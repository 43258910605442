import clsx from 'clsx'
import React, { ComponentProps, forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { BodyTextSmall } from '../Typography/BodyText'
import _TableCell from './_TableCell'

const Container = styled(_TableCell)`
  text-align: start;  /* reset th */
  ${BodyTextSmall}
  font-weight: ${props => props.theme.font.primary.weight.superBold};
`

type InheritedBaseTableCellProps = Pick<
  ComponentProps<typeof _TableCell>,
  'minWidth' | 'width' | 'format' | 'wrap' | 'align' | 'tabletAlign' | 'desktopAlign' | 'lineClamp' | 'tabletLineClamp' | 'desktopLineClamp'
>

interface Props extends HTMLAttributes<HTMLTableCellElement>, InheritedBaseTableCellProps {
}

/**
 * Must be child of `<TableHeaderRow>`.
 *
 * Styles string children accordingly.
 */
const TableHeaderCell = forwardRef<HTMLTableCellElement, Props>(function TableHeaderCell(props, ref) {
  return <Container {...props} forwardedAs="th" className={clsx(TableHeaderCell.name, props.className)} ref={ref} />
})

export default TableHeaderCell
