import { keyframes } from 'styled-components'

type Keyframes = ReturnType<typeof keyframes>

/**
 * Memoize a keyframes object based on the theme, so we can use it in styled
 * components without creating a new keyframes object on every render.
 * This is only necessary if the keyframes are using theme variables.
 * Taken from https://github.com/styled-components/styled-components/issues/852#issuecomment-505192348
 */
export function themedKeyframes(
  fn: (theme: App.Design.Theme) => Keyframes,
): (props: { theme: App.Design.Theme }) => Keyframes {
  const keyframeCache =
    typeof WeakMap === 'undefined' ? new Map() : new WeakMap()
  return ({ theme }) => {
    let result = keyframeCache.get(theme)
    if (!result) {
      result = fn(theme)
      keyframeCache.set(theme, result)
    }
    return result
  }
}

export const pulseFrames = themedKeyframes((theme: App.Design.Theme) => keyframes`
  0% {
    border-color: ${theme.palette.brand.primary.normal};
    box-shadow: 0 0 5px rgba(${theme.palette.brand.primary.normal}, 0), inset 0 0 5px rgba(${theme.palette.brand.primary.normal}, 0);
  }

  100% {
    border-color: lighten(0.05, ${theme.palette.brand.primary.normal});
    box-shadow: 0 0 20px rgba(${theme.palette.brand.primary.normal}, 0.3), inset 0 0 10px rgba(${theme.palette.brand.primary.normal}, 0.2);
  }
`)

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
