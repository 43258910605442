import { groupBy, nonNullable } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import offerPageURL from 'lib/offer/offerPageURL'
import { getConfidenceLabels, getLabels } from 'checkout/lib/utils/accommodation/label'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import { excludeNullOrUndefined } from 'checkout/utils'

import { getFullOffers } from 'selectors/offerSelectors'
import { isLEHotel } from 'lib/offer/offerTypes'
import getFlightBundledItemIds from './getFlightBundledItemIds'
import { pluralizeToString } from 'lib/string/pluralize'
import { findPostPurchaseCheckout, isBNBLLEHotelItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { getLeHotelItems } from './hotels'
import { getCheckoutLuxPlusTier } from '../checkoutLuxPlusSelectors'
import { getDepositServiceFeeConfig, getDefaultDepositAmountPercentage } from '../featureConfig/deposit'
import { checkoutWithMemberPrice } from './luxPlusSubscription'
import { getLEPackageItemView } from 'checkout/lib/utils/accommodation/view'
import { getDepositAmountPercentage } from 'lib/payment/depositsUtils'

function pkgMatch(item: App.Checkout.LEAccommodationItem) {
  return (pkg: App.Package): boolean => {
    return pkg.id === item.packageId && pkg.duration === item.duration
  }
}

const getHotelItemViews = createSelector(
  (state: App.State) => getLeHotelItems(state),
  (state: App.State) => getDepositServiceFeeConfig(state),
  (state: App.State) => getDefaultDepositAmountPercentage(state),
  (state: App.State) => getFullOffers(state),
  (state: App.State) => state.calendar.calendarsByOccupancy,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => getCheckoutLuxPlusTier(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => getFlightBundledItemIds(state),
  (
    cartItems,
    serviceFee,
    defaultDepositAmountPercentage,
    offers,
    calendarsByOccupancy,
    availableRatesByOccupancy,
    offerCredits,
    postPurchase,
    existingOrder,
    luxPlusTier,
    checkoutWithMemberPrice,
    bundledItemIds,
  ): App.WithDataStatus<Array<App.Checkout.LEAccommodationOfferView>> => {
    // Each item = 1 room, but we want to process the same dates/offer as a single view
    const itemsByBookingKey = groupBy(cartItems, item => {
      if (isBNBLLEHotelItem(item)) {
        return `${item.offerId}-bnbl-${item.duration}`
      }
      return `${item.offerId}-${item.checkIn}-${item.checkOut}`
    })
    const itemGroups = itemsByBookingKey.values().map(items => ({
      offerId: items[0].offerId,
      items,
    })).toArray()

    const views = itemGroups.map((itemGroup): App.WithDataStatus<App.Checkout.LEAccommodationOfferView | undefined> => {
      const { offerId, items } = itemGroup

      const offer = offers[offerId]

      if (!isLEHotel(offer)) {
        return {
          hasRequiredData: false,
          data: undefined,
        }
      }

      const firstItem = items[0]

      // The items could be different packages, but since they're grouped by duration/dates,
      // we know the items in each have the same duration. So we can look up the duration label
      // of the first item.
      const durationLabel = offer.packages.find(pkgMatch(firstItem))?.durationLabel ?? pluralizeToString('night', firstItem.duration)

      const itemViewsWithStatuses = items.map(item => getLEPackageItemView(
        item,
        calendarsByOccupancy,
        availableRatesByOccupancy,
        checkoutWithMemberPrice,
        offer,
        offerCredits,
        postPurchase,
        existingOrder,
        luxPlusTier,
      ))

      const view: App.Checkout.LEAccommodationOfferView = {
        offerId,
        offer,
        offerPageUrl: offerPageURL(offer),
        ...getLabels(offer),
        bedGroups: [],
        confidenceLabels: getConfidenceLabels(offer),
        duration: firstItem.duration,
        startDate: isInstantBookingLEHotelItem(firstItem) ? firstItem.checkIn : undefined,
        endDate: isInstantBookingLEHotelItem(firstItem) ? firstItem.checkOut : undefined,
        image: offer.image,
        location: getLocationString(offer),
        locationCountry: offer.property.geoData.country ?? undefined,
        locationCountryCode: offer.property.geoData.countryCode ?? undefined,
        occupancy: itemGroup.items.map(item => item.occupancy).filter(excludeNullOrUndefined),
        offerType: offer.type,
        reservationType: firstItem.reservationType ?? 'instant_booking',
        urgencyLabels: offer.urgencyTags,
        saleUnit: offer.saleUnitLong,
        durationLabel: durationLabel.toLocaleLowerCase(),
        offerLoaded: true,
        itemViews: itemViewsWithStatuses.map(i => i.data),
        propertyTimezone: offer.property.timezone ?? '',
        bundledWithFlightsOnly: offer.bundledWithFlightsOnly,
        bundledWithTour: items.some(item => !!item.bundledItemIds?.length),
        designation: 'accommodation',
        depositType: 'percentage',
        depositAmount: getDepositAmountPercentage(offer, defaultDepositAmountPercentage),
        serviceFee,
        isBundled: items.some(item => bundledItemIds.has(item.itemId)),
      }

      return {
        data: view,
        hasRequiredData: itemViewsWithStatuses.every(itemView => itemView.hasRequiredData),
      }
    })

    return {
      data: nonNullable(views.map(view => view.data)),
      hasRequiredData: views.every(view => view.hasRequiredData),
    }
  },
)

export default getHotelItemViews
