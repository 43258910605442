import { pluralizeToString } from 'lib/string/pluralize'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { dateIsBefore } from 'lib/datetime/dateUtils'

import { ALL_LUX_PLUS_RECENT_FILTER, DEFAULT_FLEXIBLE_DURATION_RANGE, FLEXIBLE_DURATION_RANGE, RECENT_SEARCHES_LIMIT, SEARCH_STORAGE_KEYS } from 'constants/search'

import type { EnabledFilters, RecentDate, RecentFilters, RecentFlexibleSearch } from './type'
import moment from 'moment'
import { formatOccupantsAsGuests } from 'lib/offer/occupancyUtils'
import { take, unique, uniqueBy } from 'lib/array/arrayUtils'
import config from 'constants/config'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { prettyFlexibleDates } from './SearchForm/SearchDateInput/FlexibleDateSearchField'
import { FilterOptionItems } from 'components/OfferList/OfferListFilter/OfferListFilterUtils'

export const searchPaneInteractEventName = 'search-container-pane-interact'

// formatLocationsDisplayText shows display text for the destination or property.
// If empty, it returns a placeholder.
export function formatLocationsDisplayText(searchItem?: App.SearchItem, placeholder?: string) {
  if (!searchItem) {
    return placeholder || 'Search for a destination or hotel'
  }

  return searchItem.format.mainText
}

export function getTotalRoomsString(rooms: Array<App.Occupants>, saleUnit?: string) {
  return `(${pluralizeToString(saleUnit ?? 'room', rooms.length)})`
}

export function getChildrenAgesString(rooms: Array<App.Occupants>) {
  const allChildren = rooms.flatMap(room => room.childrenAge)
  return allChildren.reduce((ageString, child, index) => {
    if (index === 0 && index + 1 === allChildren.length) {
      return ageString + `(${child}yo)`
    } else if (index === 0) {
      return ageString + `(${child}yo`
    } else if (index + 1 === allChildren.length) {
      return ageString + `, ${child}yo)`
    } else {
      return ageString + `, ${child}yo`
    }
  }, '')
}

export function saveRecentRooms(rooms: Array<Array<App.Occupants>>) {
  setLocalStorage(SEARCH_STORAGE_KEYS.TRAVELLERS, rooms)
}

export function loadRecentRooms(): Array<Array<App.Occupants>> {
  return getLocalStorage(SEARCH_STORAGE_KEYS.TRAVELLERS) ?? []
}

export function saveLastDates(dates: RecentDate) {
  setLocalStorage(SEARCH_STORAGE_KEYS.LAST_DATES, dates)
}

export function saveLastFlexibleSelection(flexibleSelection: RecentFlexibleSearch) {
  setLocalStorage(SEARCH_STORAGE_KEYS.FLEXIBLE_OPTIONS, flexibleSelection)
}

export function getLastFlexibleDateSelection() {
  return getLocalStorage(SEARCH_STORAGE_KEYS.FLEXIBLE_OPTIONS)
}

export function clearLastDates() {
  setLocalStorage(SEARCH_STORAGE_KEYS.LAST_DATES, null)
}

export function loadLastDates() {
  const lastDates = getLocalStorage(SEARCH_STORAGE_KEYS.LAST_DATES)
  if (isTodayBeforeCheckin(lastDates?.checkinDate)) return lastDates
}

export function isTodayBeforeCheckin(checkin: string) {
  const today = new Date()
  return !dateIsBefore(new Date(checkin), today)
}

export function isTodayBeforeFlexibleMonth(flexibleMonth: string) {
  const flexibleMonthArray = flexibleMonth.split(',')
  return flexibleMonthArray.some((month) => {
    const momentMonth = moment(month)
    return momentMonth.isAfter()
  })
}

export function getApplyCtaText(isLastStep: boolean) {
  if (isLastStep) {
    return 'Search'
  }

  return 'Apply'
}

export function isRecentSearchWithinTimeLimit(dateAdded: string, limit: number, unit: moment.unitOfTime.DurationConstructor) {
  const dateAddedMoment = moment(dateAdded)
  const now = moment()
  const diff = now.diff(dateAddedMoment, unit)
  return diff <= limit
}

export function getCheckInCheckOutText(checkIn: string, checkOut: string): string {
  // Parsing the input strings into Moment objects
  const checkInDate = moment(checkIn, 'YYYY-MM-DD')
  const checkOutDate = moment(checkOut, 'YYYY-MM-DD')

  // Extracting components from the Moment objects
  const checkInDay = checkInDate.date()
  const checkInMonth = checkInDate.format('MMM')
  const checkInYear = checkInDate.year()

  const checkOutDay = checkOutDate.date()
  const checkOutMonth = checkOutDate.format('MMM')
  const checkOutYear = checkOutDate.year()

  let output = ''

  // Case 1: Same month, same year
  if (checkInYear === checkOutYear && checkInDate.month() === checkOutDate.month()) {
    output = `${checkInDay} - ${checkOutDay} ${checkInMonth}`
  }
  // Case 2: Different months, but same year
  else if (checkInYear === checkOutYear) {
    output = `${checkInDay} ${checkInMonth} - ${checkOutDay} ${checkOutMonth}`
  }
  // Case 3: Different months, different years
  else {
    output = `${checkInDay} ${checkInMonth} ${checkInYear} - ${checkOutDay} ${checkOutMonth} ${checkOutYear}`
  }

  return output
}

export function getFlexibleDurationAndMonthText(flexibleDuration: DEFAULT_FLEXIBLE_DURATION_RANGE, flexibleMonths: string): string {
  let output = ''

  // Handling the flexible duration part
  if (flexibleDuration && flexibleDuration !== DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION) {
    const [minDays, maxDays] = flexibleDuration.split('-')
    output += `${minDays} - ${maxDays} nights`
  }

  // Handling the flexible months part
  if (flexibleMonths) {
    const months = flexibleMonths.split(',').map(month => moment(month, 'YYYY-MM').format('MMM'))

    if (months.length === 1) {
      output += output ? `, ${months[0]}` : months[0]
    } else if (months.length === 2) {
      output += output ? `, ${months.join(' & ')}` : `${months.join(' & ')}`
    } else {
      output += output ? `, ${months.slice(0, 2).join(' & ')} + ${months.length - 2}` : `${months.slice(0, 2).join(' & ')} + ${months.length - 2}`
    }
  }

  return output
}

export function getRecentSearchDescription(
  checkInDate?: string,
  checkOutDate?: string,
  flexibleMonths?: string,
  flexibleDuration?: DEFAULT_FLEXIBLE_DURATION_RANGE,
  isAnytime?: boolean,
): string {
  if (isAnytime) {
    return 'Anytime'
  }

  if (checkInDate && checkOutDate) {
    return getCheckInCheckOutText(checkInDate, checkOutDate)
  }

  if (flexibleMonths || flexibleDuration) {
    return getFlexibleDurationAndMonthText(flexibleDuration ?? DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION, flexibleMonths ?? '')
  }

  return ''
}

const emptyArray: Array<App.FullRecentSearch> = []
export function getRecentSearches(memberId?: string): Array<App.FullRecentSearch> {
  const shouldUseMemberId = !config.ENABLE_LOGGED_OUT_RECENT_SEARCH
  // we currently only store recent searches for a user, this may change but for now return empty if not provided
  if (!memberId && shouldUseMemberId) {
    return emptyArray
  }

  const key = shouldUseMemberId && memberId ? memberId : SEARCH_STORAGE_KEYS.RECENT_SEARCHES

  let recentSearches = getLocalStorage(key) as Array<App.FullRecentSearch> ?? emptyArray

  // if there aren't any recent searches with the *new* key, try to load using the old key
  if (recentSearches.length === 0 && memberId) {
    recentSearches = getLocalStorage(memberId) as Array<App.FullRecentSearch> ?? emptyArray
  }
  return clearSearchDatesIfInPast(recentSearches)
}

export function saveRecentSearch(
  searchItem: App.SearchItem,
  dates: App.SearchDates,
  rooms: Array<App.Occupants>,
  memberId?: string,
  travellers?: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>,
) {
  const newSearchItem = {
    searchItem,
    dates,
    rooms,
    recentSearchId: buildRecentSearchId(dates, searchItem, rooms),
    dateAdded: new Date().toISOString(),
    travellers,
  }

  const shouldUseMemberId = !config.ENABLE_LOGGED_OUT_RECENT_SEARCH
  const key = shouldUseMemberId ? memberId : SEARCH_STORAGE_KEYS.RECENT_SEARCHES
  if (!key) {
    // guess they had no member id and we needed, can't save it
    return
  }
  const uniqueSearches = uniqueBy([newSearchItem, ...getRecentSearches(memberId)], search => search.searchItem.value)
  const nextSearches = take(uniqueSearches, RECENT_SEARCHES_LIMIT)
  setLocalStorage(key, nextSearches)
}

export function getFormattedDateRange(startDate: moment.Moment, endDate: moment.Moment): string {
  if (startDate.month() === endDate.month()) {
    return `${startDate.format('DD')} - ${endDate.format('DD MMM')}`
  }
  return `${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`
}

// To remove dates if they are in the past
export function clearSearchDatesIfInPast(searches: Array<App.FullRecentSearch>) {
  return searches.map((search) => {
    if (Object.keys(search.dates).length === 0 || (search.dates.checkIn && !isTodayBeforeCheckin(search.dates.checkIn)) || (search.dates.flexibleMonths && !isTodayBeforeFlexibleMonth(search.dates.flexibleMonths))) {
      const dates: App.SearchDates = { checkIn: undefined, checkOut: undefined, flexibleDuration: undefined, flexibleMonths: undefined, isAnytime: true }
      const recentSearchId = buildRecentSearchId(dates, search.searchItem, search.rooms)
      return { ...search, dates, recentSearchId }
    }
    return search
  })
}

export function deleteRecentSearch(recentSearchId: string, memberId?: string) {
  const searches = getRecentSearches(memberId)
  const shouldUseMemberId = !config.ENABLE_LOGGED_OUT_RECENT_SEARCH
  const key = shouldUseMemberId ? memberId : SEARCH_STORAGE_KEYS.RECENT_SEARCHES
  if (!key) {
    // needs a key to work
    return
  }
  const nextSearches = searches.filter(search => search.recentSearchId !== recentSearchId)
  setLocalStorage(key, nextSearches)
}

// Helper function for getting unique searches
function buildRecentSearchId(dates: App.SearchDates, searchItem: App.SearchItem, rooms: Array<App.Occupants>): string {
  return `${dates?.checkIn}-${dates?.checkOut}-${dates?.flexibleDuration}-${dates?.flexibleMonths}-${searchItem.format.mainText}-${searchItem.searchType}-${formatOccupantsAsGuests(...rooms)}`
}

export function prettyDates(checkinDate?: moment.Moment, checkoutDate?: moment.Moment): string {
  if (checkinDate && checkoutDate) {
    const checkOut = checkoutDate.day() >= 10 ? checkoutDate.format(DMY_CASUAL_FORMAT) : checkoutDate.format('D MMM YYYY')
    let checkinFormat = checkinDate.day() >= 10 ? 'DD' : 'D'
    if (checkinDate.year() === checkoutDate.year()) {
      checkinFormat += checkinDate.month() === checkoutDate.month() ? '' : ' MMM'
    } else {
      checkinFormat += ' MMM YYYY'
    }
    return `${checkinDate.format(checkinFormat)} - ${checkOut}`
  }
  return 'Anytime'
}

function getDateIn30Days() {
  const date = new Date()
  date.setDate(date.getDate() + 30)
  return date.getTime()
}

export function getPageTitle(
  searchItem: App.SearchItem | undefined,
  dates: {
    checkIn?: moment.Moment,
    checkOut?: moment.Moment,
    flexibleNights?: FLEXIBLE_DURATION_RANGE,
    flexibleMonths?: string
  }): string {
  const { checkIn, checkOut, flexibleNights, flexibleMonths } = dates
  const searchText = searchItem?.format.mainText
  let dateString = 'Anytime'
  const LUXURY_ESCAPES_TAGLINE = 'Luxury Escapes: Handpicked Escapes at the Best Prices'
  if (checkIn && checkOut) {
    dateString = prettyDates(checkIn, checkOut)
  } else if (flexibleNights || flexibleMonths) {
    dateString = prettyFlexibleDates(flexibleMonths, flexibleNights)
  }
  let resultString = `${searchText}`
  resultString += searchItem?.searchType === 'property' ? '' : ' Hotels'
  resultString += dateString !== 'Anytime' ? `: ${dateString}` : ''
  resultString += ` • ${LUXURY_ESCAPES_TAGLINE}`
  return resultString
}

export function getRecentFilters(): RecentFilters {
  const recentFilters = getLocalStorage(SEARCH_STORAGE_KEYS.RECENT_FILTERS)
  if (recentFilters?.expiryDate && Number(recentFilters.expiryDate) > new Date().getTime()) {
    if (recentFilters.customerRatingGte) {
      recentFilters.customerRatingGte = Number(recentFilters.customerRatingGte)
    }
    recentFilters.expiryDate = Number(recentFilters.expiryDate)
    return recentFilters
  }
  return {
    holidayTypes: [],
    amenities: [],
    inclusions: [],
    locations: [],
    expiryDate: getDateIn30Days(),
    orderBy: [],
    bedroomsGte: undefined,
    bedsGte: undefined,
    bathroomsGte: undefined,
    luxPlusFeatures: [],
    agentHubFeatures: [],
  }
}

export function saveRecentFilters(filters: Partial<RecentFilters>) {
  const recentFilters = getRecentFilters()
  for (const _key in filters) {
    const key = _key as keyof RecentFilters
    if (filters[key]) {
      if (key === 'customerRatingGte') {
        recentFilters[key] = filters[key]
        recentFilters.orderBy = unique([filters[key], ...(recentFilters.orderBy ?? [])])
      } else if (key !== 'orderBy' && key !== 'expiryDate' && key !== 'bedroomsGte' && key !== 'bedsGte' && key !== 'bathroomsGte') {
        recentFilters[key] = take(unique(filters[key].concat(...recentFilters[key])), 20)
        recentFilters.orderBy = unique([...filters[key], ...(recentFilters.orderBy ?? [])])
      }
    }
  }
  recentFilters.expiryDate = getDateIn30Days()
  setLocalStorage(SEARCH_STORAGE_KEYS.RECENT_FILTERS, recentFilters)
}

export function getAppliedFilterCount(filters: App.OfferListFilters, enabledFilters: EnabledFilters): number {
  return (enabledFilters.holidayTypes ? filters.holidayTypes?.length ?? 0 : 0) + (filters.amenities?.length ?? 0) +
    (filters.inclusions?.length ?? 0) + (filters?.priceLte ? 1 : 0) + (filters.locations?.length ?? 0) + (filters.customerRatingGte ? 1 : 0) +
    (filters.offerTypes?.length === 1 ? 1 : 0) + (filters.bedroomsGte ? 1 : 0) + (filters.bedsGte ? 1 : 0) + (filters.bathroomsGte ? 1 : 0) + (filters.luxPlusFeatures?.length ?? 0)
}

export function formatSearchItemSubtitle(searchItem: App.FullRecentSearch) {
  const { dates, rooms } = searchItem

  const dateText = getRecentSearchDescription(dates.checkIn, dates.checkOut, dates.flexibleMonths, dates.flexibleDuration as DEFAULT_FLEXIBLE_DURATION_RANGE, dates.isAnytime)

  const occupancyText = formatOccupantsAsGuests(...rooms)

  if (config.businessTraveller.currentAccountMode === 'business' && searchItem.travellers && searchItem.travellers.length > 0) {
    return `${dateText} • ${searchItem.travellers[0].firstName} ${searchItem.travellers[0].lastName}`
  }

  return dateText !== '' && occupancyText !== '' && occupancyText !== '' ? `${dateText} • ${occupancyText} • ${pluralizeToString('room', rooms.length)}` : ''
}

export function isOfTypeLuxPlusFeatureFilter(feature: string): boolean {
  const luxPlusFeatureFilters: Array<App.LuxPlusFeatureFilter> = ['Early access', 'Member prices', 'Bonus inclusions', 'Member exclusive']

  return luxPlusFeatureFilters.includes(feature as App.LuxPlusFeatureFilter)
}

export function getLuxPlusFilterName(filter: string): string {
  if (filter === ALL_LUX_PLUS_RECENT_FILTER) {
    return filter
  }
  return `LuxPlus+ ${filter}`
}

export function isLuxPusAllChecked(filter: string, luxPlusFeatureItems: Array<FilterOptionItems>): boolean {
  return filter === ALL_LUX_PLUS_RECENT_FILTER && luxPlusFeatureItems.every(obj => obj.checked)
}

export function getOfferIdFromSearchItem(searchItem: App.SearchItem): string | undefined {
  const bedbankPrefix = 'bedbank:'
  if (searchItem.searchType === 'property') {
    if (searchItem.offerId) return searchItem.offerId
    if (searchItem.value.startsWith(bedbankPrefix)) return searchItem.value.substring(bedbankPrefix.length)
  }
  return undefined
}

export function isFlexibleMonthsWithinDealDates(
  flexibleMonths: string,
  dealStart: moment.Moment,
  dealEnd: moment.Moment,
): boolean {
  return flexibleMonths.split(',').some((month: string) => {
    const startOfMonth = moment(`${month}-01`, 'YYYY-MM-DD')
    const endOfMonth = startOfMonth.clone().endOf('month')

    const currentDay = startOfMonth.clone()
    while (currentDay.isSameOrBefore(endOfMonth, 'day')) {
      if (currentDay.isBetween(dealStart, dealEnd, null, '[]')) {
        return true
      }
      currentDay.add(1, 'day')
    }
    return false
  })
}

export function isDestinationSearchPage(pathname: string) {
  return pathname.includes('location') || pathname.includes('country') || pathname.includes('city') || pathname.includes('location')
}

export function isAgentHubExclusiveFilter(filter: string): boolean {
  const agentHubExclusiveFilters: Array<string> = ['Agent Hub Exclusive']
  return agentHubExclusiveFilters.includes(filter)
}
