import Clickable from 'components/Common/Clickable'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import { SVG_ICON_SIZE_CSS_VAR } from 'components/Luxkit/SvgIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { rem } from 'polished'
import React, { MouseEventHandler, ReactElement, ReactNode, useCallback } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: ${rem(8)};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${rem(4)};
  max-width: max-content;
  border-radius: ${props => props.theme.borderRadius.S};
  background-color: var(--tint);
  color: var(--contrast);

  &.tier-bronze {
    --tint: ${props => props.theme.palette.product.luxLoyalty.bronze.backgroundTint};
    --shade: ${props => props.theme.palette.product.luxLoyalty.bronze.backgroundShade};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.bronze.contrast};
    --alt-contrast: ${props => props.theme.palette.product.luxLoyalty.bronze.altContrast};
  }
  &.tier-silver {
    --tint: ${props => props.theme.palette.product.luxLoyalty.silver.backgroundTint};
    --shade: ${props => props.theme.palette.product.luxLoyalty.silver.backgroundShade};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.silver.contrast};
    --alt-contrast: ${props => props.theme.palette.product.luxLoyalty.silver.altContrast};
  }
  &.tier-gold {
    --tint: ${props => props.theme.palette.product.luxLoyalty.gold.backgroundTint};
    --shade: ${props => props.theme.palette.product.luxLoyalty.gold.backgroundShade};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.gold.contrast};
    --alt-contrast: ${props => props.theme.palette.product.luxLoyalty.gold.altContrast};
  }
  &.tier-platinum {
    --tint: ${props => props.theme.palette.product.luxLoyalty.platinum.backgroundTint};
    --shade: ${props => props.theme.palette.product.luxLoyalty.platinum.backgroundShade};
    --contrast: ${props => props.theme.palette.product.luxLoyalty.platinum.contrast};
    --alt-contrast: ${props => props.theme.palette.product.luxLoyalty.platinum.altContrast};
  }
`

const IconContainer = styled.div`
  background-color: var(--shade);
  display: grid;
  place-items: center;
  height: ${rem(20)};
  width: ${rem(20)};
  border-radius: ${props => props.theme.borderRadius.round};
  color: var(--alt-contrast);
  ${SVG_ICON_SIZE_CSS_VAR}: ${rem(16)};

  &:empty { display: none; }
`

const Title = styled(BodyText)`
  color: var(--contrast);
`

const Subtitle = styled(BodyText)`
  color: var(--alt-contrast);
  flex-shrink: 1;
`

const Separator = styled.div`
  height: ${rem(14)};
  width: ${rem(1)};
  background-color: var(--alt-contrast);
`

const ClickableContainer = styled(Clickable)`
  display: grid;
  place-items: center;
`

interface Props {
  tier: App.LuxLoyaltyTier
  icon?: ReactElement
  children: string | ReactElement | Iterable<ReactNode>
  onClick?: MouseEventHandler<HTMLButtonElement>
}

function LuxLoyaltyTierBenefitLabel({
  tier,
  icon,
  children,
  onClick,
}: Props) {
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onClick?.(event)
  }, [onClick])

  return <Container className={`tier-${tier}`}>
    {!!icon && <IconContainer>{icon}</IconContainer>}
    <Title variant="medium" weight="bold">{children}</Title>
    <Separator role="separator" />
    <Subtitle variant="small" weight="bold">{getLuxLoyaltyTierDisplayName(tier)} Member Benefit</Subtitle>
    {!!onClick && <ClickableContainer onClick={handleOnClick}>
      <LineInfoCircleIcon size="XS" />
    </ClickableContainer>}
  </Container>
}

export default LuxLoyaltyTierBenefitLabel
