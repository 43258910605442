import { createSelector } from 'reselect'
import { isTourV2ExperienceItem, isTourV2Item } from 'lib/checkout/checkoutUtils'
import { EmptyMap } from 'lib/array/arrayUtils'

export function getDiscountItemId(item: App.Checkout.AnyItem): string {
  if (isTourV2Item(item)) {
    return 'roomId' in item.occupancy ? item.occupancy.roomId as string : item.itemId
  }
  if (isTourV2ExperienceItem(item)) {
    return item.purchasableOption.fkExperienceId ?? item.itemId
  }
  return item.itemId
}

const getAllItemsDiscountTotals = createSelector(
  (state: App.State) => state.checkout.promo?.items,
  (state: App.State) => state.checkout.cart.items,
  (promoItems, cartItems): Map<string, number | undefined> => {
    if (!promoItems?.length) {
      return EmptyMap
    }
    const map = new Map<string, number | undefined>()

    cartItems.forEach((item) => {
      const itemId = getDiscountItemId(item)

      map.set(item.itemId, promoItems.find(promoItem => promoItem.itemId === itemId)?.discountAmount)
    })

    if (!map.size) return EmptyMap

    return map
  },
)

export default getAllItemsDiscountTotals
