import config from 'constants/config'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { LUXURY_ESCAPES } from 'constants/brands'

/**
 * Account IDs of design, product, engineering and key stakeholders
 */
const internalLeTestAccountIds = new Set([
  'd111d417-ce39-414e-b2bc-e34a0a33f4f1',
  '0931afb3-5b66-40aa-be2b-abba161d7118',
  '3c440768-55b2-4021-be40-652a20b81ee8',
  'c91d2e42-ed64-47e1-af4d-d8f24d853566',
  '0386d4bc-43f1-4f1a-ada0-c667c3dbffe4',
  'e9f6b03e-d0d9-4b7a-bc9a-ccb2fbd272d1',
  '914844de-70cb-4143-9d1d-746fe3694509',
  '28ec1788-6c87-4a65-9e64-d2d9b7d01791',
  'b8707ea7-b38a-49a9-b333-9ffa1c1a3dc9',
  '3b052f80-ae08-4d40-bdbc-fd9fb79285f0',
  'c5c94c80-6d77-49a1-8317-24f311f92b23',
  '6c1e6118-ac85-4b30-8557-b0d837ae5636',
  '92dd8778-c05f-491e-8a00-d00eed01ec5b',
  '3dab9a74-63e3-47a7-a850-7e8572a1dd99',
  '61ac6a87-3d85-4359-b3c0-bcf7189acd08',
  'c04aa5c0-c7e4-4d6b-a9d2-4a248f6cee1f',
  '2bb0f94c-91f5-4d85-90f5-9ac86a3724c2',
  'fea7e303-6a4d-4a49-987c-36efc2da9454',
  '93bc5dd0-b707-4e5d-abd0-68eb1b7a9c10',
  'f839c024-5e5e-4695-8e82-2752d0a5d083',
  '89375c8a-dc94-4912-a69e-c95756f39ad8',
  'a52e8fd3-a8af-4408-8899-b436bac5d42e',
  'a1b7a155-aaaf-4365-96bd-1cf9e1af9a72',
  '618d70c7-2864-4b37-a5c7-ba9ae300b5f2',
  '973ed3e8-bea5-4696-9e1a-543c40c7ca53',
  '8dcf4acc-6f2c-4996-9163-c424716a24ff',
  'd88201b1-90fb-429d-8a37-006cb1800a77',
  '4642c53a-e1fa-4c41-b616-e08dfc582567',
  'db0dd872-2926-4bd1-82ad-006fa0def465',
  '3ae28c41-19d6-4146-a37b-c4c7973b8e2c',
  'c15ba489-5eb9-4be2-a925-95678b132ae4',
  '5d45804e-2f55-445d-836b-57e3f13b9342',
  '5328f702-cb20-4b9e-8065-81f3460ea681',
  '40db6dea-cea4-40cb-b125-8245936c84da',
  'f5ab4fb9-fe75-4bc3-93b3-e16ae030fef2',
  '9343757d-3fe0-41f6-ac2a-de80b0483550',
  'f2d24bed-ea38-421a-833c-b4ef29bdbfe8',
  '3f32781b-03cc-433b-a74f-3811f159e1eb',
  'a9bbd40a-ab16-4664-b49e-d6e9640f770c',
  'a724b430-c904-46ad-85ab-f3c7aa969eac',
  'bb4ae554-2a6b-4d17-9051-18f98c9188d1',
  'a0c94b0f-144b-4b66-b483-d736f4e3d282',
  '9f1e5e8d-7826-4e40-b33e-7e623cd42603',
  '4cc22f5f-13c6-467d-bd41-2754e0b2044e',
  'fc8d655c-491b-43f9-8c6d-536b68529758',
  '1eba0e5d-32a0-4c76-857f-0795bac899ed',
  '29ab75b2-7bd1-47c1-8359-8c09830f816d',
  '399cef00-553d-480c-9d01-70dc0ca5b2b9',
  '2bc4b7d5-d9a0-4b46-af1a-d73694a36aef',
  '56f6b68-69c2-460c-9dd3-8de93a4cb707',
  '3ff66689-1223-41df-a542-f52f5b5007eb',
  '201041d2-ea67-4958-873e-29986dd1d440',
  'fa5828a7-46fd-49e2-89d7-beab946162f2',
  'c8c2c576-e01d-4cdb-8117-37199baefa47',
  'ca1b7c62-a925-4261-9cc2-0c43aa3488fd',
  'da56d6b1-8b5e-448a-9b27-cee67cb33f4f',
  'd28553ca-35f8-4fea-bb5d-5a8a2f3e4c69',
  'fed8eba9-d24b-4e88-b9bc-c4f4cea02436',
  '7e7ada96-5f3f-474b-a09f-06734f213860',
  '6fcba867-46c5-47ae-a2ba-a9f4ae1dcb87',
  'd06c54ca-7bb6-40b8-a5bf-d268634b3614',
  '3a1787bd-0dc6-4c57-9219-5eeeadad4700',
  '9186d79c-586b-4da8-9e21-6066f4e0f145',
  'd909de97-4028-496d-bf32-17b2180bae06',
  '025fa441-8cca-43c8-9cee-14a1f2d1b8ca',
  '6dc34d4f-d76a-4313-a8bb-025e5d7e8cdb',
  'c0c65db5-0be2-41a1-a52e-2ebfe0c70e4e',
  'a556cbaa-fcf0-41a7-9752-5c4b8b5cb4cd',
  '20b81113-337f-4a3c-8534-4d766a6295ff',
  '11095137-5708-467a-9632-cbf9b7621da6',
  '09093050-9be2-44b5-bef8-97f113a8e259',
  '12c3a861-3cdc-4563-a87f-d486aae8a5ea',
  '0e81d06f-c6cc-495f-a966-8880cbff3b9a',
  '9f2fe608-9a84-4a69-8d18-38b322a6a1d3',
  'e04f98ec-05e6-4c6a-9dd4-a95027556350',
  '3fa7c2a1-cbea-43d7-b8c8-69ae35381548',
  '76a4322a-1e0d-4908-9c59-2c7c9e5f1e2f',
  '9c494819-5ebb-4021-9374-9cd196abee13',
  'ead9c0f8-c996-4566-ad1b-ff0830577f8d',
  'dc26be6b-e924-44c4-b76c-44d854c86fb1',
  '0e80eef9-d8dd-4657-9978-729aac0c15f9',
  '7d222134-8914-435e-a336-1aa70543a46b',
  '82f4e9df-6b21-4851-986b-1954ceff6dbd',
  'f2f157d7-0a79-4431-9fd3-dd1b0f05ed2f',
  '6b834669-741c-45c5-8ca7-6f97244c4c7c',
  'b0686fdf-b8b7-4577-8717-8d31b123450d',
  '5d0f4cc4-1819-4659-9550-145e99d6a3e7',
  'b2afb6c1-c277-4e5d-9c70-98028cca37c1',
  '7282d768-61af-47df-81a2-dd920d1b7ef3',
  '93b65912-8995-484e-9471-53a285a12200',
  '4f81a12e-3310-4b1f-966f-b622965c070a',
  '1eddbe6e-4d2d-4734-9499-02acd49c4a9b',
  '2958842f-ca04-462f-973f-2b81dca38e67',
  '73378d57-f80c-4e14-a678-a53bf4a360c3',
  '5ebc0a7f-6511-4b4f-86e0-e346c7c94750',
  '26f1e303-018f-435d-9c94-7d70662c6058',
  'd593a2ad-12d8-4096-b9d9-1df25797448a',
  '038cb161-d6ca-4be1-9bbd-ba07c2ea4b9c',
  'ec28c26b-2fe2-45d4-9327-d1a312707c57',
  '3df64b10-a407-4027-817a-ee97da315f11',
  '5c211da8-3713-4ca5-9ecf-9be5b3791619',
  '6331daa4-01aa-47a2-919d-ee7b16507a42',
  'd7aabb7f-7eb5-43e6-96cb-76631d8e51e5',
  '9ae790be-c155-463a-8b0c-4231781bdef1',
  '957f8d19-4dc6-43ad-ae88-861e41f91ae6',
  'd836bd29-2a64-45cf-bd98-29d45469d888',
  '1162b448-6c51-4e32-bbc6-5d660e5d7ba0',
  'a8b57663-6714-4db6-8c49-409035fffab0',
  '3b588c78-05b0-4330-ba90-1204d3ca5d2b',
  '7f0a30ae-1cef-4277-99a0-21197a438a11',
  '201ee013-e557-4635-bd47-442a828108b7',
  '939b7bbd-7af2-43af-a611-756b67c77940',
  'b1176765-1703-496a-be85-da9cbca9a7c9',
  'fc2a3c3e-14fa-4b3c-83e6-b9682b1787dd',
  '61c5ddb1-3063-4f32-92ea-d67216931df4',
  '7cf104e0-a61e-4e50-ac26-5dc8daef34d5',
  '99e63af6-b747-4d76-900b-909672a375d3',
  '69653bce-0917-4ee2-87be-d531e38d5fd4',
  '4f632e00-dd35-43e3-be40-9b1345d43634',
  '65e76f08-2960-4f5c-aec7-b83f19823b79',
  'a2f8436d-5de0-4475-ba3b-9f7586095646',
  '76ecdcfa-7cef-4149-a929-2a48c1b2bf3f',
  '8bf3d784-163a-4ead-8d69-ec34fb86d735',
  'd6442413-6fa8-4cce-b676-b9321523269e',
  'c02efdb6-4be2-440a-98e4-62cc27fb5465',
  '3f361a07-260e-4640-8348-5b46e9543673',
  '2e4b9382-855f-4f91-b0f0-858c341d8999',
  'aca46f77-bfdd-4f88-8245-b3bc0ad28216',
  '8c3fcdac-0149-458b-b47b-b884d1916c2e',
  '30e33bfa-99b6-4b5b-a2f2-4952c5432cba',
  '14c77b62-8c6f-470b-9f1b-e53c574a5cb9',
  '80dad0ed-814a-4941-93fd-6799784f8c04',
  'bcbf33a6-5f9d-4c8b-afdf-3f2fbf3e57fc',
  '021fe2bf-4a66-4f6f-8603-3546fa34e73a',
  'c6a91f5b-0cff-4924-9144-bac7f7c94b9d',
  'dbfef922-51ff-458c-9f4f-b30927380f65',
  '537df58f-3042-4252-9158-d4657e74006a',
  '935655e0-c1c0-49c1-ad35-01db58b2c961',
  'bb8ddbf9-4f45-478c-b3b6-fa7d849142af',
  '12b4b37c-ce55-4f48-8540-a4b8bc486c76',
  '4a902447-0ab8-4c5c-aa6f-7135da31423a',
  '7c911dd6-7896-4025-b7d8-9796e000e718',
  '0dff605d-0a62-46ee-8047-bc5c456adc69',
  'cc60d54d-4e00-43e6-a247-0db3e8ab1af9',
  '196f0052-9007-4bda-8889-7c795845842d',
  '713def87-4f52-45db-a29f-d99b6fd607cb',
  '265d26c6-c7ca-4c64-b769-13cfae20d31d',
  '3a5078e2-d034-4072-bde1-8b6673fbd2bd',
  '1f61c93c-2d0b-4cf1-b64b-e5b4b0a2d454',
  'aaf9730e-e2db-4863-9a89-6766def962b2',
  'e4f3fa1d-03f6-4cee-a208-3a125021cd68',
  'df4a65f8-4cf8-42cf-98dc-a367310522a0',
  '30c95693-418a-45fa-b79b-f8ea5556b616',
  '0d2d7428-c8e2-429f-ad16-4010b6702699',
  '16c8384c-eabe-449a-8486-7ca38fdfbcf0',
  '85df05b2-1280-4e1d-acfc-6800b0e96135',
  '96cf3d07-7f32-48b2-881c-eb7c0412e32f',
  '02817222-07cf-496f-b962-c6062d441465',
  '6a2d1dbd-f38a-4085-800b-dfba5da218b9',
  '309bbbeb-24c1-4929-becd-4e5e67c2b1d9',
  'b8904e6b-1388-46a4-bb18-3fe8e892bb7f',
  '1ccd93de-5a19-42b8-b6b5-232f3727a682',
  '27085a5a-77db-4563-952e-59e9a06f3c56',
  'aa56e085-4112-4784-b599-7fb808ff3c1d',
  'd09c22db-dba6-4090-b6e1-a1b259ff65d7',
  'e3c17c15-59f7-4258-bf28-4e6841dbd544',
  'b4442932-9f78-48d5-86bf-c0d9442f67ca',
  '4172c1f2-bb35-4d42-baec-c870235dc6bb',
  '9ebe3e62-92a8-4a41-9c7b-a286c309cb30',
  '1cda0f50-c728-4eb0-a76f-59dabdb0a38f',
  '09d8e9a0-298a-4d1b-9490-4f381ac2e1c5',
  'd46e1aa8-9b22-47da-ad9e-c13f448eebf4',
  '913d9b4b-bfb6-4384-ba7b-08d25a2f3619',
  '3d317841-9faa-4ac9-a9a1-c1ec908c24ce',
  'fc7b05b9-8e1a-4bdc-bf84-0db2aa809584',
  '90559803-c48c-4606-87f6-3fc81f30b4e9',
  '1f401691-c09b-48a6-b76a-925bd5248b76',
  '1626053b-a24c-475e-810d-73cfad1916b4',
  'fe74e62e-dcbf-418d-b3d7-2bcd6de04b5e',
  '2646efcc-71c9-4ee3-af30-78b411680344',
  '67a8df65-112a-47e0-a2ef-228c8a9a1321',
  'd4da8bab-29d0-4c5e-9482-cebe8811ec6a',
  '28ede005-db57-4f99-b85c-6b635b7620c6',
  '279f068c-b658-41ce-9622-7ea861277051',
  '58e50554-4ae8-497c-a7f3-46e50e4b75c3',
  '2f04da72-ce8e-495d-995d-45d66b394db8',
  '6eb8d558-9d27-489f-ad8f-6c8e6776874f',
  'a3d9d6e1-e013-4fbc-a0b5-c12f1ca5f914',
  'febddf3b-9641-43c1-adb7-05f610f19f28',
  'f69fec61-829e-4b6a-ba3f-ecab5a031114',
  'f405ff25-2696-4d20-80a3-5b35fba7f353',
  '4127f0aa-cc81-4d2e-b422-fae657026655',
  'c28a3b2c-762e-4dd6-adbb-be941518d138',
  '8a5953bb-5e30-48a9-b5d7-c2e8a8e83b69',
  '3717559f-331d-4c79-8fbd-6b2f3c38a10e',
  '8452bf78-9b40-40b7-8580-8e93d037b059',
  '3ac60252-c101-404d-8bc0-eec1cf444bc0',
  '1178b7e9-d153-40d0-b1e4-80c24048a9e7',
  'bc328cf0-e0f1-46e8-8239-613203f2fc61',
  'cac83ec8-2736-4c3e-a721-5da916e84bda',
  'df946c41-aeb6-41ce-aac3-9240a0756159',
  'b1167204-7334-4979-8907-733045582608',
  '004ab18e-c200-4535-96d9-01578568a873',
  'e6bf8bb0-90d5-4a04-9b68-ffd212f53428',
  'e0804bda-e807-4e4b-a138-789a9a1e1190',
  'f6114a18-0052-44d6-88be-ca33b2d88ce4',
  'eece9a12-0a89-47ff-9863-0c22cd9bc5d9',
  '93371443-a9f0-481d-8c9e-425ff149f09d',
  'a828e509-d213-4d22-baca-b06e6596cb3e',
  'ccde18e6-953b-41fa-becb-45391ad017f2',
  '69fbdc21-93a5-47e3-b3c6-2355d47e363b',
  'cdaaf523-b676-481d-a2c1-3583eeac290c',
  '63d2d0ee-28e1-4860-9a35-323583b2f473',
  '992b86b2-0789-4fbc-b004-404040e83ff1',
  'a62112ee-19ef-4f12-9771-a51bc461e681',
  'bcb947e1-12c6-4785-af12-812f7fe5f388',
  'db073e10-75c3-4ee3-91c9-4941f639359b',
  '255e3576-3f1b-454f-8b2a-273b67be756f',
  'fbdc4ecd-6acf-4485-a2a6-ac8a1f218248',
  '76626a67-13af-43a2-9cd3-4f2e7cc3317d',
  'd6003fac-905e-40ee-a666-879b5ab6c214',
  '0b771d10-f763-43bc-a4b1-a5330a519849',
  'e195455c-c72b-4a86-8d05-bbb1c9746e2f',
  '1efe0768-6f2a-4c54-a8f8-04e5e65de5c0',
  'b5602c30-c9e3-4804-b6a2-f42cf1f5fca0',
  'c5a8d932-25a9-480e-9041-c1315efb3e04',
  'aaa70ee4-da73-489c-bf25-54b286caa498',
  '7ac65b6f-b37b-4036-a71f-9787f56c6741',
  '5d5a868b-a2fb-4293-ae78-a107c0e42d57',
  'cf01cdf4-1019-4641-bf97-843fcd8fae15',
  '8ea3de56-7397-43b1-8f51-78749a22a2d7',
  '31aad1c6-d1fc-4090-913d-81721033af05',
  'cdd8297f-69ca-4e43-bd8b-d84877cf3279',
  '54948b8f-b6b3-46c6-9da1-b84294cae4a5',
  '8893f99f-0086-4717-99ba-3e19bb4231f0',
  'abbeef56-b98b-4e43-b916-02a573b79a99',
  '6bc805ac-6ead-48e8-9852-a694d572d380',
  '5b945e8f-567f-439c-a3ac-093a4ee3a1ea',
  'feae5603-ae08-4859-9a12-3c449dc3f829',
  'b8cfb377-9500-4874-9fdd-a811029598aa',
  'dab90414-2915-442b-9df1-0c8cebb7ddb3',
  '5c0dafdd-667d-41bf-b408-0c05acac2478',
  '65d6fdcf-0737-410f-b8b1-628e887192dd',
  '0337dda3-8cb1-4381-8f84-d0d2e4876617',
  '6ad802d5-a15e-4354-9305-a729c6e0d394',
  '43fb9b33-7299-4445-aaea-7d0554bfc111',
  'efb3592f-c51b-46d2-a360-30d99793e668',
  '974510cf-d848-4638-b2ef-b28058d5c4d0',
  '78fa7f7d-7caf-4d71-bee1-20306883381b',
  '2194a1fe-b6b2-4641-b5c8-ea4c6db66d51',
  '7811998c-478f-4f63-a2ed-947645824ba5',
  'a5e88829-91c8-4aa8-a5df-7b86341b5f22',
  'efb3592f-c51b-46d2-a360-30d99793e668',
  'b4eb1d3a-f2f6-4218-9ce8-bbab48ae7b1d',
])

export const getIsLuxLoyaltyEnabled = (state: App.State): boolean => {
  return !!(
    config.LUX_LOYALTY_ENABLED && (
      state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxLoyaltyFeatureToggle] ||
      (state.auth.account.memberId && internalLeTestAccountIds.has(state.auth.account.memberId))
    )
  )
}

export const getIsLuxLoyaltyLoadTestEnabled = (state: App.State): boolean => {
  const memberId = state.auth.account.memberId
  return !!(
    config.BRAND === LUXURY_ESCAPES &&
    // Exclude internal LE test accounts from load test
    (!memberId || !internalLeTestAccountIds.has(memberId)) &&
    state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxLoyaltyLoadTest]
  )
}

export const getHasLuxLoyaltyAccount = (state: App.State): boolean => !!(getIsLuxLoyaltyEnabled(state) && state.luxLoyalty.account.data)
