import { createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import { businessTravellerApiFailureActions, businessTravellerApiRequestActions, businessTravellerApiSuccessActions } from './businessTravellerApiActionReducers'
import { ValueOf } from 'type-fest'

export const BUSINESS_TRAVELLER_INITIAL_STATE: App.BusinessTravellerState = {
  currentBusinessAccount: undefined,
  offersCredits: {},
  businessBudgets: {},
  businessAccommodationPolicy: { status: 'pending', policy: undefined },
  businessFlightPolicy: { status: 'pending', policy: undefined },
  businessCarHirePolicy: { status: 'pending', policy: undefined },
  businessFlightBudgets: {},
  businessCarHireBudgets: {},
  businessApprovalPolicy: { status: 'pending', policy: undefined },
  budgets: {},
  flightSearchBudget: {},
  onboarding: undefined,
  employees: { status: 'pending', all: {} },
  approvers: { status: 'pending', all: {} },
  selectedTravellerEmployeeIds: [],
  selectedTravellerGuestCount: 0,
  approvalRequestId: undefined,
  businessMapBudget: undefined,
  employeeDetails: { status: 'pending', all: [] },
  businessCards: { status: 'pending', cards: [] },
  flightBudgetNotification: undefined,
}

export enum BUSINESS_TRAVELLER_ACTIONS {
  RESET = 'BUSINESS_TRAVELLER_RESET',
  SET_BUSINESS_TRAVELLER = 'BUSINESS_TRAVELLER_SET_BUSINESS_TRAVELLER',
  UPDATE_CREDIT_CUSTOMER_DETAILS = 'BUSINESS_TRAVELLER_UPDATE_CREDIT_CUSTOMER_DETAILS',
  SET_CURRENT_APPROVAL_REQUEST = 'BUSINESS_TRAVELLER_SET_CURRENT_APPROVAL_REQUEST',
  CLEAR_CURRENT_APPROVAL_REQUEST = 'BUSINESS_TRAVELLER_CLEAR_CURRENT_APPROVAL_REQUEST',
}

type Actions = Utils.FullActionMap<{
  [BUSINESS_TRAVELLER_ACTIONS.SET_BUSINESS_TRAVELLER]: { employeeIds?: Array<string>, guestCount?: number },
  [BUSINESS_TRAVELLER_ACTIONS.UPDATE_CREDIT_CUSTOMER_DETAILS]: { creditCustomerId: string, creditCustomerEmail: string },
  [BUSINESS_TRAVELLER_ACTIONS.SET_CURRENT_APPROVAL_REQUEST]: { approvalRequestId: string },
  [BUSINESS_TRAVELLER_ACTIONS.CLEAR_CURRENT_APPROVAL_REQUEST]: { },
}>

const businessTravellerReducer = createReducer<App.BusinessTravellerState, ValueOf<Actions>>(
  BUSINESS_TRAVELLER_INITIAL_STATE,
  {
    [API_CALL_REQUEST]: (state, action) => businessTravellerApiRequestActions(action.api)(state, action),
    [API_CALL_SUCCESS]: (state, action) => businessTravellerApiSuccessActions(action.api)(state, action),
    [API_CALL_FAILURE]: (state, action) => businessTravellerApiFailureActions(action.api)(state, action),
    [BUSINESS_TRAVELLER_ACTIONS.SET_BUSINESS_TRAVELLER]: (state, action) => {
      return {
        selectedTravellerEmployeeIds: action.employeeIds ?? state.selectedTravellerEmployeeIds,
        selectedTravellerGuestCount: action.guestCount ?? state.selectedTravellerGuestCount,
      }
    },
    [BUSINESS_TRAVELLER_ACTIONS.SET_CURRENT_APPROVAL_REQUEST]: (_, action) => {
      return {
        approvalRequestId: action.approvalRequestId,
      }
    },
    [BUSINESS_TRAVELLER_ACTIONS.CLEAR_CURRENT_APPROVAL_REQUEST]: () => {
      return { approvalRequestId: undefined }
    },
    [BUSINESS_TRAVELLER_ACTIONS.UPDATE_CREDIT_CUSTOMER_DETAILS]: (state, action) => {
      if (!state.currentBusinessAccount?.employee || !state.currentBusinessAccount?.business) {
        return state
      }
      return {
        ...state,
        currentBusinessAccount: {
          status: 'success',
          business: state.currentBusinessAccount.business,
          employee: {
            ...state.currentBusinessAccount.employee,
            creditCustomerEmail: action.creditCustomerEmail,
          },
        },
      }
    },
  },
)

export default businessTravellerReducer
