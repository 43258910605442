import React from 'react'
import Pane from 'components/Common/Pane'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import styled from 'styled-components'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import cn from 'clsx'
import LuxLoyaltyPointsListItem from 'luxLoyalty/components/LuxLoyaltyPointsListItem'
import LuxLoyaltyProductLogo from './LuxLoyaltyProductLogo'
import getLuxLoyaltyEligibleTier from 'luxLoyalty/selectors/getLuxLoyaltyEligibleTier'
import Caption from 'components/Luxkit/Typography/Caption'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import getLuxLoyaltyProgramDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyProgramDisplayName'
import { useAppSelector } from 'hooks/reduxHooks'

const LuxLoyaltyBannerSheet = styled(Pane)`
  padding: ${rem(32)} ${rem(28)};
  background: linear-gradient(
    275deg,
    ${props => props.theme.palette.neutral.default.one},
    ${props => props.theme.palette.neutral.control.black100}
  );
`

const TierBar = styled.div`
  height: ${rem(8)};

  &.tier-bronze {
    background: ${props => props.theme.palette.product.luxLoyalty.bronze.gradient};
  }
  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.gradient};
  }
  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.gradient};
  }
`

const TierBadge = styled(Caption)`
  padding: ${rem(8)};

  &.tier-bronze {
    background: ${props => props.theme.palette.product.luxLoyalty.bronze.foreground};
  }
  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.foreground};
  }
  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.foreground};
  }
`

const LuxLoyaltyLogoWrapper = styled.div`
  color: ${props => props.theme.palette.neutral.default.six};
`

interface Props {
  onAfterClose: React.ComponentProps<typeof ModalBase>['onAfterClose']
  primaryActionProps: React.ComponentProps<typeof ModalFooter>['primaryActionProps']
  secondaryActionProps?: React.ComponentProps<typeof ModalFooter>['secondaryActionProps']
}

function LuxLoyaltyJoinModal(props: Props) {
  const {
    onAfterClose,
    primaryActionProps,
    secondaryActionProps,
  } = props

  const programName = getLuxLoyaltyProgramDisplayName()
  const accountEligibleTier = useAppSelector(getLuxLoyaltyEligibleTier)
  const givenName = useAppSelector((state: App.State) => state.auth.account.givenName)
  const tierName = getLuxLoyaltyTierDisplayName(accountEligibleTier)
  const welcomeCopy = givenName ? `${givenName}, welcome` : 'Welcome'

  return <ModalBase dismissible onAfterClose={onAfterClose}>
    <ModalBody>
      <LuxLoyaltyBannerSheet className={cn(`tier-${accountEligibleTier}`)}>
        <Group direction="vertical" horizontalAlign="center" gap={24} tabletGap={16}>
          <Group direction="vertical" horizontalAlign="center" gap={24}>
            <LuxLoyaltyLogoWrapper>
              <LuxLoyaltyProductLogo height={24} />
            </LuxLoyaltyLogoWrapper>
            {accountEligibleTier === 'bronze' && <Heading variant="heading3" format="propercase" align="center" colour="neutral-eight">
              {welcomeCopy} to {programName} where rewards start now
            </Heading>}
            {accountEligibleTier === 'silver' && <Heading variant="heading3" format="propercase" align="center" colour="neutral-eight">
              {welcomeCopy} to instant rewards as a {tierName} {programName} member
            </Heading>}
            {accountEligibleTier === 'gold' && <Heading variant="heading3" format="propercase" align="center" colour="neutral-eight">
              {welcomeCopy} to instant upgrades as a {tierName} {programName} member
            </Heading>}
          </Group>
          <TierBadge
            className={cn(`tier-${accountEligibleTier}`)}
            variant="small"
            format="uppercase"
          >
            {accountEligibleTier}
          </TierBadge>
        </Group>
      </LuxLoyaltyBannerSheet>
      <TierBar className={cn(`tier-${accountEligibleTier}`)} />
      <ModalContent>
        <VerticalSpacer gap={8}>
          <BodyText variant="large" weight="bold">
            Your journey starts here
          </BodyText>
          <LuxLoyaltyPointsListItem size="S" tier={accountEligibleTier}>
            Earn & redeem points on every booking
          </LuxLoyaltyPointsListItem>
          <LuxLoyaltyPointsListItem size="S" tier={accountEligibleTier}>
            Earn status credits on all bookings
          </LuxLoyaltyPointsListItem>
          <LuxLoyaltyPointsListItem size="S" tier={accountEligibleTier}>
            Access to exclusive {programName} offers & more
          </LuxLoyaltyPointsListItem>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    <ModalFooter
      primaryActionProps={primaryActionProps}
      secondaryActionProps={secondaryActionProps}
    />
  </ModalBase>
}

export default LuxLoyaltyJoinModal
