import React, { useMemo } from 'react'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import { nonNullable } from 'lib/array/arrayUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Table from 'components/Luxkit/Tables/Table'
import TableHeader from 'components/Luxkit/Tables/TableHeader'
import TableHeaderRow from 'components/Luxkit/Tables/TableHeaderRow'
import TableHeaderCell from 'components/Luxkit/Tables/TableHeaderCell'
import TableBodyRow from 'components/Luxkit/Tables/TableBodyRow'
import TableBody from 'components/Luxkit/Tables/TableBody'
import TableBodyCell from 'components/Luxkit/Tables/TableBodyCell'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'

const IconsByProductType = {
  cruise: LineShipIcon,
}

interface Props {
  calculationRequests: Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>;
}

function LuxLoyaltyInfoModal(props: Props) {
  const { calculationRequests } = props

  const requestsToCalculate = useMemo(() => nonNullable(calculationRequests), [calculationRequests])
  const customerId = useAppSelector(state => state.auth.account.memberId ?? '')
  const pointsEarnCalculations = useAppSelector(state => state.luxLoyalty.pointsEarnCalculations)

  return <ModalBase dismissible={false}>
    <ModalHeader title="Earning points" />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={16}>
          <BodyText variant="medium">
            Below is a breakdown of the points earned for each item in your cart.
          </BodyText>

          <Table>
            <TableHeader key="header">
              <TableHeaderRow>
                <TableHeaderCell minWidth={96}>
                  <BodyText variant="medium" colour="neutral-two">Package options</BodyText>
                </TableHeaderCell>
                <TableHeaderCell minWidth={96}>
                  <BodyText variant="medium" colour="neutral-two">Points</BodyText>
                </TableHeaderCell>
                <TableHeaderCell minWidth={96}>
                  <BodyText variant="medium" colour="neutral-two">Status credits</BodyText>
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody key="body">
              {requestsToCalculate.map((calculationRequest) => {
                const Icon = IconsByProductType[calculationRequest.luxLoyaltyProductType] ?? null
                const pointsCalculation = pointsEarnCalculations[buildPointsKey(calculationRequest, customerId)]

                return <TableBodyRow key={calculationRequest?.luxLoyaltyProductType} >
                  <TableBodyCell>
                    <BodyTextBlock variant="medium" colour="neutral-three" startIcon={!!Icon && <Icon />} format="propercase">
                      {calculationRequest?.luxLoyaltyProductType}
                    </BodyTextBlock>
                  </TableBodyCell>
                  <TableBodyCell>
                    <BodyText variant="medium" colour="neutral-three">
                      {pointsCalculation?.data?.points}pts
                    </BodyText>
                  </TableBodyCell>
                  <TableBodyCell>
                    {pointsCalculation?.data?.statusCredits}
                  </TableBodyCell>
                </TableBodyRow>
              })}
            </TableBody>
          </Table>

          <BodyText variant="small">
            Please note that the final points balance will credited to your account at the end of your trip and may be different from what you see here.
          </BodyText>

          <BodyText variant="small">
            You will not earn points on any portion or whole item that uses points to pay. Points can only be earned on the remaining dollar value. Some payments types are not eligible for earning points such as promotional codes, credits received before Société launched, credits received due to cancellation of a booking before Société launched.
          </BodyText>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </ModalBase>
}

export default LuxLoyaltyInfoModal
