import { AppAction } from 'actions/ActionTypes'
import { API_CALL } from 'actions/actionConstants'
import requestCalculateBusinessCredits from 'businessTraveller/api/requestGetCalculatedBusinessBudget'
import { BUSINESS_TRAVELLER_API_ACTIONS } from 'reducers/businessTravellerApiActionReducers'

function fetchBusinessBudget(
  businessId: string,
  toBeCalculatedBusinessBudget: [budgetKey: string, businessBudget: App.BusinessTraveller.ToBeCalculatedBusinessBudget],
  refetch?: boolean,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const [budgetKey, businessBudget] = toBeCalculatedBusinessBudget
    const currentBusinessBudget = state.businessTraveller.businessBudgets[budgetKey]

    if (!currentBusinessBudget || refetch) {
      dispatch({
        type: API_CALL,
        api: BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_BUDGET,
        budgetKey,
        request: () => requestCalculateBusinessCredits({
          businessId,
          toBeCalculatedBusinessBudget: businessBudget,
        }),
      })
    }
  }
}

export default fetchBusinessBudget
