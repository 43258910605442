import clsx from 'clsx'
import React, { forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.thead`
`

interface Props extends HTMLAttributes<HTMLTableSectionElement> {
}

/**
 * Must be child of `<Table>`.
 *
 * Contains `<TableHeaderRow>`s.
 */
const TableHeader = forwardRef<HTMLTableSectionElement, Props>(function TableHeader(props, ref) {
  return <Container {...props} ref={ref} className={clsx(TableHeader.name, props.className)} />
})

export default TableHeader
