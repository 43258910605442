import { getExperienceItems } from 'checkout/selectors/view/experience'
import { isStandaloneGiftCard } from 'checkout/selectors/view/giftCard'
import { isStandaloneLuxPlusSubscription } from 'checkout/selectors/view/luxPlusSubscription'
import { PRIMARY_TRAVELLER_FORM_ID } from 'constants/checkout'
import config from 'constants/config'
import { isBookingProtectionItem, isExperienceItem, isInsuranceItem, isLuxPlusSubscriptionItem } from 'lib/checkout/checkoutUtils'
import { removeRegionFromPath } from 'lib/url/searchUrlUtils'
import { createSelector } from 'reselect'

export const getPrimaryTravellerForm = createSelector(
  (state: App.State) => state.checkout.form.travellerForms,
  (forms) => forms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID),
)

export const isStandaloneExperience = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getExperienceItems(state),
  (allItems, experienceItems): boolean => {
    // a standalone experience can still have insurance/booking protection and/or a luxplus subscription
    return experienceItems.length > 0 && allItems.every(item =>
      isExperienceItem(item) ||
      isInsuranceItem(item) ||
      isBookingProtectionItem(item) ||
      isLuxPlusSubscriptionItem(item))
  },
)

export const isCheckout = createSelector(
  (state: App.State) => removeRegionFromPath(state.router.location.pathname),
  (state: App.State) => state.checkout.cart.items,
  (pathnameWithoutRegion, cartItems) => !!cartItems.length && pathnameWithoutRegion.startsWith('/checkout'),
)

/**
 * Returns the ID of the cart that can also be used to restore the cart, use only when the user has gone to the checkout
 *
 * It currently returns the transactionKey, which is the same as the cartId, except if the order request fails and is retried
 */
export const getCartId = (state: App.State) => state.checkout.cart.transactionKey

/**
 *
 * Returns the full URI for the restore cart link e.g. https://luxuryescapes.com/au/checkout-restore-cart?cartId=eb612281-b2c2-44d9-972f-8c0d2be8183b
 *
 * **Use getRestoreCartUriForId from restoreCartUtils for relative link**
 *
 */
export const getRestoreCartFullUri = createSelector(
  (state: App.State) => getCartId(state),
  (state: App.State) => state.geo.currentRegionCode,
  (cartId: string, regionCode: string) => {
    return `${config.SCHEME}://${config.CUSTOMER_PORTAL_HOST}/${regionCode.toLowerCase()}/checkout-restore-cart?cartId=${cartId}`
  },
)

export const getInsuranceAgentUpsellData = (state: App.State) => state.checkout.insuranceAgentUpsellData

export const isSimpleStandaloneCheckout = createSelector(
  (state: App.State) => isStandaloneGiftCard(state),
  (state: App.State) => isStandaloneLuxPlusSubscription(state),
  (isStandaloneGiftCard, isStandaloneLuxPlusSubscription): boolean => isStandaloneGiftCard || isStandaloneLuxPlusSubscription,
)

export const geCartItemTypes = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<string> => items.map(item => item.itemType).sort(),
)
