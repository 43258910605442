import { createSelector } from 'reselect'
import getCanRedeemTransferLoyaltyBenefit from './getCanRedeemTransferLoyaltyBenefit'
import getCanRedeemLoungePassLoyaltyBenefit from './getCanRedeemLoungePassLoyaltyBenefit'

const getCanRedeemCheckoutLoyaltyBenefits = createSelector(
  (state: App.State) => getCanRedeemTransferLoyaltyBenefit(state),
  (state: App.State) => getCanRedeemLoungePassLoyaltyBenefit(state),
  (canRedeemTransfers, canRedeemLoungePasses): boolean => {
    return canRedeemLoungePasses || canRedeemTransfers
  },
)

export default getCanRedeemCheckoutLoyaltyBenefits
