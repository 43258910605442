import Modal from 'components/Luxkit/Modal/Modal'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TourV2Itinerary from 'components/OfferPage/TourV2/Itinerary/TourV2Itinerary'
import Group from 'components/utils/Group'
import ModalContext from 'contexts/ModalContext'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import { pluralizeToString } from 'lib/string/pluralize'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import { getTourV2VariationDuration } from 'lib/tours/tourUtils'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import { generateLuxLoyaltyPointsCalculatorTourOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import React, { MouseEventHandler, useCallback, useContext, useMemo } from 'react'
import FormatDateTime from 'components/utils/Formatters/FormatDateTime'
import { last, sortBy } from 'lib/array/arrayUtils'

interface Props {
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary
  variation?: App.Tours.TourV2OfferVariation
  filters?: App.OfferListFilters
}

function TourV2SearchTileMeta({ offer, variation, filters }: Props) {
  const showModal = useContext(ModalContext)

  const offerUrl = useOfferUrl(offer, {
    filters,
    offerLinkIncludesFilters: true,
  })

  const list = useMemo<Array<string>>(() => {
    if (variation) {
      return [
        pluralizeToString('day', getTourV2VariationDuration(variation)),
        pluralizeToString('place', variation.placesVisited.length),
        pluralizeToString('country', variation.countriesVisited.length),
      ]
    }
    return []
  }, [variation])

  const { firstDepartureDate, lastDepartureDate } = useMemo<{ firstDepartureDate: string, lastDepartureDate: string }>(() => {
    const sortedDepartures = sortBy(variation?.departures ?? [], (departure) => departure.startDate, 'asc')
    const firstDepartureDate = sortedDepartures[0].startDate
    const lastDepartureDate = last(sortedDepartures).startDate
    return { firstDepartureDate, lastDepartureDate }
  }, [variation])

  const hasOneDeparture = firstDepartureDate === lastDepartureDate

  const openItineraryModal = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
    event.preventDefault()
    showModal(<Modal
      title="Trip itinerary"
      primaryActionText="View offer"
      primaryActionTo={offerUrl}
    >
      <TourV2Itinerary
        itinerary={variation!.itinerary}
        offerType={offer.type}
        showPrint
        showExperiences={false}
      />
    </Modal>)
  }, [showModal, variation, offer, offerUrl])

  const pointsEarnCalculationOptions = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(
    () => {
      const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer)
      return [generateLuxLoyaltyPointsCalculatorTourOptions(offer, { price: cheapestPurchasableOption?.price, memberPrice: cheapestPurchasableOption?.memberPrice })]
    },
    [offer],
  )

  return <Group direction="vertical" gap={4}>
    <LuxLoyaltyPoints calculationRequests={pointsEarnCalculationOptions} calculationType="estimate" />
    <BodyText variant="medium" colour="neutral-two" weight="bold">{offer.operatedBy}</BodyText>
    {variation && <Group direction="horizontal" gap="4 8" wrap="wrap">
      <BodyText variant="medium">{list.join(' · ')}</BodyText>

      {!!variation.itinerary.length && <TextLink onClick={openItineraryModal} size="medium">View itinerary</TextLink>}
      <BodyText variant="medium">
        {hasOneDeparture && <>Travel on <b><FormatDateTime value={firstDepartureDate} format="short-month-long-year" /></b></>}
        {!hasOneDeparture && <>Travel from <b><FormatDateTime value={firstDepartureDate} format="short-month-long-year" /></b> to <b><FormatDateTime value={lastDepartureDate} format="short-month-long-year" /></b></>}
      </BodyText>
    </Group>}
  </Group>
}

export default TourV2SearchTileMeta
