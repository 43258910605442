import { EmptyObject } from 'lib/object/objectUtils'
import { buildSuggestedDatesParamsKey, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { useMemo } from 'react'
import { useAppSelector } from './reduxHooks'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { checkCanViewLuxPlusBenefits, getIsLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import useBedbankFlightPrice from './OfferPage/useBedbankFlightPrice'
import { useDirectSearchPrices } from './Search/useSearchPrices'
import useBedbankRates from './useBedbankRates'
import { useBedbankRoomRatePromotions, useBedbankSearchTilePromotions } from './OfferPage/useBedbankPromotions'
import useBedbankOfferSell from './useBedbankOfferSell'
import { max } from 'lib/array/arrayUtils'
import getBedbankPromoExtraValue, { getDisplayTotalPrice, getDisplayTotalPricePerNight } from 'lib/hotels/bedbankUtils'
import { calculateDiscount } from 'lib/payment/calculateDiscount'
import { OptimizelyExperiments } from 'constants/optimizely'

type RatePredicate = (rate: App.BedbankRate) => boolean

const DEFAULT_OCCUPANTS: Array<App.Occupants> = [{ adults: 2 }]

function useBedbankOfferSearchPricing(
  offer: App.BedbankOffer | App.BedbankOfferSummary,
  filters: App.OfferListFilters = EmptyObject,
  rateId?: string | RatePredicate,
) {
  const flexibleSearchFilterKey = useMemo(() => {
    return buildSuggestedDatesParamsKey(filters?.flexibleMonths, filters?.flexibleNights, filters?.rooms)
  }, [filters])
  const suggestedDates = useAppSelector(state => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))
  const isPerNightPricingTestEnabled = useAppSelector(state => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const luxPlusLPPEnabled = useAppSelector(getIsLuxPlusLPPEnabled)

  const rooms = filters.rooms ?? DEFAULT_OCCUPANTS
  const flightPrice = useBedbankFlightPrice(offer, {
    rooms,
    startDate: filters.checkIn,
    endDate: filters.checkOut,
  })

  const directSearchPrices = useDirectSearchPrices({ filters, offerId: offer.id })
  const searchStreamingEnabled = isSearchStreamingSupported(filters)

  const [rates, fetchingRates] = useBedbankRates(
    offer.id,
    rooms,
    filters.checkIn,
    filters.checkOut,
    !searchStreamingEnabled,
  )

  const ratePredicate = useMemo<RatePredicate>(() => {
    if (typeof rateId === 'function') return rateId
    else return (rate: App.BedbankRate) => rate.id === rateId
  }, [rateId])

  const selectedHotelRates = rateId ? rates.hotelOnlyRates.filter(ratePredicate) : []
  const hotelRate = selectedHotelRates.length ? selectedHotelRates[0] : rates.hotelOnlyRates[0]
  const flightBundledRate = rates.hotelBundleRates[0] ?? hotelRate
  const shownRate: App.BedbankRate | undefined = offer.promoteAsBundle ? flightBundledRate : hotelRate
  const promotions = useBedbankSearchTilePromotions(
    offer.promotions,
    shownRate ?? (directSearchPrices?.lowestPriceRoomRateId ? { id: directSearchPrices?.lowestPriceRoomRateId } : undefined),
    filters?.checkIn,
    filters?.checkOut,
  )

  const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut

  let duration = shownRate?.nights
  if (promotions.length && !filters?.checkIn && !filters?.checkOut) {
    duration = Math.min(...promotions.map(x => x.los))
  }

  const sell = useBedbankOfferSell(offer, duration, rooms)
  const promo = useBedbankRoomRatePromotions(
    offer.promotions,
    filters?.checkIn,
    filters?.checkOut,
  )

  let taxesAndFees = shownRate?.totals.taxesAndFees ?? sell?.taxesAndFees ?? 0
  let propertyFees = shownRate?.totals.propertyFees ?? sell?.propertyFees ?? 0
  const bundleTaxesAndFees = flightBundledRate?.totals.taxesAndFees || taxesAndFees
  const bundlePropertyFees = flightBundledRate?.totals.propertyFees || propertyFees

  let hotelPrice = (suggestedDates?.price ?? (shownRate ? shownRate.totals.inclusive : sell?.price ?? 0)) + propertyFees
  const calculateHotelMemberPrice = suggestedDates?.memberPrice ?? (shownRate ? shownRate?.totals.memberInclusive : 0) ?? 0
  let hotelMemberPrice = calculateHotelMemberPrice > 0 ? calculateHotelMemberPrice + propertyFees : 0
  const calculateBundleMemberMemberPrice = flightBundledRate?.totals.memberInclusive || hotelMemberPrice
  const bundlePrice = (flightBundledRate?.totals.inclusive || hotelPrice) + bundlePropertyFees
  const bundleMemberPrice = calculateBundleMemberMemberPrice > 0 ? calculateBundleMemberMemberPrice + bundlePropertyFees : 0

  let hotelValue: undefined | number
  let bundleValue: undefined | number

  let hotelNights = shownRate?.nights ?? sell?.los
  let hotelDiscount: number
  let hotelMemberDiscount: number = 0
  // Best margin %, not value (aud)
  const hotelOnlyRates = selectedHotelRates.length ? selectedHotelRates : rates.hotelOnlyRates
  const rateWithBestMargin = max(hotelOnlyRates, (rate) => rate.margin ?? Number.NEGATIVE_INFINITY)
  const margin = rateWithBestMargin?.margin
  const marginAud = rateWithBestMargin?.marginAud
  const supplier = rateWithBestMargin?.supplier
  const isSpoofed = useAppSelector(state => state.auth.account.isSpoofed)
  const showMarginInSpoofMode = useAppSelector(state => state.system.showMarginInSpoofMode)
  const displayMargin = Boolean(margin && marginAud && supplier && isSpoofed && showMarginInSpoofMode)
  let mx: undefined | string

  const useMemberPrice = luxPlusLPPEnabled && canViewLuxPlusBenefits

  if (shownRate && 'packages' in offer) {
    const pkg = shownRate.room
    const isPromoEnabledRate = !!promo[shownRate.id]
    mx = shownRate.mx

    if (isPromoEnabledRate) {
      const inclusionsAmount = pkg?.sell ? getBedbankPromoExtraValue({
        price: pkg.sell.price,
        value: pkg.sell.value,
        minLos: pkg.sell.los,
        duration,
      }) : 0
      hotelValue = inclusionsAmount + (shownRate.totals.inclusive)
      bundleValue = inclusionsAmount + (flightBundledRate?.totals.inclusive || hotelValue)
      hotelDiscount = Number((inclusionsAmount / hotelValue).toFixed(2))
    } else {
      hotelValue = shownRate.value
      bundleValue = flightBundledRate?.value || hotelValue
      hotelDiscount = shownRate.discount
      hotelMemberDiscount = shownRate.memberDiscount
    }
  } else if (suggestedDates?.value) {
    hotelValue = suggestedDates.value
    hotelDiscount = sell?.discount ?? 0
  } else {
    hotelValue = sell?.value ?? 0
    bundleValue = sell?.value
    hotelDiscount = sell?.discount ?? 0
  }

  // override with search prices if passed down to this component
  if (directSearchPrices) {
    hotelPrice = directSearchPrices.lowestPrice ?? hotelPrice
    hotelMemberPrice = directSearchPrices.lowestMemberPrice ?? hotelMemberPrice
    hotelValue = directSearchPrices.lowestPriceValue ?? hotelValue
    hotelDiscount = calculateDiscount((useMemberPrice && hotelMemberPrice > 0) ? hotelMemberPrice : hotelPrice, hotelValue)
    taxesAndFees = directSearchPrices.lowestPriceTaxes ?? taxesAndFees
    propertyFees = directSearchPrices.lowestPricePropertyFees ?? propertyFees
    hotelNights = directSearchPrices.duration ?? hotelNights
    mx = directSearchPrices.mx
  }

  const totalPrices = getDisplayTotalPrice({
    hotelPrice,
    hotelValue,
    bundlePrice,
    hotelMemberPrice,
    bundleMemberPrice,
    bundleValue,
    hotelDiscount,
    hotelMemberDiscount,
    checkIn: filters.checkIn,
    flightPrice,
    rooms,
    displayPricingAsPerPerson: offer.displayPricingAsPerPerson,
  })
  const totalPricesPerNight = getDisplayTotalPricePerNight(totalPrices, hotelNights || 1)

  const promoteAsBundle = !!flightPrice && offer.promoteAsBundle

  let offerPrice = promoteAsBundle ? totalPrices.totals.flightBundlePrice : totalPrices.totals.price
  let offerMemberPrice = promoteAsBundle ? totalPrices.totals.flightBundleMemberPrice : totalPrices.totals.memberPrice

  const perNightOfferPrice = promoteAsBundle ? totalPricesPerNight.totals.flightBundlePrice : totalPricesPerNight.totals.price
  const perNightOfferMemberPrice = promoteAsBundle ? totalPricesPerNight.totals.flightBundleMemberPrice : totalPricesPerNight.totals.memberPrice

  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch
  const showOneNightPrice = !isPerNightPricingTestEnabled && isAnytimeSearch && !offer.promotions.length

  const nightsToDisplay = showOneNightPrice ? 1 : (hotelNights || 0)
  offerPrice = showOneNightPrice ? (offerPrice / hotelNights) : offerPrice

  if (showOneNightPrice && offerMemberPrice > 0) {
    offerMemberPrice /= hotelNights
  }

  const baseSaleUnit = isPerNightPricingTestEnabled ? 'night' : 'total'
  const saleUnit = offer.displayPricingAsPerPerson ? 'person' : baseSaleUnit
  offerPrice = isPerNightPricingTestEnabled ? perNightOfferPrice : offerPrice
  offerMemberPrice = isPerNightPricingTestEnabled ? perNightOfferMemberPrice : offerMemberPrice

  let value = promoteAsBundle ? totalPrices.totals.flightBundleValue : totalPrices.totals.value
  value = (value / hotelNights) * nightsToDisplay
  let price = promoteAsBundle ? totalPrices.totals.flightBundlePrice : totalPrices.totals.price
  let memberPrice = promoteAsBundle ? totalPrices.totals.flightBundleMemberPrice : totalPrices.totals.memberPrice
  price = (price / hotelNights) * nightsToDisplay
  memberPrice = memberPrice > 0 ? (memberPrice / hotelNights) * nightsToDisplay : 0
  const calcDiscount = useMemberPrice && memberPrice > 0 ? memberPrice : price
  const discountPercentage = calculateDiscount(calcDiscount, value)

  const offerPriceBeforeTax = offerPrice - taxesAndFees - propertyFees
  const offerMemberPriceBeforeTax = offerMemberPrice ? offerMemberPrice - taxesAndFees - propertyFees : 0

  return {
    bundleMemberPrice,
    bundlePrice,
    bundlePropertyFees,
    bundleTaxesAndFees,
    discountPercentage,
    displayMargin,
    fetchingRates,
    flightPrice,
    hotelMemberPrice,
    hotelNights,
    hotelPrice,
    hotelRate,
    isFlexibleSearch,
    margin,
    marginAud,
    nightsToDisplay,
    offerMemberPrice,
    offerMemberPriceBeforeTax,
    offerPrice,
    offerPriceBeforeTax,
    promoteAsBundle,
    propertyFees,
    rooms,
    saleUnit,
    shownRate,
    suggestedDates,
    supplier,
    taxesAndFees,
    totalPrices,
    value,
    mx,
  }
}

export default useBedbankOfferSearchPricing
