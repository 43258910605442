import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import SearchForm from 'components/Search/SearchForm'
import type { SearchFormRef } from 'components/Search/SearchForm'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import HotelSearchHeaderBar from './HotelSearchHeaderBar'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useScrollDirection from 'hooks/useScrollDirection'
import { useInView } from 'react-intersection-observer'
import { mediaQueryDown, mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'
import HotelSearchPageFilters from './HotelSearchPageFilters'
import { OfferListSortOption } from 'constants/offerListFilters'
import zIndex from 'styles/tools/z-index'
import { ANYWHERE_NAME, HOTEL_SEARCH_TYPEAHEAD_TYPES, SEARCH_VERTICALS } from 'constants/search'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import { connect } from 'react-redux'
import AnywhereFilterTabs from './AnywhereFilterTabs'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import { SearchMenuStates } from 'components/Search/type'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import HotelExposedFiltersHeader from './HotelExposedFiltersHeader/HotelExposedFiltersHeader'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import SearchField from './SearchField'
import config from 'constants/config'
import { FilterPill } from 'components/OfferList/OfferListFilter/OfferListFilters'

const SearchFormContainer = styled(LayoutContainer)`
  padding: ${rem(8)};
  display: none;
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.tablet} {
    display: block;
  }

  &.map-view {
    ${mediaQueryOnly.tablet} {
      display: none;
    }
  }

  ${mediaQueryUp.desktop} {
    padding: ${rem(16)} ${rem(32)};
  }

  &.edit-mode {
    display: block;
  }
`

const Wrapper = styled.div`
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
  margin-top: ${rem(8)};
`

const TabsContainer = styled(LayoutContainer)`
  padding: ${rem(8)} 0 ${rem(8)} ${rem(16)};
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
  ${mediaQueryUp.tablet} {
    border: none;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }
`

const StyledSearchResultsBar = styled(HotelSearchHeaderBar)`
  ${mediaQueryUp.tablet} {
    display: none;
  }

  &.map-view {
    ${mediaQueryDown.tablet} {
      display: flex;
    }
  }

  &.edit-mode {
    display: none;
  }
`

const StyledPane = styled(Pane)`
  position: sticky;
  transition: top 1s ease-in-out;
  z-index: ${zIndex.searchMenu};

  &.offScreen {
    width: 100%;
    top: -50%;
    &:not(.mapPage) {
      box-shadow: ${props => props.theme.shadow.bottom.small};
    }
    transition: top 1s ease-in-out;
  }
  &.lifted {
    top: 0;
    transition: top 0.5s ease-out;
    z-index: ${zIndex.stickySubHeader};
    &:not(.mapPage) {
      box-shadow: ${props => props.theme.shadow.bottom.small};
    }
  }
`

const FilterContainer = styled.div`
  padding: ${rem(16)};
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const BusinessTravellerMapFilter = styled.div`
  &.hasBusinessBudget {
    ${FilterPill} {
      top: ${rem(140)};

      ${mediaQueryUp.tablet} {
        top: ${rem(144)};
      }

      ${mediaQueryUp.desktop} {
        top: ${rem(160)};
      }
    }
  }
`

const businessFilterOverrides = {}

interface MappedStateProps {
  windowSearch: string;
  pathname: string;
}

interface Props {
  filters: App.OfferListFilters;
  formRef: React.Ref<SearchFormRef>,
  offerListFiltersProps: {
    searchType: App.SearchItem['searchType'],
    switchView: {
      label: string,
      view: 'map' | 'list'
      URL: string
    },
    sortOptions?: Array<OfferListSortOption>,
    listFilters: App.OfferListFilters,
  }
  searchVertical: SEARCH_VERTICALS
  baseSearchPath: string
  baseSearchMapPath: string
  disableAnywhereFilterTabs?: boolean
  locationSearchPlaceholder?: string
  typeaheadTypes?: Array<App.SearchPlaceType>
  hasBusinessBudget?: boolean
}

function HotelSearchHeader(props: Props & MappedStateProps) {
  const {
    formRef,
    filters,
    offerListFiltersProps: { searchType, switchView, sortOptions, listFilters },
    windowSearch,
    searchVertical,
    baseSearchPath,
    baseSearchMapPath,
    disableAnywhereFilterTabs,
    locationSearchPlaceholder,
    typeaheadTypes = HOTEL_SEARCH_TYPEAHEAD_TYPES,
    hasBusinessBudget,
    pathname,
  } = props

  const {
    isEditMode,
    activeMenu,
  } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()

  const openSearchMenu = useCallback(() => { toggleSearchVertical(searchVertical) }, [toggleSearchVertical, searchVertical])

  const scrollDirection = useScrollDirection({ tolerance: 20 })

  const [toLiftRef, liftInView] = useInView()

  const params = useMemo(() => parseSearchString(windowSearch), [windowSearch])
  const searchTargetLabel = params.destinationName
  const isAnywhereSearch = searchTargetLabel === ANYWHERE_NAME

  useEffect(() => {
    if (isEditMode) {
      window.scrollTo(0, 0)
    }
  }, [isEditMode])

  useEffect(() => {
    if (!liftInView && isEditMode && scrollDirection === 'down') {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_EDIT_MODE,
        isEditMode: false,
      })
    }
  }, [liftInView, isEditMode, scrollDirection, globalSearchDispatch])

  const onSearch = useCallback(() => {
    globalSearchDispatch({
      type: GlobalSearchStateActions.SET_EDIT_MODE,
      isEditMode: false,
    })
  }, [globalSearchDispatch])

  const isLifted = !liftInView && scrollDirection === 'up'
  const isOffScreen = !liftInView && scrollDirection !== 'up'

  const isMobileScreen = useScreenSizeOnly('mobile')

  const isMapPage = pathname.includes('map')

  const showSlimMobileSearch = useSlimMobileSearch()

  return <>
    <StyledPane
      className={cn({
        lifted: isLifted || activeMenu !== SearchMenuStates.Closed,
        offScreen: isOffScreen && activeMenu === SearchMenuStates.Closed,
        mapPage: isMapPage,
      })}
      shadow={!isMapPage}
    >
      {showSlimMobileSearch && <SearchField
        onEdit={openSearchMenu}
        filters={listFilters}
        sortOptions={sortOptions}
      />}
      {!showSlimMobileSearch && <StyledSearchResultsBar
        className={cn({ 'edit-mode': isEditMode && liftInView, 'map-view': isMapPage })}
        onEdit={openSearchMenu}
        switchView={switchView}
      />}
      {isMapPage &&
        <>
          <SearchFormContainer as="div" className="map-view">
            <SearchForm
              submitOnApply
              filters={filters}
              ref={formRef}
              onSubmit={onSearch}
              typeaheadTypes={typeaheadTypes}
              switchView={switchView}
              baseSearchPath={baseSearchPath}
              baseSearchMapPath={baseSearchMapPath}
              locationSearchPlaceholder={locationSearchPlaceholder}
            />
          </SearchFormContainer>
          <CSSBreakpoint min="largeDesktop">
            <FilterContainer>
              <HotelExposedFiltersHeader filters={listFilters} />
            </FilterContainer>
          </CSSBreakpoint>
        </>}
      {!isMapPage &&
        <SearchFormContainer className={cn({ 'edit-mode': isEditMode && liftInView })}>
          <SearchForm
            submitOnApply
            filters={filters}
            ref={formRef}
            onSubmit={onSearch}
            typeaheadTypes={typeaheadTypes}
            switchView={switchView}
            baseSearchPath={baseSearchPath}
            baseSearchMapPath={baseSearchMapPath}
            locationSearchPlaceholder={locationSearchPlaceholder}
          />
        </SearchFormContainer>}
      {!isMobileScreen && isAnywhereSearch && !isMapPage && !disableAnywhereFilterTabs && <Wrapper>
        <TabsContainer>
          <AnywhereFilterTabs
            filters={listFilters}
            search={windowSearch}
          />
        </TabsContainer>
      </Wrapper>}
      {(config.businessTraveller.currentAccountMode !== 'business' || !config.BUSINESS_TRAVELLER_SPLIT_MAP_VIEW_UPDATES_ENABLED) && searchType !== 'property' && isMapPage &&
        <HotelSearchPageFilters
          overrides={businessFilterOverrides}
          filters={listFilters}
          sortOptions={sortOptions}
          switchView={switchView}
        />
      }
      {config.businessTraveller.currentAccountMode === 'business' && config.BUSINESS_TRAVELLER_SPLIT_MAP_VIEW_UPDATES_ENABLED && searchType !== 'property' && isMapPage &&
        <BusinessTravellerMapFilter className={cn({ hasBusinessBudget })}>
          <HotelSearchPageFilters
            overrides={businessFilterOverrides}
            filters={listFilters}
            sortOptions={sortOptions}
            switchView={switchView}
          />
        </BusinessTravellerMapFilter>
      }
      {isMobileScreen && isAnywhereSearch && !isMapPage && !disableAnywhereFilterTabs && <TabsContainer>
        <AnywhereFilterTabs filters={listFilters} search={windowSearch} />
      </TabsContainer>}
    </StyledPane>
    <div ref={toLiftRef} />
  </>
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState): MappedStateProps => ({
  windowSearch: appState.router.location.search,
  pathname: appState.router.location.pathname,
}))(HotelSearchHeader)
