import React from 'react'
import { connect } from 'react-redux'
import BusinessTravellerWithinBudgetListFilter from './BusinessTravellerWithinBudgetListFilter'
import BusinessTravellerWithinBudgetMapFilter from './BusinessTravellerWithinBudgetMapFilter'
import config from 'constants/config'

interface MappedStateProps {
  path: string,
}

interface Props {
  filters: App.OfferListFilters;
  search: string;
}

function BusinessTravellerWithinBudgetFilter(props: Props & MappedStateProps) {
  const { filters, search, path } = props

  const isMapView = path.includes('/map')

  return <>
    {(!isMapView || !config.BUSINESS_TRAVELLER_SPLIT_MAP_VIEW_UPDATES_ENABLED) && <BusinessTravellerWithinBudgetListFilter
      filters={filters}
      search={search}
    />}
    {isMapView && config.BUSINESS_TRAVELLER_SPLIT_MAP_VIEW_UPDATES_ENABLED && <BusinessTravellerWithinBudgetMapFilter
      search={search}
    />}
  </>
}

const mapStateToProps = (state: App.State) => {
  return {
    path: state.router.location.pathname,
  }
}

export default connect(mapStateToProps)(BusinessTravellerWithinBudgetFilter)
