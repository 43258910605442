import { createSelector } from 'reselect'
import { getExperienceItemsView, getTransferItemsView } from 'checkout/selectors/view/experience'
import { excludeNullOrUndefined } from 'checkout/utils'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
} from 'constants/checkout'
import { OFFER_TYPE_CRUISE, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import { RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { getInsuranceItems } from 'checkout/selectors/view/insurance'
import { getCheckoutInsuranceTravellers } from 'checkout/selectors/request/insurance'
import { groupBy, nonNullable, sortBy } from 'lib/array/arrayUtils'
import { getCarHireItemsView } from '../view/carHire'
import { findPostPurchaseCheckout, getTourV2IdForCheckoutItem, isTourV1Item } from 'lib/checkout/checkoutUtils'
import { getBookingSessionData, getCruiseItems } from 'checkout/selectors/cruiseSelectors'
import { DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE } from 'constants/tours'
import { checkoutAccommodationGroupingKey, getAccommodationItems } from '../view/accommodation'
import { getBundleAndSaveItems } from '../view/bundleAndSave'
import { getFlightItems } from '../view/flights'
import { PROVIDERS } from 'constants/experience'
import { getVillaItems } from '../view/villa'
import { isStandaloneLuxPlusSubscription } from '../view/luxPlusSubscription'
import { isBookingProtectionSelected } from '../view/bookingProtection'
import { getBookingProtectionOccupantsFromCartItems } from './bookingProtection'
import config from 'constants/config'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { getAvailableAccommodationBenefits } from 'luxPlus/selectors/benefits/accommodation'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { finaliseInsuranceItems } from 'checkout/lib/utils/travellerSchema/insuranceTravellerSchema'
import getObjectKey from 'lib/object/getObjectKey'
import { isSalesforceTourV2 } from 'lib/offer/offerUtils'

function transformOccupantsData(occupancy: App.Occupants, relatedItem?: App.Checkout.TravellerFormSchemaOccupant['relatedItem']) {
  const result: Array<App.Checkout.TravellerFormSchemaOccupant> = []
  for (let i = 0; i < occupancy.adults; i++) {
    result.push({
      type: 'adult',
      relatedItem,
    })
  }
  for (let i = 0; i < (occupancy.children || 0); i++) {
    const childOccupant: App.Checkout.TravellerFormSchemaOccupant = {
      type: 'child',
      relatedItem,
    }
    if (occupancy.childrenAge?.length) {
      childOccupant.age = occupancy.childrenAge[i]
    }
    if (occupancy.childrenBirthDate?.length) {
      childOccupant.birthDate = occupancy.childrenBirthDate[i]
    }
    result.push(childOccupant)
  }
  for (let i = 0; i < (occupancy.infants || 0); i++) {
    const infantOccupant: App.Checkout.TravellerFormSchemaOccupant = {
      type: 'infant',
      relatedItem,
    }

    if (occupancy.childrenBirthDate?.length) {
      infantOccupant.birthDate = occupancy.childrenBirthDate[i]
    }

    result.push(infantOccupant)
  }
  return result
}

const transformCruiseOccupantsData = (
  occupancy: App.Occupants,
  infantMaxAge?: number,
  childMinAge?: number,
) => {
  const result: Array<App.Checkout.TravellerFormSchemaOccupant> = []

  for (let i = 0; i < occupancy.adults; i++) {
    result.push({ type: 'adult' })
  }

  const minimumAge = getMinimumAgeForChild(childMinAge, infantMaxAge)
  sortBy(occupancy.childrenAge ?? [], (age) => age, 'desc')
    .forEach((age, index) => {
      const type = !!minimumAge && age <= minimumAge ? 'infant' : 'child'
      const birthDate = occupancy.childrenBirthDate?.[index]
      result.push({ type, age, birthDate })
    })

  return result
}

function getMinimumAgeForChild(childMinAge?: number, infantMaxAge?: number): number | undefined {
  // check if the infantMaxAge is greater than childMinAge, if yes, then the minimumAge should be 1.9
  return !!infantMaxAge && !!childMinAge && infantMaxAge >= childMinAge ? 1.9 : childMinAge
}

function passengersToTravellers(passengers: Array<App.Checkout.FlightPassenger>): Array<App.Checkout.SchemaOccupant> {
  return passengers.map(passenger => {
    return {
      type: passenger.type,
    }
  })
}

const getExperiencesSchemaRequestItems = createSelector(
  (state: App.State) => getExperienceItemsView(state),
  (experienceItemsView): Array<App.Checkout.ExperienceSchemaRequestItem> => {
    const items = experienceItemsView.data
      .filter(excludeNullOrUndefined)
      .filter(item => item.type !== 'addon' && !item.isSessionPurchaseLimitReached)
      .map<App.Checkout.ExperienceSchemaRequestItem | null>(item => {
        const availableTickets = item.ticketViews.filter(ticket => !ticket.unavailable)
        if (availableTickets.length === 0) {
          return null
        }
        const providerKey = item.experienceId.slice(0, 3)

        return {
          type: CHECKOUT_ITEM_TYPE_EXPERIENCE,
          offerId: item.experienceId,
          tickets: availableTickets.map(ticket => ({
            ticketId: ticket.id,
            name: ticket.name,
            fareType: ticket.fareType,
            count: ticket.count,
          })),
          provider: PROVIDERS[providerKey],
        }
      }).filter(excludeNullOrUndefined)
    return items
  },
)

const getCarHireSchemaRequestItems = createSelector(
  (state: App.State) => getCarHireItemsView(state),
  (carHireItemsView): Array<App.Checkout.CarHireSchemaRequestItem> => {
    const items = carHireItemsView.data
      .filter(excludeNullOrUndefined)
      .map<App.Checkout.CarHireSchemaRequestItem>(car => {
        return {
          type: CHECKOUT_ITEM_TYPE_CAR_HIRE,
          offerId: car.item.offerId,
          dobReference: car.item.pickUpDate,
        }
      })
    return items
  },
)

const getTransferSchemaRequestItems = createSelector(
  (state: App.State) => getTransferItemsView(state),
  (transferViews): Array<App.Checkout.ExperienceSchemaRequestItem> => {
    const items = transferViews.data
      .filter(excludeNullOrUndefined)
      .map<App.Checkout.ExperienceSchemaRequestItem | undefined>(itemView => {
        if (!itemView.item.transfer.option) {
          return undefined
        }

        const providerKey = itemView.item.experienceId.slice(0, 3)

        return {
          type: CHECKOUT_ITEM_TYPE_EXPERIENCE,
          offerId: itemView.item.experienceId,
          tickets: [{
            ticketId: itemView.item.transfer.option.id,
            name: itemView.item.transfer.option.name,
            fareType: itemView.item.transfer.option.name,
            count: 1,
          }],
          provider: PROVIDERS[providerKey],
        }
      })
      .filter(excludeNullOrUndefined)
    return items
  },
)

const getFlightSchemaRequestItems = createSelector(
  (state: App.State) => getFlightItems(state),
  (flightItems) => {
    return flightItems.flatMap<App.Checkout.FlightSchemaRequestItem>(item => item.flights.map(flight => ({
      type: CHECKOUT_ITEM_TYPE_FLIGHT,
      searchId: item.searchId,
      journeyId: flight.journeyId,
      selectedUpsellOptionId: flight.fareFamily?.id,
      occupants: passengersToTravellers(item.passengers),
    })))
  },
)

/**
 * LE Tour V1 items are handled differently to other accommodation types
 * because they do not have an 'occupancy' attribute.
 *
 * Each LE TourV1 cart item corresponds to 1 adult ticket
 * This selector builds an equivalent item (containing the 'occupancy' attribute)
 */
const getAccommodationTourV1RequestItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (accomItems): Array<App.Checkout.TourV1SchemaRequestItem> => {
    const tourItems = accomItems.filter(isTourV1Item)
    const itemMap = groupBy<string, App.Checkout.LETourV1Item>(tourItems, checkoutAccommodationGroupingKey)

    return Array.from(itemMap.values()).map<App.Checkout.TourV1SchemaRequestItem>(elementList => {
      const firstItem = elementList[0]

      return {
        offerId: firstItem.offerId,
        type: OFFER_TYPE_TOUR,
        occupants: elementList.map(item => ({
          type: item.occupancy.children ? 'child' : 'adult',
        })),
        dobReference: firstItem.startDate,
      }
    })
  },
)

const getCruiseRequestItems = createSelector(
  (state: App.State) => getCruiseItems(state),
  (state: App.State) => state.cruise.cruiseOffers,
  (state: App.State) => getBookingSessionData(state),
  (items, offers, sessionData): Array<App.Checkout.CruiseSchemaRequestItem> => {
    const { personTitles } = sessionData

    return nonNullable(items.map<App.Checkout.CruiseSchemaRequestItem | undefined>((item) => {
      const offer = offers[item.offerId]
      if (offer) {
        const schemaItem: App.Checkout.CruiseSchemaRequestItem = {
          type: OFFER_TYPE_CRUISE,
          offerId: item.offerId,
          itemId: item.itemId,
          occupants: transformCruiseOccupantsData(
            item.occupancy,
            offer.ship.occupancies?.infantMaximumAge,
            offer.ship.occupancies?.childMinimumAge,
          ),
          departureId: item.departureId,
          dobReference: item.startDate,
          cruiseLine: offer.cruiseLine.name,
          personTitles: personTitles?.map((title) => title.code) ?? [],
          adultMinAge: offer.ship.occupancies?.adultMinimumAge,
          childMaxAge: offer.ship.occupancies?.childMaximumAge ?? 17,
          childMinAge: offer.ship.occupancies?.childMinimumAge ?? 2,
          minimumSupervisorAge: offer.ship.occupancies?.minimumSupervisorAge ?? 18,
          infantMinAge: offer.ship.occupancies?.infantMinimumAge ?? 0,
        }
        return schemaItem
      }
    }))
  },
)

const getBundleAndSaveSchemaRequestItems = createSelector(
  (state: App.State) => getBundleAndSaveItems(state),
  (items): Array<App.Checkout.AccommodationSchemaRequestItem> => {
    return items.map<App.Checkout.AccommodationSchemaRequestItem>((item) => {
      return {
        type: 'hotel',
        reservationType: RESERVATION_TYPE_INSTANT_BOOKING,
        occupants: transformOccupantsData(item.occupancy),
        offerId: item.offerId,
      }
    })
  },
)

const getAccommodationSchemaRequestItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getAccommodationTourV1RequestItems(state),
  (state: App.State) => getCruiseRequestItems(state),
  (state: App.State) => state.offer.tourV2Offers,
  (
    accommodationItems,
    tourV1RequestItems,
    cruiseItems,
    tourOffers,
  ): Array<App.Checkout.AccommodationSchemaRequestItem | App.Checkout.TourV2SchemaRequestItem | App.Checkout.CruiseSchemaRequestItem | App.Checkout.TourV1SchemaRequestItem> => {
    const items = accommodationItems.map<App.Checkout.AccommodationSchemaRequestItem | App.Checkout.TourV2SchemaRequestItem | null>((item, itemIndex) => {
      const baseMetadata = { offerId: item.offerId }

      switch (item.itemType) {
        case CHECKOUT_ITEM_TYPE_TOUR_V2:
          const tourV2Offer = tourOffers[getTourV2IdForCheckoutItem(item)]
          const variation = tourV2Offer?.variations[item.purchasableOption.fkVariationId]
          const childMaxAge = (() => {
            if (isSalesforceTourV2(tourV2Offer)) {
              return variation?.minChildPriceAge &&
                variation?.minChildPriceAge > 0 ?
                variation?.minChildPriceAge - 1 :
                  -1
            }
            if (item.privateRequestKey) {
              return DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE
            }
            return variation?.maxChildPriceAge
          })()
          return {
            offerId: item.offerId,
            type: OFFER_TYPE_TOUR_V2,
            occupants: transformOccupantsData(item.occupancy, {
              index: itemIndex,
              unit: 'room',
            }),
            departureId: item.purchasableOption.fkDepartureId,
            dobReference: item.startDate,
            childMinAge: item.privateRequestKey ? 0 : (variation?.minChildPriceAge ?? 0),
            childMaxAge: childMaxAge ?? DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE,
            offerType: tourV2Offer?.productType,
          } as App.Checkout.TourV2SchemaRequestItem
        case CHECKOUT_ITEM_TYPE_BEDBANK:
          return {
            ...baseMetadata,
            type: OFFER_TYPE_HOTEL,
            occupants: transformOccupantsData(item.occupancy),
          } as App.Checkout.AccommodationSchemaRequestItem
        case CHECKOUT_ITEM_TYPE_LE_HOTEL:
          return {
            ...baseMetadata,
            type: OFFER_TYPE_HOTEL,
            reservationType: item.reservationType,
            occupants: item.reservationType == RESERVATION_TYPE_INSTANT_BOOKING ? transformOccupantsData(item.occupancy) : undefined,
          } as App.Checkout.AccommodationSchemaRequestItem
        default:
          return null
      }
    }).filter(excludeNullOrUndefined)

    return [
      ...items,
      ...tourV1RequestItems,
      ...cruiseItems,
    ]
  },
)

const getVillasSchemaRequestItems = createSelector(
  (state: App.State) => getVillaItems(state),
  (items) => {
    return items.map<App.Checkout.AccommodationSchemaRequestItem>(item => ({
      offerId: item.offerId,
      type: OFFER_TYPE_HOTEL,
      reservationType: item.reservationType,
      occupants: transformOccupantsData(item.occupancy),
    }))
  },
)

const getInsuranceSchemaRequestItems = createSelector(
  (state: App.State) => getInsuranceItems(state),
  (state: App.State) => getCheckoutInsuranceTravellers(state),
  (insuranceItems, travellers): Array<App.Checkout.InsuranceTravellerFormSchema> => {
    const item = insuranceItems[0]
    if (!item || !travellers || !item.productId) {
      return []
    }

    return [{
      productId: item.productId,
      quoteId: 'deprecated',
      type: CHECKOUT_ITEM_TYPE_INSURANCE,
      travellers: transformOccupantsData(travellers),
    }]
  },
)

const getBookingProtectionSchemaRequestItems = createSelector(
  (state: App.State) => isBookingProtectionSelected(state),
  (state: App.State) => getBookingProtectionOccupantsFromCartItems(state),
  (hasBookingSelected, occupants): Array<App.Checkout.BookingProtectionSchemaRequestItem> => {
    if (!hasBookingSelected) return []

    if (occupants) {
      return [{
        type: CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
        travellers: transformOccupantsData(occupants),
      }]
    } else {
      return []
    }
  },
)

export const isTravellerFormNeeded = createSelector(
  (state: App.State) => isStandaloneLuxPlusSubscription(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (
    isStandaloneLuxPlusSubscription,
    postPurchase,
  ) => {
    return !isStandaloneLuxPlusSubscription && (!postPurchase || postPurchase === 'insurance' || postPurchase === 'select-date')
  },
)

export const getIsLuxPlusMemberRedeemingBenefitSchemaRequest = createSelector(
  (state: App.State) => checkCanRedeemLuxPlusBenefits(state),
  (state: App.State) => getAvailableAccommodationBenefits(state),
  (state: App.State) => state.checkout.cart.isGift,
  (canRedeemLuxPlusBenefits, accomBenefits, isGift): boolean => {
    const hasBenefitsWithEditablePrimaryTravellerDetails = accomBenefits.isBundledWithFlights || accomBenefits.isTourWithMemberPrice
    const hasBenefitsWithLockedPrimaryTravellerDetails = (accomBenefits.isMemberOnly ||
      accomBenefits.hasEarlyAccess ||
      accomBenefits.hasLuxPlusInclusions ||
      (accomBenefits.totalMemberPriceSavings ?? 0) > 0)

    return canRedeemLuxPlusBenefits &&
    !isGift &&
    !hasBenefitsWithEditablePrimaryTravellerDetails &&
    hasBenefitsWithLockedPrimaryTravellerDetails
  })

export const getTravellerFormSchemaRequest = createSelector(
  (state: App.State) => getCarHireSchemaRequestItems(state),
  (state: App.State) => getAccommodationSchemaRequestItems(state),
  (state: App.State) => getBundleAndSaveSchemaRequestItems(state),
  (state: App.State) => getExperiencesSchemaRequestItems(state),
  (state: App.State) => getTransferSchemaRequestItems(state),
  (state: App.State) => getFlightSchemaRequestItems(state),
  (state: App.State) => getInsuranceSchemaRequestItems(state),
  (state: App.State) => getBookingProtectionSchemaRequestItems(state),
  (state: App.State) => getVillasSchemaRequestItems(state),
  (state: App.State) => selectSelectedTravellerEmployees(state),
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.auth.account.memberId,
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => getIsLuxPlusMemberRedeemingBenefitSchemaRequest(state),
  (
    carHireItems,
    accommodationItems,
    bundleAndSaveItems,
    experienceItems,
    transferItems,
    flightItems,
    insuranceItems,
    bookingProtectionItems,
    villaItems,
    selectedTravellerEmployees,
    region,
    customer_id,
    isGift,
    currencyCode,
    isLuxPlusMemberRedeemingBenefitSchemaRequest,
  ) => {
    // Use customerId of employee being booked on behalf of on LEBT
    const hasBusinessEmployeesSelected = config.businessTraveller.currentAccountMode === 'business' && selectedTravellerEmployees.length > 0
    return {
      items: [
        ...carHireItems,
        ...accommodationItems,
        ...bundleAndSaveItems,
        ...experienceItems,
        ...transferItems,
        ...flightItems,
        ...finaliseInsuranceItems(insuranceItems, flightItems),
        ...bookingProtectionItems,
        ...villaItems,
      ],
      region,
      customer_id: hasBusinessEmployeesSelected ? selectedTravellerEmployees[0].customerId : customer_id,
      isGift,
      currencyCode,
      isLuxPlusMember: isLuxPlusMemberRedeemingBenefitSchemaRequest,
    }
  },
)

export const getTravellerFormSchemaRequestKey = createSelector(
  (state: App.State) => getTravellerFormSchemaRequest(state),
  (request) => {
    return getObjectKey(request)
  },
)
