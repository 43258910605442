import { isDepositPercentageDefault } from 'checkout/lib/utils/accommodation/deposit'
import { getCruiseDeposits, getCruiseBalanceDueDate } from 'checkout/lib/utils/cruises/booking'
import getPayableTotal from 'checkout/selectors/payment/getPayableTotal'
import { createSelector } from 'reselect'
import { arrayToMap, min, nonNullable } from 'lib/array/arrayUtils'
import {
  findPostPurchaseCheckout,
  getTourV2IdForCheckoutItem,
  isAccommodationItem, isBedbankItem,
  isBNBLLEHotelItem,
  isCruiseItem,
  isInstantBookingHotelItem,
  isInstantBookingLEHotelItem,
  isLEHotelItem,
  isTourV1Item,
  isTourV2ExperienceItem,
  isTourV2Item,
} from 'lib/checkout/checkoutUtils'
import { dateDifference, subDays } from 'lib/datetime/dateUtils'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import {
  getAlwaysOnCancellationDays,
  getDepositMinimumValueForCurrencyCode,
  getTourV2DepositMinimumValueForCurrencyCode,
  isDepositsEnabled,
} from 'lib/payment/depositsUtils'
import { CHECKOUT_DUE_DATE_DAY_THRESHOLD } from 'constants/checkout'
import { TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD } from 'constants/tours'
import { isStandaloneFlights } from 'checkout/selectors/view/flights'
import { getBookingCabinPricingData, getOverallConsolidatedPaymentSchedule } from 'checkout/selectors/cruiseSelectors'
import config from 'constants/config'
import { getItemUniqueKey } from 'checkout/lib/utils/accommodation/cart'
import { cartIncludesFlights } from '../view/flights'

import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import { isSpoofed } from 'selectors/featuresSelectors'
import { floatify } from 'lib/maths/mathUtils'
import getDepositServiceFee from './getDepositServiceFee'
import getDepositRemaining from './getDepositRemaining'
import getDepositAmountBeforeCreditApplied from './getDepositAmountBeforeCreditApplied'
import { isTourV2Offer } from 'lib/offer/offerTypes'
import switchFunc from 'lib/function/switchFunc'
import getTourV2ItemViews from 'checkout/selectors/view/getTourV2ItemViews'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'

/**
 * @remarks
 * We need this selector for the rebooking flow and cannot use getDepositAmountBeforeCreditApplied
 * because it is needed to calculate service fee. This would create a circular dependency if we
 * added service fee to it.
 */
export const getDepositAmountBeforeCreditAppliedIncludingSvcFee = createSelector(
  (state: App.State) => getDepositAmountBeforeCreditApplied(state),
  (state: App.State) => getDepositServiceFee(state),
  (depositAmount, serviceFee): number => floatify(depositAmount + serviceFee),
)

/**
 * @remarks
 * We need this selector for a few functions within this file but cannot use getAccommodationItems from /checkout/selectors/view/accommodation.ts
 * because this would create a circular import dependency. This is because accommodation.ts requires getDepositAmountPercentage from this file.
 * We have the same problem in hotels.ts, so be sure to update all 3 functions. Looking to implement a better, long term solution soon.
 */
const getAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),

)

const getItemsDepositInfo = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => getBookingCabinPricingData(state),
  (state: App.State) => state.offer.offerAvailableRates,
  (state: App.State) => state.offer.tourV2Offers,
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => isSpoofed(state),
  (accommodationItems, offers, cabinPricingData, offersAvailableRates, tourV2Offers, isGift, isSpoofed): Array<App.Checkout.ItemDepositInfo> => {
    return accommodationItems
      .map((item) => {
        // Gift items should never be Deposit orders
        if (isGift) {
          return depositDisabled(item)
        }

        if (isTourV1Item(item)) {
          const offer = offers[item.offerId]
          return getTourDepositInfo(item, offer)
        }
        if (isTourV2Item(item)) {
          const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)] ?? offers[getTourV2IdForCheckoutItem(item)]
          return isTourV2Offer(offer) ? getTourV2DepositInfo(item, offer) : depositDisabled(item)
        }

        if (isCruiseItem(item) && cabinPricingData.hasCabinPricing) {
          const paymentSchedule = getCruiseDeposits(cabinPricingData)
          if (paymentSchedule?.schedule.finalPayment) return getCruiseDepositInfo(item)
        }

        if (isBedbankItem(item)) {
          if (item?.bundledItemIds?.length) {
            return { itemId: item.itemId }
          }
          return depositDisabled(item)
        }

        if (!isLEHotelItem(item)) {
          return depositDisabled(item)
        }

        // Disable deposits if specified at an offer level
        if (offers[item.offerId]?.disableDeposit) {
          return depositDisabled(item)
        }

        if (isInstantBookingLEHotelItem(item)) {
          const offer = offers[item.offerId]
          const availableRatesKey = buildAvailableRateKey(item.checkIn, item.checkOut, [item.occupancy])
          // all available room rates, using only if flag useDynamicCancellationPolicies is true
          const offerAvailableRates = offersAvailableRates?.[item.offerId]?.[availableRatesKey]
          return offer ? getLeHotelDepositInfo(item, offer, offerAvailableRates) : depositDisabled(item)
        }

        if (isBNBLLEHotelItem(item) && !config.BNBL_DEPOSITS_ENABLED && !isSpoofed) {
          return depositDisabled(item)
        }

        // multi-room should be disabled for BNBL for deposits
        if (accommodationItems.filter(item => isBNBLLEHotelItem(item)).length > 1) {
          return depositDisabled(item)
        }

        // BNBL items, have no dates
        return {
          itemId: item.itemId,
        }
      })
  },
)

function getTourDepositInfo(
  item: App.Checkout.TourItem,
  offer?: App.Offer | App.Tours.TourV2Offer,
): App.Checkout.ItemDepositInfo {
  const offerDueDate = offer?.depositThresholds?.numberOfDays || TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD
  return {
    itemId: item.itemId,
    dueDaysLeft: offerDueDate,
    depositDaysLeft: offerDueDate,
  }
}

function getTourV2DepositInfo(
  item: App.Checkout.TourItem,
  offer: App.Tours.TourV2Offer,
): App.Checkout.ItemDepositInfo {
  return offer.depositType === 'noDeposit' ? depositDisabled(item) : getTourDepositInfo(item, offer)
}

function getCruiseDepositInfo(
  item: App.Checkout.CruiseItem,
): App.Checkout.ItemDepositInfo {
  return {
    itemId: item.itemId,
    dueDaysLeft: 18,
    depositDaysLeft: 18,
  }
}

function getLeHotelDepositInfo(
  item: App.Checkout.InstantBookingLEHotelItem | App.Checkout.BedbankHotelItem,
  offer: App.Offer,
  offerAvailableRates?: App.OfferAvailableRates,
): App.Checkout.ItemDepositInfo {
  if (offer.depositThresholds?.numberOfDays) {
    return {
      itemId: item.itemId,
      dueDaysLeft: offer.depositThresholds.numberOfDays,
      depositDaysLeft: offer.depositThresholds.numberOfDays,
    }
  }

  if (offer.type == OFFER_TYPE_ALWAYS_ON) {
    const pkg = offer.packages.find(pkg => pkg.uniqueKey == getItemUniqueKey(item))
    const useDynamicCancellationPolicies = offer.property?.useDynamicCancellationPolicies ?? false
    const cancellationPolicies = pkg && useDynamicCancellationPolicies ? offerAvailableRates?.rates?.find(rate => rate.packageUniqueKey === pkg.uniqueKey)?.cancellationPolicies : undefined
    const cancellationPolicyDays = pkg ? getAlwaysOnCancellationDays(item.checkIn, pkg, cancellationPolicies) : null
    return cancellationPolicyDays ? {
      itemId: item.itemId,
      dueDaysLeft: cancellationPolicyDays + 15,
      depositDaysLeft: cancellationPolicyDays + 16,
    } : depositDisabled(item)
  }

  return {
    itemId: item.itemId,
    dueDaysLeft: CHECKOUT_DUE_DATE_DAY_THRESHOLD,
    depositDaysLeft: CHECKOUT_DUE_DATE_DAY_THRESHOLD,
  }
}

function depositDisabled(item: App.Checkout.AnyItem): App.Checkout.ItemDepositInfo {
  return {
    itemId: item.itemId,
    depositDisabled: true,
  }
}

export const getDepositDueDaysLeft = createSelector(
  (state: App.State) => getItemsDepositInfo(state),
  (itemDepositInfo): number => {
    return Math.max(...(nonNullable(Object.values(itemDepositInfo).map(deposit => deposit.dueDaysLeft))))
  },
)

export const getDepositDueDate = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getItemsDepositInfo(state),
  (state: App.State) => getOverallConsolidatedPaymentSchedule(state),
  (state: App.State) => isSpoofed(state),
  (accommodationItems, itemsDepositInfo, consolidatedPaymentSchedule, isSpoofed): Date | undefined => {
    /**
     * The deposit/balance calculations (dates and amounts) are performed in svc-cruise,
     * however there is no reason for FE to perform new calculations. For cruises,
     * we only need to render what the API gives us. If the deposit values
     * returned by the API do not exist, we must disable the deposit
     *
     * NOTE: the selector name is misleading, as it is not deposit due date, but final payment due date
    */
    if (accommodationItems.every(item => isCruiseItem(item))) {
      return getCruiseBalanceDueDate(consolidatedPaymentSchedule)
    }

    // BNBLs have no deposit due date as dates are not selected yet
    if ((config.BNBL_DEPOSITS_ENABLED || isSpoofed) && accommodationItems.some(item => isBNBLLEHotelItem(item))) {
      // beginning of epoch as placeholder and will be used to signal BNBL deposit order
      return new Date(0)
    }

    const depositInfoMapping = arrayToMap(itemsDepositInfo, (item) => item.itemId)
    const dueDates = nonNullable(accommodationItems
      .filter(isInstantBookingHotelItem)
      .map(item => {
        const depositDueDaysLeft = depositInfoMapping.get(item.itemId)?.dueDaysLeft
        const startDate = getStartDate(item)
        return (depositDueDaysLeft && startDate) ? subDays(startDate, depositDueDaysLeft) : undefined
      }))
    return min(dueDates)
  },
)

export const isBeforeDepositStartDate = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => getItemsDepositInfo(state),
  (accommodationItems, itemsDepositInfo): boolean => {
    const depositInfoMapping = arrayToMap(itemsDepositInfo, (item) => item.itemId)
    const eligibleItems = accommodationItems.filter(isInstantBookingHotelItem)

    return eligibleItems.length > 0 &&
      eligibleItems.every(item => {
        const depositDaysLeft = depositInfoMapping.get(item.itemId)?.depositDaysLeft
        const startDate = getStartDate(item)
        if (!startDate || !depositDaysLeft) return false

        return dateDifference(startDate).days > depositDaysLeft
      })
  },
)

export function getStartDate(item: App.Checkout.InstantBookingItem): Date | undefined {
  const startDate = (isTourV1Item(item) || isTourV2Item(item) || isCruiseItem(item)) ? item.startDate : item.checkIn
  if (startDate) {
    return new Date(startDate)
  }
  return undefined
}

const isDepositEnabled = createSelector(
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.system.depositConfigs,
  (currency, depositConfigs): boolean => isDepositsEnabled(currency) && !!depositConfigs,
)

const getMinDepositValue = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.geo.currentCurrency,
  (items, currency): number => {
    return items.some(isTourV2Item) ?
      getTourV2DepositMinimumValueForCurrencyCode(currency) :
      getDepositMinimumValueForCurrencyCode(currency)
  },
)

const isPriceOverMinDepositValue = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getMinDepositValue(state),
  (payableTotal, minDeposit): boolean => payableTotal >= minDeposit,
)

export const isDepositAllowedByOrderValue = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.system.depositConfigs,
  (payableTotal, isSpoofed, currencyCode, depositConfig): boolean => {
    if (isSpoofed) {
      return true
    }

    if (config.DEPOSITS_LOW_ORDER_VALUE_ENABLED) {
      return true
    }

    if (!depositConfig) {
      return false
    }

    // These three brands share the same inventory and have higher than average order values so are excluded
    // for low deposit threshold which is for white labels which have lower than average order values.
    if (!['luxuryescapes', 'leagenthub', 'lebusinesstraveller'].includes(config.BRAND) &&
      payableTotal >= switchFunc<number, string>(depositConfig.WHITELABELS_DEFAULT_DEPOSIT_MINIMUMS, Infinity)(currencyCode)
    ) {
      return true
    }

    if (payableTotal >= switchFunc<number, string>(depositConfig.LUXURYESCAPES_DEFAULT_DEPOSIT_MINIMUMS, Infinity)(currencyCode)) {
      return true
    }

    return false // Disallow for order values and currencies for everything else
  },
)

export const shouldTriggerForHotelDepositsHighOrderValueExperimentAu = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.checkout.cart.currencyCode,
  (payableTotal, iSpoofed, items, currencyCode): boolean => {
    if (iSpoofed) {
      return false
    }

    return payableTotal >= 1999 && payableTotal <= 4999 && items.some(isLEHotelItem) && currencyCode === 'AUD'
  },
)

export const shouldTriggerForHotelDepositsHighOrderValueExperimentNonAu = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.checkout.cart.currencyCode,
  (payableTotal, iSpoofed, items, currencyCode): boolean => {
    if (iSpoofed) {
      return false
    }

    return payableTotal >= 1999 && items.some(isLEHotelItem) && currencyCode !== 'AUD'
  },
)

const isAnyItemDepositDisabled = createSelector(
  (state: App.State) => getItemsDepositInfo(state),
  (itemsDepositInfo): boolean => itemsDepositInfo.some(item => item.depositDisabled),
)

export const isTourV2Bundled = createSelector(
  (state: App.State) => getTourV2ItemViews(state),
  (offerViewsWithStatus): boolean => {
    return offerViewsWithStatus.data.some(offerView => offerView.isBundled)
  },
)

const isDepositEnabledForFlights = createSelector(
  (state: App.State) => isStandaloneFlights(state),
  (isStandaloneFlights): boolean => {
    return isStandaloneFlights ? false : config.FLIGHTS_DEPOSITS_ENABLED
  },
)

export const isDepositAvailable = createSelector(
  (state: App.State) => isDepositEnabled(state),
  (state: App.State) => getDepositRemaining(state),
  (state: App.State) => cartIncludesFlights(state),
  (state: App.State) => isDepositEnabledForFlights(state),
  (state: App.State) => isAnyItemDepositDisabled(state),
  (state: App.State) => isPriceOverMinDepositValue(state),
  (state: App.State) => isBeforeDepositStartDate(state),
  (state: App.State) => isDepositAllowedByOrderValue(state),
  (state: App.State) => isSpoofed(state),
  (state: App.State) => state.paymentSchedule.eligible,
  (state: App.State) => state.checkout.cart.isMultiItemMode,
  (state: App.State) => !!state.checkout.payment.rebookingID,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.items.some(isLEHotelItem),
  (state: App.State) => state.checkout.cart.items.filter((item) => !isTourV2ExperienceItem(item)).length,
  (
    isDepositEnabled,
    depositRemaining,
    cartIncludesFlights,
    isDepositEnabledForFlights,
    anyItemDepositDisabled,
    isPriceOverMinDepositValue,
    isBeforeDepositStartDate,
    isDepositAllowedByOrderValue,
    isSpoofed,
    isPaymentSchedulesEligible,
    isMultiItemMode,
    rebookingID,
    postPurchase,
    hasLeHotel,
    cartItemsWithoutOptionalExperiences,
  ): boolean => {
    return (
      isDepositEnabled &&
      depositRemaining > 0 &&
      (cartIncludesFlights ? isDepositEnabledForFlights : true) &&
      !anyItemDepositDisabled &&
      isPriceOverMinDepositValue &&
      isBeforeDepositStartDate &&
      !postPurchase &&
      (!isMultiItemMode || cartItemsWithoutOptionalExperiences <= 1) &&
      !isPaymentSchedulesEligible &&
      (!hasLeHotel || isDepositAllowedByOrderValue)
    ) || (isSpoofed && rebookingID && !isMultiItemMode)
  },
)

export const isOfferUsingDefaultDepositPercentage = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.tourV2Offers,
  (accommodationItems, offers, tourV2Offers): boolean => {
    const item = accommodationItems[0]

    if (item) {
      if (isLEHotelItem(item)) {
        if (isInstantBookingLEHotelItem(item)) {
          const offer = offers[item.offerId]
          return isDepositPercentageDefault(offer)
        }
      }

      if (isTourV1Item(item)) {
        const offer = offers[item.offerId]
        return isDepositPercentageDefault(offer)
      }

      if (isTourV2Item(item)) {
        const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
        return offer?.depositType === 'default'
      }
    }

    return false
  },
)

export const getOfferViewWithStatusForDeposit = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (viewsWithStatus): App.WithDataStatus<App.Checkout.AccommodationOfferView> => {
    const tourV2BundleOfferView = viewsWithStatus.data.find(item => item.offerType === OFFER_TYPE_TOUR_V2 && item.isBundled)
    return {
      hasRequiredData: viewsWithStatus.hasRequiredData,
      data: tourV2BundleOfferView ?? viewsWithStatus.data[0],
    }
  },
)
