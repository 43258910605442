import { isAccommodationItem, isBedbankItem, isExperienceItem, isFlightItem, isInstantBookingLEHotelItem, isLEHotelItem, isLuxPlusSubscriptionItem, isTransferItem } from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { getAllOffers } from './offerSelectors'
import { isNonNullable, last, nonNullable, sortBy } from 'lib/array/arrayUtils'
import { CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE } from 'constants/checkout'
import { isMultiCartExperienceSupported, isUserCartSupportedItem } from 'lib/checkout/userCart'
import getObjectKey from 'lib/object/getObjectKey'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { userCartDatesOverlapping } from '../components/Pages/UserCartPage/UserCartSummary/UserCartDatesOverlappingUtils'
import { formatDateDMYCasual } from 'lib/datetime/dateUtils'
import { isBedbankOffer, isExperienceOffer, isLEHotel } from 'lib/offer/offerTypes'

export const getUserCartItemsArray = createSelector(
  (state: App.State) => state.auth.userCart.items,
  (items) => Object.values(items),
)

export const getNonDeletedUserCartItemsArray = createSelector(
  (state: App.State) => state.auth.userCart.itemStates || {},
  (state: App.State) => getUserCartItemsArray(state),
  (states, items) => Object.values(items).filter(item => !states[item.itemId]?.deleted),
)

export const getActiveUserCartItems = createSelector(
  (state: App.State) => state.auth.userCart.itemStates || {},
  (state: App.State) => getUserCartItemsArray(state),
  (states, items) => {
    return items.filter(item => (
      !states[item.itemId]?.deleted &&
      !states[item.itemId]?.saveForLater &&
      item.itemType !== CHECKOUT_ITEM_TYPE_LUXURY_PLUS_JOINING_FEE
    ))
  },
)

export const getNumberOfActiveUserCartItems = createSelector(
  (state: App.State) => getActiveUserCartItems(state),
  (activeItems) => activeItems.length,
)

export const getUserCartLuxPlusSubscriptionItem = createSelector(
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (items): App.Checkout.LuxPlusSubscriptionItem | undefined => items.find(isLuxPlusSubscriptionItem),
)

export const selectIsLuxPlusSubscriptionUserCartItemActive = createSelector(
  (state: App.State) => getUserCartLuxPlusSubscriptionItem(state),
  (state: App.State) => state.auth.userCart.itemStates || {},
  (item, states) => item && !states[item.itemId].deleted && !states[item.itemId].saveForLater,
)

export const selectIsLuxPlusInUserCart = createSelector(
  (state: App.State) => getUserCartLuxPlusSubscriptionItem(state),
  (state: App.State) => state.auth.userCart.itemStates || {},
  (subscriptionItem, states) => {
    const subscriptionStateActive = subscriptionItem && !states[subscriptionItem.itemId].deleted && !states[subscriptionItem.itemId].saveForLater
    return !!subscriptionStateActive
  },
)

export const getHasLuxPlusOrSubscriptionInMultiCart = createSelector(
  (state: App.State) => selectIsLuxPlusSubscriptionUserCartItemActive(state),
  (state: App.State) => checkCanRedeemLuxPlusBenefits(state),
  (isLuxPlusSubscriptionInMultiCartCart, canRedeemLuxPlusBenefits): boolean => !!(isLuxPlusSubscriptionInMultiCartCart || canRedeemLuxPlusBenefits),
)

export const getUserCartAccommodationItems = createSelector(
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),
)

export const getUserCartFlightItems = createSelector(
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (items) => items.filter(isFlightItem),
)

export const getUserCartFlightOrigin = createSelector(
  (state: App.State) => getUserCartFlightItems(state),
  (items) => items.filter(isFlightItem).filter(item => !!item.bundledItemIds?.length)[0]?.originAirportCode,
)

export const getUserCartExperienceItems = createSelector(
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (items): Array<App.Checkout.ExperienceItem> => items.filter(isExperienceItem),
)

export const getUserCartTransferItems = createSelector(
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (items): Array<App.Checkout.TransferItem> => items.filter(isTransferItem),
)

export const selectCartOffers = createSelector(
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (state: App.State) => getAllOffers(state),
  (cartItems, offers) => {
    return cartItems.reduce((acc: Record<string, App.AnyOffer>, item: App.Checkout.AnyItem) => {
      if ((isExperienceItem(item) || isTransferItem(item)) && offers?.[item?.experienceId]) {
        acc[item.experienceId] = offers[item.experienceId]
      } else if ((isLEHotelItem(item) || isBedbankItem(item)) && offers?.[item?.offerId]) {
        acc[item.offerId] = offers[item.offerId] as App.Offer
      }
      return acc
    }, {})
  },
)

interface SnackbarData {
  heading?: string
  description: string
}

type ItemTypeHandler = (items: Array<App.Checkout.AnyItem>, offers: Record<string, App.AnyOffer>, experiences: Record<string, App.ExperienceOffer>) => SnackbarData | undefined

function getFlightAirportMessage(items: Array<App.Checkout.FlightItem>) {
  const flights = sortBy(items.map(item => item.flights.map(flight => ({
    fareType: item.fareType,
    timestamp: new Date(flight.departingDate).getTime(),
    departingAirportName: flight.departingAirportName,
    arrivalAirportName: flight.arrivalAirportName,
  }))).flat(), item => item.timestamp, 'asc')
  const firstFlightAirport = flights[0].departingAirportName
  const lastFlight = last(flights)
  // Show destination airport for return and arrival for one-way or multi-city
  const isReturnFlight = flights.length === 2 && lastFlight.fareType !== 'multiCity'
  const lastFlightAirport = isReturnFlight ? lastFlight.departingAirportName : lastFlight.arrivalAirportName
  return `${firstFlightAirport} to ${lastFlightAirport}`
}

const flightSnackbarHandler: ItemTypeHandler = (items) => {
  if (!items.every(isFlightItem)) return

  const isSingleItem = items.length === 1
  const firstItem = items[0]

  const headingMap: Record<App.Checkout.FlightItem['fareType'], string> = {
    return: 'Return flights added to cart',
    oneWay: isSingleItem ? 'One-way flight added to cart' : 'Return flights added to cart',
    multiCity: 'Multi-city flights added to cart',
  }
  return {
    heading: headingMap[firstItem.fareType] || 'Flight(s) added to cart',
    description: getFlightAirportMessage(items),
  }
}

const experienceSnackbarHandler: ItemTypeHandler = (items, _, experiences) => {
  if (!items.every(isExperienceItem)) return
  return {
    heading: 'Experience added to cart',
    description: experiences[items[0].experienceId].name,
  }
}

const transferSnackbarHandler: ItemTypeHandler = (items) => {
  if (!items.every(isTransferItem)) return
  const typeLookup: Record<App.ExperienceTransferView['type'], string> = {
    'AIRPORT-TO-HOTEL': 'Airport to hotel',
    'HOTEL-TO-AIRPORT': 'Hotel to airport',
  }
  const isSingleItem = items.length === 1
  return {
    heading: 'Airport transfers added to cart',
    description: isSingleItem ? typeLookup[items[0].transfer.type] : 'Round trip',
  }
}

const hotelSnackbarHandler: ItemTypeHandler = (items, offers) => {
  if (!items.every(isInstantBookingLEHotelItem) && !items.every(isBedbankItem)) return
  const description = offers[items[0].offerId].name
  const heading = items.length > 1 ? `${items.length} rooms added to cart` : 'Hotel room added to cart'
  return { heading, description }
}

const flightHotelSnackbarHandler: ItemTypeHandler = (items, offers) => {
  if (!(items.some(isFlightItem) && (items.some(isInstantBookingLEHotelItem) || items.some(isBedbankItem)))) return
  const hotelItems = items.filter(item => isInstantBookingLEHotelItem(item) || isBedbankItem(item))
  const headingBase = hotelItems.length > 1 ? `${hotelItems.length} rooms` : 'Hotel'
  const description = `${offers[hotelItems[0].offerId].name ?? 'Hotel'} + Return flights`
  return {
    heading: `${headingBase} + flights added to cart`,
    description,
  }
}

const itemTypeHandlers: Array<ItemTypeHandler> = [
  flightSnackbarHandler,
  experienceSnackbarHandler,
  transferSnackbarHandler,
  hotelSnackbarHandler,
  flightHotelSnackbarHandler,
]

export const getAddToCartSnackbarData = createSelector(
  (state: App.State) => getAllOffers(state),
  (state: App.State) => state.experience.experiences,
  (_: App.State, addedItems: Array<App.Checkout.AnyItem>) => addedItems,
  (offers, experiences, addedItems): SnackbarData => {
    for (const handler of itemTypeHandlers) {
      const result = handler(addedItems, offers, experiences)
      if (result) return result
    }
    return { description: 'Item(s) added to cart' }
  },
)

export const getShouldWaiveLuxPlusJoiningFee = createSelector(
  (state: App.State) => getActiveUserCartItems(state),
  (state: App.State) => selectCartOffers(state),
  (items, cartOffers) => {
    const hotelItems = items.filter(item =>
      isLEHotelItem(item) || isBedbankItem(item),
    )

    for (const item of hotelItems) {
      const offer = cartOffers[item.offerId]
      if (offer && (offer.luxPlus.hasMemberPrices || offer.luxPlus.hasMemberInclusions || offer.luxPlus.access)) {
        return true
      }
    }

    return false
  },
)

function getAbandonItemExperienceKey(item: App.Checkout.ExperienceItem) {
  return item.experienceId
}

function getAbandonItemBedbankKey(item: App.Checkout.BedbankHotelItem) {
  const { offerId, boardCode, roomId, refundable, occupancy, checkIn, checkOut } = item
  return getObjectKey({
    offerId,
    boardCode,
    roomId,
    refundable,
    occupancy,
    checkIn,
    checkOut,
  })
}

function getAbandonItemHotelKey(item: App.Checkout.InstantBookingLEHotelItem) {
  const { offerId, packageId, occupancy, checkIn, checkOut } = item
  const stableOccupancy = {
    adults: occupancy.adults,
    children: occupancy.children,
    childrenAge: occupancy.childrenAge,
    infants: occupancy.infants,
  }
  return getObjectKey({
    offerId,
    packageId,
    stableOccupancy,
    checkIn,
    checkOut,
  })
}

function getAbandonItemFlightItem(item: App.Checkout.FlightItem) {
  const { occupants, travelStart, travelEnd, flights } = item
  return getObjectKey({
    occupants,
    travelStart,
    travelEnd,
    flights: flights.map(flight => ({ journeyKey: flight.journeyKey, fareFamily: flight.fareFamily?.fareFamily })),
  })
}

function getAbandonItemKey(item: App.Checkout.AnyItem): string | undefined {
  if (isExperienceItem(item)) return getAbandonItemExperienceKey(item)
  if (isBedbankItem(item)) return getAbandonItemBedbankKey(item)
  if (isInstantBookingLEHotelItem(item)) return getAbandonItemHotelKey(item)
  if (isFlightItem(item)) return getAbandonItemFlightItem(item)
}

export const getUserCartAbandonableItems = createSelector(
  (_: App.State, items: Array<App.Checkout.AnyItem>) => items,
  (state: App.State) => state.experience.experiences,
  (state: App.State) => getNonDeletedUserCartItemsArray(state),
  (items, experienceOffers, userCartItems) => {
    const baseSupportedItems = items.filter(isUserCartSupportedItem)
    if (!baseSupportedItems.length) return []

    const hasMultipleBedbanks = baseSupportedItems.filter(isBedbankItem).length > 1

    const dedupeKeys = new Set(nonNullable(userCartItems.map(getAbandonItemKey)))

    return baseSupportedItems.filter(item => {
      const itemDedupeKey = getAbandonItemKey(item)
      if (itemDedupeKey && dedupeKeys.has(itemDedupeKey)) return false
      if (isExperienceItem(item)) {
        return isMultiCartExperienceSupported(experienceOffers[item.experienceId])
      }
      return !isBedbankItem(item) || !hasMultipleBedbanks
    })
  },
)

export const getUserCartDates = createSelector(
  (state: App.State) => getActiveUserCartItems(state),
  (state: App.State) => selectCartOffers(state),
  (items, offers) => items
    .map(item => {
      if (!item?.itemId) return null

      const offerId = (isLEHotelItem(item) || isBedbankItem(item)) ? item.offerId :
          (isExperienceItem(item) || isTransferItem(item)) ? item.experienceId : undefined

      const offer = offerId ? offers[offerId] : undefined

      if (isInstantBookingLEHotelItem(item) && offer && isLEHotel(offer)) {
        const packageData = offer?.packages?.find(pkg => pkg.id === item.packageId)
        const packageImage = packageData?.roomType?.images?.[0]
        const hotelImage = packageImage || offer?.images?.[0]

        return {
          itemId: item.itemId,
          type: item.itemType,
          offerId: item.offerId,
          title: offer?.property?.name || undefined,
          description: packageData?.name || undefined,
          checkIn: item.checkIn,
          checkOut: item.checkOut,
          roomImage: hotelImage,
          occupancy: item.occupancy,
        }
      }

      else if (isBedbankItem(item) && isBedbankOffer(offer)) {
        const bedbankOffer = offer
        const roomData = bedbankOffer?.packages?.find(room => room.roomId === item.roomId)
        const bedbankImage = roomData?.images?.[0] || bedbankOffer?.images?.[0]

        return {
          itemId: item.itemId,
          type: item.itemType,
          offerId: item.offerId,
          title: bedbankOffer?.name || undefined,
          description: roomData?.name || undefined,
          checkIn: item.checkIn,
          checkOut: item.checkOut,
          roomImage: bedbankImage,
          occupancy: item.occupancy,
        }
      }

      else if (isExperienceItem(item) && offer && isExperienceOffer(offer)) {
        return {
          itemId: item.itemId,
          type: item.itemType,
          offerId: item.experienceId,
          title: offer?.name || undefined,
          date: ` ${formatDateDMYCasual(item.date)} - ${item.time}`,
          roomImage: offer?.images?.[0],
        }
      }

      else if (isTransferItem(item) && offer && isExperienceOffer(offer) && item.transfer.date) {
        return {
          itemId: item.itemId,
          type: item.itemType,
          offerId: item.experienceId,
          title: offer?.name || undefined,
          date: ` ${formatDateDMYCasual(item.transfer.date)}`,
          roomImage: offer?.images?.[0],
          maxPassengers: item.transfer.option?.maxPassengers,
          description: item.transfer.option?.name,
        }
      }

      else if (isFlightItem(item) && item.flights?.length) {
        const departingAirport = item.flights[0].departingAirportName
        const lastFlight = last(item.flights)
        const departingAiportCode = item.flights[0].departingAirportCode
        const arrivalAirport = (item.fareType === 'multiCity' || item.fareType === 'return') ?
          lastFlight.departingAirportName :
          lastFlight.arrivalAirportName
        const arrivalAirportCode = (item.fareType === 'multiCity' || item.fareType === 'return') ?
          lastFlight.departingAirportCode :
          lastFlight.arrivalAirportCode
        const fareTypeMap = {
          return: 'Return flight',
          oneWay: 'One-way flight',
          multiCity: 'Multi-city flight',
        }
        const fareType = fareTypeMap[item.fareType] || item.fareType
        return {
          itemId: item.itemId,
          title: `${departingAirport} (${departingAiportCode}) to ${arrivalAirport} (${arrivalAirportCode})`,
          type: item.itemType,
          checkIn: item.flights[0].departureDateTime,
          checkOut: last(item.flights).arrivalDateTime,
          fareType: fareType,
          occupancy: item.occupants,
        }
      }

      return null
    })
    .filter(isNonNullable),
)

export const userCartOverlappingDates = createSelector(
  getUserCartDates,
  (cartDates) => {
    return userCartDatesOverlapping(cartDates)
  },
)
