import Plural from 'components/utils/Formatters/Plural'
import config from 'constants/config'
import React from 'react'

interface Props {
  points: number
  abbreviated?: boolean
}

function FormatLuxLoyaltyPoints({ points, abbreviated }: Props) {
  if (abbreviated) {
    return <Plural
      singular={config.luxLoyalty.pointsUnitAbbreviation}
      delimiter=""
      count={points}
      withCount
    />
  }
  return <Plural
    singular={config.luxLoyalty.pointsUnit}
    count={points}
    withCount
  />
}

export default FormatLuxLoyaltyPoints
