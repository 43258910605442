import { DMY_CASUAL_FORMAT, SHORT_TIME_FORMAT_AM_PM } from 'constants/dateFormats'
import { sum } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'
import { makeDateTime } from 'tripPlanner/utils'

function getCarHireInsuranceItem(carHireInsurance: App.CarHireInsurance): App.Checkout.AnyItemBreakdownView {
  if (!carHireInsurance || carHireInsurance?.type === 'limited') {
    return {
      title: 'Insurance',
      itemType: 'car_hire',
      additionalInfoText: [],
      additionalElements: [{
        id: 'limited_protection',
        left: {
          type: 'text',
          value: 'Limited Protection',
        },
        right: {
          type: 'price',
          value: 0,
        },
      }],
    }
  }

  return {
    title: 'Insurance',
    itemType: 'car_hire',
    additionalInfoText: [],
    additionalElements: [{
      id: carHireInsurance.id,
      left: {
        type: 'text',
        value: 'Enhanced Protection',
      },
      right: {
        type: 'price',
        value: 0,
      },
    }],
  }
}

function getCarHireAddonsItems(selectedAddons: Record<string, App.CartCarHireAddon>, taxesAndFees?: number): App.Checkout.AnyItemBreakdownView {
  return {
    title: 'Add-ons',
    itemType: 'car_hire',
    additionalInfoText: [],
    taxesAndFees,
    additionalElements: Object.values(selectedAddons).map(addon => {
      return {
        id: addon.description || '',
        left: {
          type: 'text',
          value: addon.description || '',
          quantity: addon.quantity || 1,
        },
        right: {
          type: 'price',
          value: addon.total * (addon.quantity || 1),
        },
      }
    }),
  }
}

export function getSelectedCarHireAddonsTotal(selectedAddons: Array<Record<string, App.CartCarHireAddon>>) {
  return sum(selectedAddons, addon => {
    return sum(Object.values(addon), addon => addon.total * (addon.quantity || 1))
  })
}

export function getCarHireSelectedRateOption(
  item: App.Checkout.CarHireItem,
): App.CarHireOffer['rateOptions'][number] {
  return item.offer.rateOptions.find((option) =>
    option.id === item.selectedRateOption.id &&
    option.idContext === item.selectedRateOption.idContext,
  ) ?? item.offer.rateOptions[0]
}

export function getCarHireItemView(
  item: App.Checkout.CarHireItem,
): App.Checkout.CarHireItemView {
  const rateOption = getCarHireSelectedRateOption(item)
  const isPayOnArrival = !rateOption.payNowAmount && !!rateOption.payOnArrivalAmount

  return {
    luxLoyaltyProductType: getLuxLoyaltyProductType(item.offer),
    kind: 'car-hire',
    item,
    totals: {
      taxesAndFees: !isPayOnArrival ? rateOption.payOnArrivalAmount : 0,
      price: item.totalPrice,
      value: item.totalPrice,
      memberPrice: 0,
      memberValue: 0,
      surcharge: 0,
      extraGuestSurcharge: 0,
      carHirePayOnArrival: isPayOnArrival ? rateOption.payOnArrivalAmount : 0,
    },
    model: item.offer.vehicle.model,
    image: item.offer.vehicle.image,
    pickUpLocation: item.offer.pickUp,
    returnLocation: item.offer.dropOff,
    addons: item.offer.addons,
    vendor: {
      name: item.offer.vendor.name,
      image: {
        url: `https://ctimg-svg.cartrawler.com/supplier-images/${item.offer.vendor.name.toLowerCase().replaceAll(' ', '_')}.svg`,
        title: item.offer.vendor.name,
      },
    },
  }
}

function getCarHireCancellationPolicyItem(
  offerCancellationPolicy: App.CarHireCancellationPolicy,
):App.Checkout.AnyItemBreakdownView {
  return {
    cancellationPolicy: {
      maxHours: offerCancellationPolicy.maxHours,
      maxDate: offerCancellationPolicy.maxDate,
    },
    title: '',
    additionalInfoText: [],
    additionalElements: [],
    itemType: 'car_hire',
  }
}

export function getCarHireInsurancePriceBreakdown(
  carView: App.Checkout.CarHireItemView,
): App.Checkout.PriceBreakdownView {
  return {
    title: 'Insurance',
    price: carView.item.selectedInsurance?.total || 0,
    memberPrice: 0,
    additionalInfoText: [],
    items: [
      ...carView.item.selectedInsurance ? [getCarHireInsuranceItem(carView.item.selectedInsurance)] : [],
    ],
    itemsAlwaysVisible: true,
    alwaysShowItemPrice: true,
  }
}

export function getCarHireAddonPriceBreakdown(
  carView: App.Checkout.CarHireItemView,
): App.Checkout.PriceBreakdownView {
  const selectedCarHireAddonTotal = getSelectedCarHireAddonsTotal([carView.item.selectedAddons])
  return {
    title: 'Add-ons',
    price: selectedCarHireAddonTotal,
    memberPrice: 0,
    additionalInfoText: [],
    items: selectedCarHireAddonTotal ? [getCarHireAddonsItems(carView.item.selectedAddons, carView.totals.taxesAndFees)] : [],
  }
}

export function getCarHireItemPriceBreakdown(
  carView: App.Checkout.CarHireItemView,
): App.Checkout.PriceBreakdownView {
  const { offer } = carView.item
  const isSameLocation = offer.pickUp.name === offer.dropOff.name
  const selectedRateOption = getCarHireSelectedRateOption(carView.item)

  return {
    title: `${carView.model} ${carView.item.offer.guarenteedModel ? '' : 'or similar'}`,
    price: (selectedRateOption.payNowAmount ?? 0) + (carView.totals.carHirePayOnArrival ?? 0),
    memberPrice: 0,
    description: isSameLocation ? `Pick up and drop-off at ${offer.pickUp.name}` : `Pick up at ${offer.pickUp.name} and drop-off at ${offer.dropOff.name}`,
    additionalInfoText: [
          `${makeDateTime(carView.item.pickUpDate, carView.item.pickUpTime).format(`${DMY_CASUAL_FORMAT}, ${SHORT_TIME_FORMAT_AM_PM}`)} → ${makeDateTime(carView.item.dropOffDate, carView.item.dropOffTime).format(`${DMY_CASUAL_FORMAT}, ${SHORT_TIME_FORMAT_AM_PM}`)}`,
          `${pluralizeToString('day', offer.duration)}`,
    ],
    items: [getCarHireCancellationPolicyItem(offer.cancellationPolicy)],
    itemsAlwaysVisible: true,
    alwaysShowItemPrice: true,
  }
}
