import LuxLoyaltyTierIcon from 'components/Luxkit/Icons/luxLoyalty/LuxLoyaltyTierIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import React, { ComponentProps, PropsWithChildren } from 'react'

export interface BaseLuxLoyaltyPointsListItemProps {
  tier: App.LuxLoyaltyTier
  size: 'S' | 'M'
}

interface Props extends BaseLuxLoyaltyPointsListItemProps, PropsWithChildren {}

export const SIZES_BODY_TEXT_VARIANTS: Record<Props['size'], ComponentProps<typeof BodyText>['variant']> = {
  S: 'medium',
  M: 'large',
}

function LuxLoyaltyPointsListItem({ children, tier, size }: Props) {
  return <Group
    className={LuxLoyaltyPointsListItem.name}
    direction="horizontal"
    verticalAlign="center"
    gap={8}
  >
    <LuxLoyaltyTierIcon tier={tier} size="S" />
    <BodyText variant={SIZES_BODY_TEXT_VARIANTS[size]}>{children}</BodyText>
  </Group>
}

export default LuxLoyaltyPointsListItem
