import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import {
  getCategoryAndProductId,
  getCruiseTourV2OrExperienceOffer,
  getExperienceProductIdFromProvider,
  getExperienceProviderFromOffer,
} from 'analytics/snowplow/helpers/itemCategorisation'
import { isExperienceItem, isTransferItem } from 'lib/checkout/checkoutUtils'
import { getExperiencesTimesKeyFromCartItem } from 'lib/experiences/experienceUtils'
import { mapExperienceTicketToView } from 'checkout/lib/utils/experiences/view'
import { isNonNullable } from 'lib/array/arrayUtils'
import sanitizeItemContextMetadata from './sanitizeItemContextMetadata'
import { mapCityLevelPlace } from 'lib/search/mappingUtils'
import { getPlaceByLatLong } from 'api/search'

function mapExperienceTicketsPrice(state: App.State, item: App.Checkout.ExperienceItem) {
  const experienceTimeKey = getExperiencesTimesKeyFromCartItem(item, state.geo.currentCurrency)
  const timeSlots = state.experience.experienceTimes[item.experienceId]?.[experienceTimeKey]
  if (!timeSlots?.slots) return
  return timeSlots.slots
    .filter(slot => slot.time === item.time)
    .flatMap(slot => {
      return item.tickets.map(cartTicket => {
        const ticket = slot.tickets.find(ticket => (
          ticket.id === cartTicket.ticketId &&
        ticket.date === cartTicket.date
        ))
        const view = mapExperienceTicketToView(cartTicket, ticket)
        if (!view || view.price <= 0) return
        return {
          price: view.price,
          value: view.value,
        }
      })
    },
    ).filter(isNonNullable)
}

async function getExperienceCartItem(state: App.State, item: App.Checkout.TransferItem | App.Checkout.ExperienceItem, isMultiCart?: boolean) {
  const offerId = item.experienceId

  const offer = getCruiseTourV2OrExperienceOffer(state, offerId) as App.ExperienceOffer

  const { categoryId } = getCategoryAndProductId('experience', offer)
  const productId = getExperienceProductIdFromProvider(offer.provider)
  const finalProductId = productId || getExperienceProductIdFromProvider(getExperienceProviderFromOffer(offerId))
  const locations = mapCityLevelPlace((await getPlaceByLatLong(offer.location.latitude, offer.location.longitude)))
  const destinationCity = locations?.city
  const originCity = locations?.city
  const destinationCountry = locations?.country
  const originCountry = locations?.country

  const experienceDate = item.itemType === 'experience' ? item.date : item.transfer.date

  const baseProps = {
    offerId,
    categoryId,
    productId: finalProductId,
    duration: 1,
    travelStart: experienceDate,
    travelEnd: experienceDate,
    offerLeadPrice: offer?.price,
    offerLeadDuration: '1',
    offerLeadUnit: offer?.ticketUnitLabel,
    currency: state.geo.currentCurrency,
    destinationCity,
    destinationCountry,
    originCity,
    originCountry,
    itemId: item.itemId,
    metadata: sanitizeItemContextMetadata({
      isMultiCart,
    }),
  }

  if (isExperienceItem(item)) {
    const pricingPerTicket = mapExperienceTicketsPrice(state, item)
    if (pricingPerTicket) {
      return pricingPerTicket.map(ticketPricing => {
        return comluxgroup.createItem_1_1_0(
          {
            ...baseProps,
            ...ticketPricing,
          },
        )
      })
    }
  }
  if (isTransferItem(item)) {
    return [comluxgroup.createItem_1_1_0(
      {
        ...baseProps,
        price: item.transfer.option?.price || 0,
        value: item.transfer.option?.price || 0,
      },
    )]
  }
  return [comluxgroup.createItem_1_1_0(baseProps)]
}

export default getExperienceCartItem
