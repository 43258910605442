import React, { PropsWithChildren, useContext, MouseEventHandler, useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from '../Typography/BodyText'
import Heading from '../Typography/Heading'
import LineArrowLeftIcon from '../Icons/line/LineArrowLeftIcon'
import LineTimesIcon from '../Icons/line/LineTimesIcon'
import IconButton from '../Button/IconButton'
import { LuxkitModalBehaviourDispatchContext, LuxkitModalBehaviourStateContext } from './context/LuxkitModalBehaviourContexts'
import { stopPropagation } from 'lib/web/eventHandlerUtils'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import config from 'constants/config'
import { LuxkitModalBehaviourDispatchActions } from './context/LuxkitModalBehaviourState'

const wlMobileAppStyle = config.MOBILE_APP_CUSTOMISATIONS ? `padding-top: ${rem(50)};` : ''

export const StyledModalHeader = styled.header`
  align-items: start;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  display: grid;
  grid-template-areas:
    "prepended-action title appended-action"
    ". subtitle ."
    "extension extension extension";
  grid-template-columns: min-content 1fr min-content;
  z-index: 1;

  grid-template-rows:
    minmax(${rem(24)}, auto)
    auto
    auto;
  padding: ${rem(16)} ${rem(20)};
  transition: box-shadow .2s ease;
  ${wlMobileAppStyle}

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)} ${rem(24)} ${rem(16)};
  }

  &.has-shadow:not(.is-overlay)  {
    box-shadow: ${props => props.theme.shadow.bottom.small};
  }

  > .action-area {
    display: flex;
    align-items: center;
    max-height: ${rem(24)};
  }

  > .prepended-action-area {
    grid-area: prepended-action;
    padding: 0 ${rem(12)} 0 0;
  }

  > .appended-action-area {
    grid-area: appended-action;
    padding: 0 0 0 ${rem(12)};
  }

  > .title-area {
    grid-area: title;
  }

  > .subtitle-area {
    grid-area: subtitle;
    margin-top: ${rem(8)};
  }

  > .extension-area {
    grid-area: extension;
    margin-top: ${rem(24)};

    ${mediaQueryUp.tablet} {
      margin-top: ${rem(16)};
    }
  }

  > .action-area,
  > .subtitle-area,
  > .extension-area {
    &:empty {
      display: none;
    }
  }

  &.is-overlay {
    background-color: transparent;
    box-shadow: none;
    grid-template-areas:
      "prepended-action . appended-action"
      "extension extension extension";
    grid-template-rows: repeat(2, auto);
    height: 0;
    padding: 0;
    pointer-events: none;

    > .title-area,
    > .subtitle-area {
      display: none;
    }

    > .action-area {
      margin: ${rem(10)};
      padding: unset;
      pointer-events: visible;

      ${mediaQueryUp.tablet} {
        margin: ${rem(16)};
      }
    }

    > .extension-area {
     > * {
        pointer-events: visible;
      }
    }
  }
`

export const LUXKIT_MODAL_HEADER_TITLE_ID = 'LUXKIT-MODAL-HEADER-TITLE'
export const LUXKIT_MODAL_HEADER_SUBTITLE_ID = 'LUXKIT-MODAL-HEADER-SUBTITLE'

interface ModalHeaderProps extends PropsWithChildren {
  isOverlay?: boolean
  /**
   * Subtitle text in the header beneath the title.
   */
  subtitle?: string | React.ReactElement | false;
  /**
   * Title text in the header.
   *
   * This element is a react node so elements such as `<FormatCurrency />` can be utilised
   * Do not pass any elements that will return anything but text.
   */
  title: string | React.ReactElement;
  onBackButtonClick?: MouseEventHandler<HTMLButtonElement>
  onCloseButtonClick?: MouseEventHandler<HTMLButtonElement>
  'data-testid'?: string
  /**
   * Whether the close button should be displayed or not
   * @default true
   */
  dismissible?: boolean
}

function ModalHeader(props: ModalHeaderProps) {
  const {
    children,
    isOverlay,
    subtitle,
    title,
    dismissible = true,
    onBackButtonClick,
    onCloseButtonClick,
    'data-testid': testId,
  } = props

  const modalBehaviourState = useContext(LuxkitModalBehaviourStateContext)
  const modalBehaviourDispatch = useContext(LuxkitModalBehaviourDispatchContext)
  const elementContext = useModalElementContext()

  useEffect(() => {
    modalBehaviourDispatch({
      type: LuxkitModalBehaviourDispatchActions.TOGGLE_HEADER,
      hasHeader: true,
    })

    return () => {
      modalBehaviourDispatch({
        type: LuxkitModalBehaviourDispatchActions.TOGGLE_HEADER,
        hasHeader: false,
      })
    }
  }, [modalBehaviourDispatch])

  const onClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onCloseButtonClick) {
      onCloseButtonClick(e)
    } else {
      elementContext?.resolve()
    }
  }

  return <StyledModalHeader
    className={cn({
      'has-shadow': !(modalBehaviourState?.bodyHasReachedStart ?? true),
      'is-overlay': isOverlay,
    })}
    onClick={stopPropagation}
  >
    {onBackButtonClick && <div className="action-area prepended-action-area">
      <IconButton
        kind="tertiary"
        variant="dark"
        onClick={onBackButtonClick}
        horizontalOutdent="start"
        data-testid={`${testId}-back-button`}
      >
        <LineArrowLeftIcon />
      </IconButton>
    </div>}
    <Heading
      title={typeof title === 'string' ? title : undefined}
      lineClamp={2}
      variant="heading3"
      as="h1"
      className="title-area"
      id="LUXKIT-MODAL-HEADER-TITLE"
    >
      {title}
    </Heading>
    {!!subtitle && <div className="subtitle-area">
      <BodyText
        variant="medium"
        colour="neutral-two"
        as="p"
        id="LUXKIT-MODAL-HEADER-SUBTITLE"
      >
        {subtitle}
      </BodyText>
    </div>}
    {(!!children) && <div className="extension-area">{children}</div>}
    {dismissible && (onCloseButtonClick || elementContext) && <div className="action-area appended-action-area">
      <IconButton
        kind="tertiary"
        variant="dark"
        horizontalOutdent="end"
        onClick={onClose}
        data-testid={`${testId}-close-button`}
        aria-label="close"
      >
        <LineTimesIcon />
      </IconButton>
    </div>}
  </StyledModalHeader>
}

export default ModalHeader
