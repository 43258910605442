import isDomesticTrip from 'checkout/selectors/view/isDomesticTrip'
import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'

const getCanRedeemTransferLoyaltyBenefit = createSelector(
  (state: App.State) => state.luxLoyalty.account.data?.benefits,
  (state: App.State) => !isDomesticTrip(state),
  (state: App.State) => state.luxLoyalty.config?.transferAirports,
  (userBenefits, isInternationalTrip, airportCodes = EmptyArray): boolean => {
    const transferBenefit = userBenefits?.find(benefit => benefit.code === 'airport_transfer')

    // Can redeem airport transfers when they have some remaning, it's an international order
    // and our config says we have transfer airports available
    return airportCodes.length > 0 &&
    !!transferBenefit &&
    (transferBenefit.unlimited || !!transferBenefit.remaining) &&
    isInternationalTrip
  },
)

export default getCanRedeemTransferLoyaltyBenefit
