import {
  enrolLuxLoyaltyAccountRequest,
  fetchLuxLoyaltyAccountEligibilityRequest,
  fetchLuxLoyaltyAccountRequest,
  getLuxLoyaltyConfig,
} from 'api/luxLoyalty'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import config from 'constants/config'
import { appViewMessageHandler } from 'lib/window/webViewUtils'
import getLuxLoyaltyProgramDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyProgramDisplayName'
import { getHasLuxLoyaltyAccount, getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { API_CALL, CLEAR_LUX_LOYALTY_ENROLMENT } from './actionConstants'
import { AppAction } from './ActionTypes'
import {
  ENROL_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT,
  FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
  FETCH_LUX_LOYALTY_CONFIG,
} from './apiActionConstants'

interface EnrolLuxLoyaltyAccount {
  accountMemberId?: string;
  token?: string;
  enableSnackbar?: boolean;
  enrolment: App.LuxLoyaltyEnrolmentType;
}

export function enrolLuxLoyaltyAccount({ accountMemberId, token, enrolment }: EnrolLuxLoyaltyAccount): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = accountMemberId ?? state.auth.account.memberId
    const givenName = state.auth.account.givenName
    const headlessMode = state.system.headlessMode
    const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
    const luxLoyaltyAccount = state.luxLoyalty.account

    let enrolled = false

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      !memberId ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyAccount.fetching ||
      luxLoyaltyAccount.error
    ) return

    dispatch({
      type: API_CALL,
      api: ENROL_LUX_LOYALTY_ACCOUNT,
      enrolment,
      request: () => enrolLuxLoyaltyAccountRequest({
        customerId: memberId,
      }, token)
        .then((result) => {
          enrolled = true

          if (enrolment === 'newUserRegistration') {
            showSnackbar(
              `${givenName ? `Welcome ${givenName}` : 'Welcome'}, to ${config.title} and your new ${getLuxLoyaltyProgramDisplayName()}. Start earning today.`,
              'success',
            )
          }

          if (enrolment === 'existingUserRegistration') {
            showSnackbar(
              `${givenName ? `Welcome ${givenName}` : 'Welcome'}, to your new ${getLuxLoyaltyProgramDisplayName()}. Start earning today.`,
              'success',
            )
          }

          return result
        })
        .catch((err) => {
          showSnackbar(
            'Please try again later or contact us for assistance.',
            'critical',
            { heading: 'Oops! Something went wrong' },
          )

          throw err
        })
        .finally(() => {
          // Send message to mobile WebView
          if (headlessMode) {
            appViewMessageHandler('luxLoyalty', { enrolled })
          }
        }),
    })
  }
}

export function fetchLuxLoyaltyAccount(token?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
    const luxLoyaltyAccount = state.luxLoyalty.account

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyAccount.fetching ||
      luxLoyaltyAccount.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_ACCOUNT,
      request: () => fetchLuxLoyaltyAccountRequest(token),
    })
  }
}

export function fetchLuxLoyaltyAccountEligibility(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const memberId = state.auth.account.memberId
    const hasLuxLoyaltyAccount = getHasLuxLoyaltyAccount(state)
    const luxLoyaltyEligibility = state.luxLoyalty.eligibilityTier

    if (
      !getIsLuxLoyaltyEnabled(state) ||
      !memberId ||
      hasLuxLoyaltyAccount ||
      luxLoyaltyEligibility.fetching ||
      luxLoyaltyEligibility.error
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY,
      request: () => fetchLuxLoyaltyAccountEligibilityRequest(memberId),
    })
  }
}

export function clearLuxLoyaltyEnrolment(): AppAction {
  return {
    type: CLEAR_LUX_LOYALTY_ENROLMENT,
  }
}

export function fetchLuxLoyaltyConfig(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (
      state.luxLoyalty.config ||
      state.luxLoyalty.fetchingConfig ||
      state.luxLoyalty.configError
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_LUX_LOYALTY_CONFIG,
      request: () => getLuxLoyaltyConfig(state.geo.currentRegionCode),
    })
  }
}
