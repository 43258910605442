import { paths } from '@luxuryescapes/contract-svc-lux-loyalty'
import { luxLoyaltyAccountMapper, luxLoyaltyPendingPointsMapper } from './mappers/luxLoyaltyMap'
import requestUtils, { authOptions } from './requestUtils'
import qs from 'qs'

type EnrolLuxLoyaltyAccountPayload = paths['/api/lux-loyalty/account']['post']['parameters']['body']['payload']
type EnrolLuxLoyaltyAccountResponse = paths['/api/lux-loyalty/account']['post']['responses']['201']['content']['application/json']
type GetLuxLoyaltyAccountResponse = paths['/api/lux-loyalty/account/me']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyOrderPendingPoints = paths['/api/lux-loyalty/customer/{customerId}/order/{orderId}/pending-points']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyAccountEligibilityResponse = paths['/api/lux-loyalty/customer/{customerId}/enrolment-tier']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyProgramConfigResponse = paths['/api/lux-loyalty/program-configuration']['get']['responses']['200']['content']['application/json']

const BASE_PATH = '/api/lux-loyalty'

export async function enrolLuxLoyaltyAccountRequest(payload: EnrolLuxLoyaltyAccountPayload, token?: string) {
  const response = await requestUtils.post<EnrolLuxLoyaltyAccountResponse, EnrolLuxLoyaltyAccountPayload>(
    `${BASE_PATH}/account`,
    payload,
    {
      ...authOptions(token),
      excludeBrand: true,
    })

  return luxLoyaltyAccountMapper(response.result.accountSummary)
}

export async function fetchLuxLoyaltyAccountRequest(token?: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountResponse>(`${BASE_PATH}/account/me`, authOptions(token))

  return luxLoyaltyAccountMapper(response.result.accountSummary)
}

/**
 * Endpoint to determine which tier a customer is eligible to start with for when enrolling in the program
 */
export async function fetchLuxLoyaltyAccountEligibilityRequest(customerId: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountEligibilityResponse>(`${BASE_PATH}/customer/${customerId}/enrolment-tier`, {
    credentials: 'include',
  })

  return response.result.enrolmentTier
}

export async function requestLuxLoyaltyOrderPendingPoints(customerId: string, orderId: string): Promise<Array<App.LuxLoyaltyPendingPoints>> {
  const response = await requestUtils.get<GetLuxLoyaltyOrderPendingPoints>(`${BASE_PATH}/customer/${customerId}/order/${orderId}/pending-points`, {
    credentials: 'include',
  })
  return luxLoyaltyPendingPointsMapper(response.result.pendingPoints)
}

export function getLuxLoyaltyConfig(regionCode: string): Promise<App.LuxLoyaltyConfig> {
  const query = qs.stringify({
    region: regionCode,
  })

  return requestUtils.get<GetLuxLoyaltyProgramConfigResponse>(
    `${BASE_PATH}/program-configuration?${query}`,
    { excludeBrand: true },
  ).then((response): App.LuxLoyaltyConfig => {
    const loungePassBenefit = response.result.benefits.find(benefit => benefit.code === 'lounge_pass')
    const transferBenefit = response.result.benefits.find(benefit => benefit.code === 'airport_transfer')
    return {
      loungePassExperienceIds: loungePassBenefit?.offerIds ?? [],
      transferAirports: transferBenefit?.eligibleAirports ?? [],
      benefits: response.result.benefits.map(benefit => ({
        code: benefit.code,
        paymentType: benefit.lePaymentType,
      })),
    }
  })
}
