import { useEffect } from 'react'
import { useQsQueryParams } from 'hooks/useQueryParams'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { CRUISE_ROUTES } from 'constants/cruise'
import { useAppDispatch } from './reduxHooks'
import { get as getLocalStorage, set as setLocalStorage } from 'lib/storage/isomorphicLocalStorage'

export default function useCruiseSelectedCabinType() {
  const dispatch = useAppDispatch()
  const { search, pathname } = useLocation()
  const { cabinTypes: selectedTypeFromQuery } = useQsQueryParams<{ cabinTypes?: string }>()

  const isCruiseSearchPage = pathname.includes(CRUISE_ROUTES.SEARCH_PAGE)
  const selectedTypeFromLocalStorage = getLocalStorage('cruiseCabinType')
  const selectedCabinType = selectedTypeFromQuery || selectedTypeFromLocalStorage

  useEffect(() => {
    if (
      isCruiseSearchPage &&
      selectedCabinType &&
      selectedTypeFromLocalStorage !== selectedCabinType
    ) {
      const nextSearch = setSearchParamValue(search, 'cabinTypes', selectedCabinType)
      dispatch(replace({ search: nextSearch }))
      setLocalStorage('cruiseCabinType', selectedCabinType)
    }
  }, [dispatch, isCruiseSearchPage, search, selectedCabinType, selectedTypeFromLocalStorage])

  return selectedCabinType
}
