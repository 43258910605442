import { createSelector } from 'reselect'
import moment from 'moment'
import { buildSearchParamsKey } from 'lib/search/searchUtils'
import { arrayToObject, sortBy } from 'lib/array/arrayUtils'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { getBedbankRateKey } from 'actions/BedbankOfferActions'
import { getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import { UTM_CONFIGS } from 'constants/utms'
import { SOURCE_TYPES } from 'constants/source'

export function getSuggestedDates(state: App.State, filterKey: string, offerId: string): App.OfferSuggestedDates | undefined {
  return state.offer.searchResultMetadata.offerSuggestedDates?.[filterKey]?.[offerId]
}

interface GetOffersBestPricesParams {
  offers: Array<App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary>;
  filters: App.OfferListFilters;
}

export interface OfferBestPriceData {
  bestPrice: number;
  bestMemberPrice: number;
  bestBaseMemberPrice: number;
  loading: boolean;
  soldOut: boolean;
  duration: number;
  bestPricePackage?: App.Package;
  error?: any;
}

/**
 * Returns "best price" information for the given offers.
 * Price given is the total for all rooms, NOT per room.
 */
export const getOffersBestPrices = createSelector(
  (state: App.State) => state.offer.offerBestPrices,
  (state: App.State) => state.offer.offerPricesLoading,
  (state: App.State) => state.offer.offerPricesErrors,
  (state: App.State) => state.offer.bedbankOfferRates,
  (state: App.State) => state.offer.offerRatesLoading,
  (state: App.State, params: GetOffersBestPricesParams) => params.offers,
  (state: App.State, params: GetOffersBestPricesParams) => params.filters,
  function combiner(bestPrices, pricesLoading, pricesErrors, bedbankOfferRates, bedbankOfferRatesLoading, offers, filters): Record<string, OfferBestPriceData> {
    const searchKey = buildSearchParamsKey(filters.checkIn, filters.checkOut, filters.rooms)
    const hasDates = !!(filters.checkIn && filters.checkOut)
    return arrayToObject(
      offers,
      (offer) => offer.id,
      (offer): OfferBestPriceData => {
        let duration = hasDates ? moment(filters.checkOut).diff(moment(filters.checkIn), 'days') : undefined

        if (offer.type === OFFER_TYPE_BED_BANK) {
          const rateKey = getBedbankRateKey(filters.rooms, filters.checkIn, filters.checkOut)
          const lowestRate = bedbankOfferRates[offer.id]?.[rateKey]?.[0]
          let loading = bedbankOfferRatesLoading[offer.id]
          if (hasDates && typeof lowestRate === 'undefined' && typeof loading === 'undefined') {
            // If we don't have a rate and haven't even started loading it yet, for all intents and purposes it is "loading"
            loading = true
          }

          const soldOut = hasDates && !lowestRate && !loading
          let bestPrice: number
          if (lowestRate) {
            bestPrice = lowestRate.totals.inclusive + lowestRate.totals.propertyFees
            duration = lowestRate.nights
          }

          return {
            bestPrice,
            bestMemberPrice: 0,
            bestBaseMemberPrice: 0,
            duration,
            loading,
            soldOut,
          }
        } else {
          const bestPriceInfo = bestPrices[offer.id]?.[searchKey]
          const available = bestPriceInfo?.available
          const rate = available ? bestPriceInfo.rate : undefined
          const error = pricesErrors[offer.id]?.[searchKey]
          let loading = pricesLoading[offer.id]?.[searchKey]
          if (hasDates && !bestPriceInfo && typeof loading === 'undefined') {
            // If we don't have a rate and haven't even started loading it yet, for all intents and purposes it is "loading"
            loading = true
          }

          const soldOut = hasDates && bestPriceInfo && !available

          let bestPricePackage: App.Package
          if (available && 'packages' in offer) {
            bestPricePackage = offer.packages?.find(pkg => pkg.uniqueKey === rate.packageUniqueKey)
          } else if (!hasDates) {
            let bpPackage

            if (offer.hasTactical) {
              const allPackagesFilteredByLowestPrice = (offer.packages ?? []).filter(pkg => pkg.duration === offer.lowestPricePackage?.duration)
              const packagedViews = allPackagesFilteredByLowestPrice.filter(pkg => pkg.roomRate?.isPackaged)

              for (const view of packagedViews) {
                if (view.price > 0) {
                  const indexPackageViewByDuration = allPackagesFilteredByLowestPrice.findIndex(x => x.roomType?.id === view.roomType?.id && x.roomRate?.packagedRatePlanId === view.roomRate?.ratePlanId)
                  if (indexPackageViewByDuration >= 0) {
                    allPackagesFilteredByLowestPrice[indexPackageViewByDuration] = view
                  } else {
                    allPackagesFilteredByLowestPrice.push(view)
                  }
                }
              }

              bpPackage = sortBy(allPackagesFilteredByLowestPrice.filter(pkg => pkg.hasTactical && pkg.price > 0), p => p.price, 'asc')[0]
            }

            bestPricePackage = bpPackage || offer.lowestPricePackage
          }

          let bestPrice: number
          let bestMemberPrice = 0
          let bestBaseMemberPrice = 0
          if (bestPricePackage) {
            const roomCount = filters?.rooms?.length || 1
            const extraGuestSurcharges = getSumExtraGuestsSurcharge(filters.rooms, offer, bestPricePackage)
            bestPrice = (rate?.price ?? bestPricePackage.price) * roomCount + extraGuestSurcharges
            bestBaseMemberPrice = rate?.memberPriceWithSurcharge ?? bestPricePackage.memberPrice
            bestMemberPrice = bestBaseMemberPrice * roomCount + extraGuestSurcharges
            duration = bestPricePackage.duration
          }

          return {
            bestPrice,
            bestMemberPrice,
            bestBaseMemberPrice,
            duration,
            loading,
            error,
            soldOut,
            bestPricePackage,
          }
        }
      },
    )
  },
)

/**
 * Use to select for a combined state of all offers by id across the app - does not include summary offers
 *
 * In theory, one day, this should be a single field in a reducer but for now
 * it's spread across many fields based on offer type.
 */
export const getFullOffers = createSelector(
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.bedbankOffers,
  (state: App.State) => state.cruise.cruiseOffers,
  (state: App.State) => state.offer.tourV2Offers,
  (offers, bedbankOffers, cruises, tours) => {
    return {
      ...cruises,
      ...bedbankOffers,
      ...offers,
      ...tours,
    }
  },
)

/**
 * Use to select for a combined state of all offers by id across the app
 *
 * In theory, one day, this should be a single field in a reducer but for now
 * it's spread across many fields based on offer type.
 */
export const getAllOffers = createSelector(
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.offerSummaries,
  (state: App.State) => state.offer.bedbankOffers,
  (state: App.State) => state.offer.bedbankOfferSummaries,
  (state: App.State) => state.cruise.cruiseOffers,
  (state: App.State) => state.offer.tourV2Offers,
  (state: App.State) => state.experience.experiences,
  (offers, offerSummaries, bedbankOffers, bedbankSummaries, cruises, tours, experiences): Record<string, App.AnyOffer> => {
    return {
      ...cruises,
      ...tours,
      ...offerSummaries,
      ...bedbankSummaries,
      ...bedbankOffers,
      ...offers,
      ...experiences,
    }
  },
)

export const getAllAirportTransferOffers = createSelector(
  (state: App.State) => state.experience.experiences,
  (experiences): Record<string, App.ExperienceOffer> => {
    return {
      ...experiences,
    }
  },
)

export const isPaidSession = createSelector(
  (state: App.State) => state.utm,
  (utm_params): boolean => {
    const utmStateArray = Object.keys(utm_params)
    return utmStateArray.some(utm => UTM_CONFIGS.advanced.utms.includes(utm))
  },
)

export const getSource = createSelector(
  (state: App.State) => state.router.location.query?.source ?? undefined,
  (state: App.State) => state.router.location.pathname,
  (state: App.State) => state.utm,
  (state: App.State) => state.auth,
  (source, pathname, utm, auth): string => {
    const sourceData: Array<string> = []
    if (pathname.includes('/partner/')) {
      sourceData.push('offer')
    } else if (pathname.includes('/search')) {
      sourceData.push('search')
    } else if (pathname.includes('/checkout/')) {
      sourceData.push('checkout')
    }

    const memberId = auth.account?.memberId
    const bp = utm.bp

    // When the bp=2 cookie/session value exists AND the user is not logged in then always send source=metasearch parameter in bedbank requests to svc-public-offer & svc-bedbank.
    if (!memberId && bp === '2' && source !== SOURCE_TYPES.METASEARCH) {
      sourceData.push(SOURCE_TYPES.METASEARCH)
    }

    if (source) {
      sourceData.push(source)
    }

    return sourceData.join(',')
  },
)
