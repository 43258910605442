import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import GlobalMobileSearchTabContentContainer from './GlobalMobileSearchTabContentContainer'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import { centreElementInView } from 'lib/window/scrollUtils'
import styled from 'styled-components'
import config from 'constants/config'
import { rem } from 'polished'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { useScreenSizeOnly } from 'hooks/useScreenSize'

const wlMobileAppStyle = config.MOBILE_APP_CUSTOMISATIONS ? `padding-top: ${rem(20)};` : ''

const StyledModal = styled(ModalBase)`
  ${wlMobileAppStyle}
 `

interface Props {
  isOpen: boolean;
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTabV2>;
  selectedTab: App.GlobalSearchVerticalTabV2;
  closeSearch: () => void;
  onTabSelect: (key: SEARCH_VERTICALS) => void;
  onSearch?: () => void;
}

function GlobalMobileSearchV2(props: Props) {
  const { isOpen, closeSearch, tabs, selectedTab, onTabSelect, onSearch } = props
  const isMobileScreen = useScreenSizeOnly('mobile')
  const tabsArray = useMemo(() => [...tabs.entries()], [tabs])
  const tabsRef = useRef<Record<string, HTMLButtonElement>>({})

  const onTabClick = useCallback((searchVertical: SEARCH_VERTICALS) => {
    onTabSelect(searchVertical)
    if (searchVertical !== SEARCH_VERTICALS.HOTELS) {
      setShouldShowSearch(true)
    }
  }, [onTabSelect])

  const onCloseClick = useCallback(() => {
    closeSearch()
    setShouldShowSearch(true)
  }, [closeSearch])

  const [shouldShowSearch, setShouldShowSearch] = useState(true)
  const [isSearchEnabled, setIsSearchEnabled] = useState(true)

  const selectedTabElement = selectedTab ? tabsRef.current?.[selectedTab.key] : undefined
  useEffect(() => {
    centreElementInView(selectedTabElement)
  }, [selectedTabElement])

  const title = config.businessTraveller.currentAccountMode === 'business' ? 'Search your next business booking' : 'Search your next escape'

  return (
    <StyledModal
      mode="drawer"
      isOpen={isMobileScreen && isOpen}
      onClose={onCloseClick}
    >
      <ModalHeader
        title={title}
        onCloseButtonClick={onCloseClick}
      />
      <ModalBody>
        <ModalContent>
          <TabButtonsGroup>
            {tabsArray.map(([searchVertical, { label, Icon }]) => <TabButton
              size="medium"
              key={searchVertical}
              isActive={searchVertical === selectedTab?.key}
              onClick={() => onTabClick(searchVertical)}
              ref={(node) => { if (node) tabsRef.current[searchVertical] = node }}
              format="titlecase"
              startIcon={<Icon />}
            >
              {label}
            </TabButton>)}
          </TabButtonsGroup>
          {selectedTab && <GlobalMobileSearchTabContentContainer
            tab={selectedTab}
            closeSearch={onCloseClick}
            onSearch={onSearch}
            showSearch={setShouldShowSearch}
            onSearchValidate={setIsSearchEnabled}
          />}
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          disabled: !isSearchEnabled,
          children: shouldShowSearch ? 'Search' : '',
          type: selectedTab?.formId ? 'submit' : 'button',
          form: selectedTab?.formId,
          onClick: selectedTab?.formId ? undefined : onSearch,
        }}
      />
    </StyledModal>
  )
}

export default GlobalMobileSearchV2
