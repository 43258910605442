import { createSelector } from 'reselect'
import {
  getTourV2IdForCheckoutItem,
  isLuxPlusSubscriptionItem,
  isTourV2ExperienceItem,
  isSubscriptionJoinItem,
  isTourV2Item,
  isNoProtectionInsuranceItem,
  isInsuranceItem,
  findPostPurchaseCheckout,
  isBedbankItem,
} from 'lib/checkout/checkoutUtils'
import { subDays } from 'lib/datetime/dateUtils'
import config from 'constants/config'
import { PAYMENT_SCHEDULE_ENABLED_REGIONS } from 'constants/config/region'
import { getTourV2CheckoutItems } from 'checkout/selectors/view/accommodation'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { sum } from 'lib/array/arrayUtils'
import { getInsuranceTotals } from '../insurance'
import getAllItemsDiscountTotals from '../getAllItemsDiscountTotals'
import { checkoutWithMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'
import { isAgentHubEnabled } from 'agentHub/selectors/agentHubSelectors'
import getAllItemViews from 'checkout/selectors/view/getAllItemViews'
import { getAllOffers } from 'selectors/offerSelectors'
import { getAgentHubCommissionItemKey } from 'lib/agentHub/agentHubUtils'
import { AGENT_HUB_PAYMENT_MODE } from 'constants/payment'

export const isPaymentScheduleEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (_state: App.State) => config.PAYMENT_SCHEDULE_ENABLED,
  (currentRegionCode, isEnabled) => {
    if (!isEnabled) {
      return false
    }

    if (!PAYMENT_SCHEDULE_ENABLED_REGIONS.includes(currentRegionCode)) {
      return false
    }

    return true
  },
)

const isItemAllowedWithPaymentSchedules = (item: App.Checkout.AnyItem): boolean => {
  return isTourV2Item(item) ||
  isTourV2ExperienceItem(item) ||
  isLuxPlusSubscriptionItem(item) ||
  isSubscriptionJoinItem(item) ||
  isInsuranceItem(item) ||
  (isBedbankItem(item) && !!item.bundledItemIds?.length)
}

const canGetItemsPaymentScheduleInfo = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getAllOffers(state),
  (state: App.State) => state.checkout.cart.isGift,
  (items, offers, isGift): Array<boolean> | false => {
    if (isGift || (!items.every(isItemAllowedWithPaymentSchedules))) {
      return false
    }

    const tourV2Items = items.filter(isTourV2Item)

    return tourV2Items.map((item) => {
      const tourV2Offer = offers[getTourV2IdForCheckoutItem(item)] as App.Tours.TourV2Offer | undefined
      const templateId = tourV2Offer?.paymentScheduleInfo?.templateId
      return !!templateId
    })
  },
)

const isAnyItemDisabledForPaymentSchedule = createSelector(
  canGetItemsPaymentScheduleInfo,
  (itemsPaymentScheduleInfo): boolean => !itemsPaymentScheduleInfo || itemsPaymentScheduleInfo.some(item => !item),
)

export const isPaymentScheduleAvailable = createSelector(
  (state: App.State) => isPaymentScheduleEnabled(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => isAnyItemDisabledForPaymentSchedule(state),
  (
    isPaymentScheduleEnabled,
    postPurchase,
    isAnyItemDisabledForPaymentSchedule,
  ): boolean => {
    const result = (
      isPaymentScheduleEnabled &&
      !postPurchase &&
      !isAnyItemDisabledForPaymentSchedule
    )
    return result
  },
)

export const getPaymentScheduleTemplateIdFromTourV2Offer = createSelector(
  (state: App.State) => state.offer.tourV2Offers,
  getTourV2CheckoutItems,
  (tourV2Offers, tourV2Items): string | undefined | null => {
    const item = tourV2Items.find(item => {
      const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
      return offer?.paymentScheduleInfo?.templateId
    })

    if (!item) {
      return undefined
    }

    return tourV2Offers[getTourV2IdForCheckoutItem(item)]?.paymentScheduleInfo?.templateId
  },
)

const mapItemType = (item: App.Checkout.AnyItem): string => {
  if (isTourV2Item(item)) {
    return 'tour_v2'
  }
  if (isTourV2ExperienceItem(item)) {
    return 'tour_optional_experience'
  }
  if (isBedbankItem(item)) {
    return 'bedbank'
  }
  if (isLuxPlusSubscriptionItem(item) || isSubscriptionJoinItem(item)) {
    return 'subscription'
  }
  return item.itemType
}

export const getPaymentScheduleRequestInfo = createSelector(
  (state: App.State) => isPaymentScheduleAvailable(state),
  (state: App.State) => getInsuranceTotals(state),
  (state: App.State) => getAllItemsDiscountTotals(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getAllOffers(state),
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => state.checkout.commissionData?.itemsCommission,
  (state: App.State) => state.checkout.payment.agentHubPaymentMode,
  (isPaymentScheduleAvailable, insuranceTotals, discountTotalToCartItemsMap, checkoutWithMemberPrice, cartItems, offers, itemViews, itemsCommission, agentHubPaymentMode): App.Checkout.PaymentScheduleRequestInfo | null => {
    if (!isPaymentScheduleAvailable) {
      return null
    }

    const shouldUseAgentHubCommission = isAgentHubEnabled && agentHubPaymentMode === AGENT_HUB_PAYMENT_MODE.PAY_NET_AMOUNT

    const getPaymentScheduleInfoFromOffer = (item: App.Checkout.TourV2Item) => {
      const tourV2Offer = offers[getTourV2IdForCheckoutItem(item)] as App.Tours.TourV2Offer
      return tourV2Offer?.paymentScheduleInfo
    }

    const tourV2Items = cartItems.filter(isTourV2Item)

    const itemWithTemplateId = tourV2Items.find(getPaymentScheduleInfoFromOffer)
    if (!itemWithTemplateId) {
      return null
    }

    const paymentScheduleInfo = getPaymentScheduleInfoFromOffer(itemWithTemplateId)
    if (!paymentScheduleInfo?.templateId) {
      return null
    }

    if (!paymentScheduleInfo.supplierPaymentNumberOfDays) {
      return null
    }

    if (!itemWithTemplateId.total) {
      return null
    }

    const dueDate = subDays(new Date(itemWithTemplateId.startDate), paymentScheduleInfo.supplierPaymentNumberOfDays)

    const items = cartItems
      .filter(item => !(isSubscriptionJoinItem(item) && item.waived))
      .filter(item => !isNoProtectionInsuranceItem(item))
      .map((item) => {
        let total: number | undefined = undefined
        if (isLuxPlusSubscriptionItem(item) || isSubscriptionJoinItem(item)) {
          total = item.amount ?? undefined
        } else if (isInsuranceItem(item)) {
          total = insuranceTotals.hasRequiredData ? insuranceTotals.data.price : 0
        } else if (isBedbankItem(item)) {
          const bedbankItemView = itemViews.data.get(item.itemId)?.data as App.Checkout.BedbankAccommodationItemView | undefined
          total = (checkoutWithMemberPrice && bedbankItemView?.totals?.memberPrice) ? bedbankItemView?.totals?.memberPrice : bedbankItemView?.totals?.price ?? undefined
        } else {
          total = (checkoutWithMemberPrice && item.memberTotal) ? item.memberTotal : item.total
        }
        const discountTotal = discountTotalToCartItemsMap.has(item.itemId) ? discountTotalToCartItemsMap.get(item.itemId) : 0
        const commission = shouldUseAgentHubCommission ? itemsCommission?.[getAgentHubCommissionItemKey(item)]?.itemDiscount : 0
        return {
          idItems: item.itemId,
          total,
          type: mapItemType(item),
          discountTotal: shouldUseAgentHubCommission && commission ? commission : discountTotal,
        }
      })
    // We don't want to call payment schedule endpoint if any item has an undefined total or discountTotal
    if (!items.every((item): item is typeof item & { total: number, discountTotal: number } => item.total !== undefined && item.discountTotal !== undefined)) {
      return null
    }
    return {
      templateId: paymentScheduleInfo.templateId,
      bookingInfo: {
        bookingDate: moment().format(ISO_DATE_FORMAT),
        checkinDate: itemWithTemplateId.startDate,
        supplierDueDate: moment(dueDate).format(ISO_DATE_FORMAT),
        items,
      },
    }
  },
)

export const getServiceFeeForInitialPayment = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    return paymentSchedule.payment_schedules[0].feeAmount || 0
  },
)

export const getPaymentScheduleTotalServiceFee = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    return sum(paymentSchedule.payment_schedules, (schedule) => schedule.feeAmount)
  },
)

export const getServiceFeeForLaterPayments = createSelector(
  getPaymentScheduleTotalServiceFee,
  getServiceFeeForInitialPayment,
  (totalServiceFee, initialServiceFee): number => {
    return totalServiceFee - initialServiceFee
  },
)

export const getServiceFeePaymentScheduleData = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): App.PaymentScheduleServiceFeeData | undefined => {
    const chargeIndex = paymentSchedule.payment_schedules.findIndex(schedule => schedule.feeAmount > 0)

    if (chargeIndex === -1) {
      return undefined
    }

    return {
      chargeIndex,
      feeAmount: paymentSchedule.payment_schedules[chargeIndex].feeAmount,
      paymentType: paymentSchedule.payment_schedules[chargeIndex].paymentType,
    }
  },
)
