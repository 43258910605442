import React from 'react'
import Modal from 'components/Luxkit/Modal/Modal'
import getLuxLoyaltyProgramDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyProgramDisplayName'
import BodyText from 'components/Luxkit/Typography/BodyText'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import { useAppSelector } from 'hooks/reduxHooks'
import getLuxLoyaltyDisplayTier from 'luxLoyalty/selectors/getLuxLoyaltyDisplayTier'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import styled from 'styled-components'
import { rem } from 'polished'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import cn from 'clsx'
import Heading from 'components/Luxkit/Typography/Heading'
import useLuxLoyaltyUserBenefit from 'luxLoyalty/hooks/useLuxLoyaltyUserBenefit'
import FormatDateTime from 'components/utils/Formatters/FormatDateTime'

const BenefitDetailsContainer = styled.div`
  border: 1px solid ${props => props.theme.palette.neutral.default.seven};
`

const UpgradeTitle = styled(BodyTextBlock)`
  padding: ${rem(12)} ${rem(20)};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.seven};
`

const UpgradesAvailableContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${rem(8)};
  padding: ${rem(12)} ${rem(20)};
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.seven};
`

const ExpiryDetail = styled(BodyText)`
  padding: ${rem(12)} ${rem(20)};
  background-color: ${props => props.theme.palette.neutral.default.seven};
`

const TierIndicator = styled.div`
  height: ${rem(24)};
  width: ${rem(24)};
  border-style: solid;
  border-width: 2px;

  &.tier-bronze {
    border-color: ${props => props.theme.palette.product.luxLoyalty.bronze.backgroundTint};
    background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.backgroundShade}
  }

  &.tier-silver {
    border-color: ${props => props.theme.palette.product.luxLoyalty.silver.backgroundTint};
    background-color: ${props => props.theme.palette.product.luxLoyalty.silver.backgroundShade}
  }

  &.tier-gold {
    border-color: ${props => props.theme.palette.product.luxLoyalty.gold.backgroundTint};
    background-color: ${props => props.theme.palette.product.luxLoyalty.gold.backgroundShade}
  }

  &.tier-platinum {
    border-color: ${props => props.theme.palette.product.luxLoyalty.platinum.backgroundShade};
    background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.backgroundTint}
  }
`

function LuxLoyaltyRoomUpgradeBenefitModal() {
  const displayTier = useAppSelector(getLuxLoyaltyDisplayTier)
  const loyaltyUpgradeBenefit = useLuxLoyaltyUserBenefit('hotel_upgrade')
  const luxLoyaltyAccount = useAppSelector(state => state.luxLoyalty.account)
  const statusReviewDate = luxLoyaltyAccount.data?.statusReviewDate

  if (
    !loyaltyUpgradeBenefit?.remaining ||
    !loyaltyUpgradeBenefit.unlimited ||
    luxLoyaltyAccount.fetching ||
    luxLoyaltyAccount.error ||
    !luxLoyaltyAccount.data
  ) return null

  return <Modal title={`${getLuxLoyaltyProgramDisplayName()} free room upgrades`}>
    <VerticalSpacer gap={24}>
      <BodyText variant="large">
        As a {getLuxLoyaltyProgramDisplayName()} {getLuxLoyaltyTierDisplayName(displayTier)} member you have free room{' '}
        upgrades available on select hotel offers to use in your membership year.
      </BodyText>
      <BenefitDetailsContainer>
        <UpgradeTitle variant="medium" weight="bold" startIcon={<LineRoomUpgradeIcon size="M" />} colour="neutral-two">
          Free room upgrades
        </UpgradeTitle>
        <UpgradesAvailableContainer>
          <TierIndicator className={cn(`tier-${displayTier}`)} />
          <BodyText variant="medium" colour="neutral-two">
            Available upgrades
          </BodyText>
          <Heading variant="heading6" colour="neutral-two">
            {loyaltyUpgradeBenefit.unlimited ? 'unlimited' : `x${loyaltyUpgradeBenefit.remaining}`}
          </Heading>
        </UpgradesAvailableContainer>
        {/* Lowest ranked tier that has room upgrades */}
        {displayTier === 'gold' && <ExpiryDetail variant="small" colour="neutral-two">
          Benefit expires <b><FormatDateTime value={statusReviewDate!} format="day-long-month-long-year" /></b>
        </ExpiryDetail>}
      </BenefitDetailsContainer>
    </VerticalSpacer>
  </Modal>
}

export default LuxLoyaltyRoomUpgradeBenefitModal
