import clsx from 'clsx'
import React, { forwardRef, HTMLAttributes } from 'react'
import styled from 'styled-components'

const Container = styled.tr`
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};
`

interface Props extends HTMLAttributes<HTMLTableRowElement> {
}

/**
 * Must be child of `<TableHeader>`.
 *
 * Contains `<TableHeaderCell>`s.
 */
const TableHeaderRow = forwardRef<HTMLTableRowElement, Props>(function TableHeaderRow(props, ref) {
  return <Container {...props} ref={ref} className={clsx(TableHeaderRow.name, props.className)} />
})

export default TableHeaderRow
