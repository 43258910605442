import React, { useCallback, useContext, useMemo } from 'react'
import { generateLuxLoyaltyPointsCalculatorCruiseOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import LuxLoyaltyInfoModal from 'luxLoyalty/components/LuxLoyaltyInfoModal'
import ModalContext from 'contexts/ModalContext'

interface Props {
  offer: App.CruiseOffer;
  bookingPrice?: number;
  calculationType: 'estimate' | 'exact',
}

function CruiseLuxLoyaltyPoints({ offer, bookingPrice, calculationType }: Props) {
  const showModal = useContext(ModalContext)

  const pointsEarnCalculationOptions = useMemo(
    () => [generateLuxLoyaltyPointsCalculatorCruiseOptions(offer, { bookingPrice })],
    [offer, bookingPrice],
  )

  const onInfoIconClick = useCallback(() => {
    showModal(<LuxLoyaltyInfoModal calculationRequests={pointsEarnCalculationOptions} />)
  }, [showModal, pointsEarnCalculationOptions])

  return <LuxLoyaltyPoints
    calculationRequests={pointsEarnCalculationOptions}
    calculationType={calculationType}
    onInfoIconClick={onInfoIconClick}
  />
}

export default CruiseLuxLoyaltyPoints
