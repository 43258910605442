import { ExperienceOrderCreationItem } from 'api/order'
import { getExperienceItems } from 'checkout/selectors/view/experience'
import { ITEM_TYPE_BNBL_EXPERIENCE, ITEM_TYPE_EXPERIENCE } from 'constants/cart'
import config from 'constants/config'
import { ExperienceBookingType } from 'constants/experience'
import { arrayToObject, fillArray } from 'lib/array/arrayUtils'
import { getExperienceTicketDate, getExperienceTimesKey } from 'lib/experiences/experienceUtils'
import { filterItemDiscounts } from 'lib/promo/promoMappers'
import { incrementUuidV4 } from 'lib/string/uuidV4Utils'
import { createSelector } from 'reselect'
import { getExperienceItemDiscounts } from '../checkout'
import getExperienceItemSourceApp from '../getExperienceItemSourceApp'
import { getFormattedTravellers } from '../orderCreationSelectors'
import formatItemDiscount from 'lib/payment/formatDiscountItem'

export const getFormattedExperienceItems = createSelector(
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => getFormattedTravellers(state),
  (state: App.State) => getExperienceItemDiscounts(state),
  (state: App.State) => getExperienceItemSourceApp(state),
  (experienceItems, experiences, experienceTimes, currencyCode, specialRequestsMap, travellers, itemDiscounts, sourceApp) => {
    if (!travellers || !itemDiscounts.hasRequiredPromoCodeData) { return [] }

    return experienceItems
      .map(item => {
        const experience = experiences[item.experienceId]

        // order service expects to send expiration date in ticket.date if open date item
        // if it's a BNBL or GIFT, value should be undefined
        let ticketDate: string | undefined
        if (!(item.isBuyNowBookLater || item.isGift)) {
          ticketDate = ['NO-CALENDAR-FIXED-END', 'NO-CALENDAR-FIXED-VALIDITY'].includes(experience.bookingType) ?
            experience.expirationDate :
            getExperienceTicketDate(item.date, item.time)
        }

        const pickupPoint = experience.pickupPoints.find((pickupPoint) => pickupPoint.id == item.pickupPointId)
        const redemptionLocation = experience.redemptionLocations.find((location) => location.id == item.redemptionLocationId)

        const key = getExperienceTimesKey(item.experienceId, item.date, {
          currency: currencyCode,
          pickupPointId: item.pickupPointId,
          redemptionLocationId: item.redemptionLocationId,
          isBuyNowBookLater: item.isBuyNowBookLater,
          isGift: item.isGift,
          ticketMode: item.ticketModeKey,
        })

        const timeSlots = experienceTimes[item.experienceId]?.[key]?.slots
        const tickets = timeSlots?.find(slot => slot.time === item.time)?.tickets ?? timeSlots?.flatMap(slot => slot.tickets)
        const ticketMap = arrayToObject(tickets, (ticket) => ticket.id)

        let nextTransactionKey = item.transactionKey
        let participantsCount = 0
        return item.tickets.map(ticket => {
          const ticketDetails = ticketMap[ticket.ticketId]

          // for each ticket item (count) group the corresponding participant
          const participants = travellers.participants?.slice(participantsCount, participantsCount + ticket.count)
          participantsCount += ticket.count

          return fillArray(ticket.count).map((index): ExperienceOrderCreationItem => {
            // An experience item only has one transaction key,
            // but we need a separate key per item we send to svc-order.
            // So we'll increment the key to create a new one per ticket.
            const transactionKey = nextTransactionKey
            nextTransactionKey = incrementUuidV4(nextTransactionKey)

            // Store bookByDate from ticket if there's one
            const bookByDate = ticketDetails.bookByDate ?? experience.bookByDate
            return {
              type: item.isBookingBNBL ? ITEM_TYPE_BNBL_EXPERIENCE : ITEM_TYPE_EXPERIENCE,
              id_experience_items: ticket.orderItemIds?.[index],
              provider_offer_id: experience.id,
              brand: config.BRAND,
              transaction_key: transactionKey,
              total: ticketDetails.price,
              language: item.languageId,
              categories: experience.categories,
              cancellation_policies: experience.cancellationPolicies.length > 0 ? {
                isFree: experience.copy.cancellationInfo.isFree,
                timezoneOffset: `${new Date().getTimezoneOffset()}`,
                refundPolicies: experience.cancellationPolicies.map(policy => ({
                  id: policy.id,
                  periods: policy.periods,
                  periodLabel: policy.periodLabel,
                  type: policy.type,
                  value: policy.value,
                })),
                text: experience.copy.cancellationInfo.text,
              } : undefined,
              le_exclusive: experience.leExclusive,
              redemption_location_id: redemptionLocation?.id,
              redemption_location_name: redemptionLocation?.name,
              pickup_point_id: pickupPoint?.id,
              pickup_point_name: pickupPoint?.name,
              special_requests: specialRequestsMap[item.experienceId],
              traveller_info: {
                customer: travellers.customer,
                ...(participants?.length && {
                  participants: participants.map(participant => ({
                    [ticketDetails.productId]: participant,
                  })),
                }),
              },
              ticket: {
                fareType: ticketDetails.name,
                identifier: ticketDetails.id,
                productId: ticketDetails.productId,
                type: ticketDetails.type,
                date: ticketDate,
                rateStartDate: ticketDetails.rateStartDate,
                rateEndDate: ticketDetails.rateEndDate,
                bookByDate,
                isExtra: ticketDetails.isExtra,
              },
              title: experience.name,
              booking_type: (item.isBuyNowBookLater || item.isGift) ? ExperienceBookingType.BUY_NOW_BOOK_LATER :
                ExperienceBookingType.INSTANT_BOOKING,
              taxes_and_fees: ticketDetails.taxesAndFees,
              ticketed: experience.ticketed,
              ...(ticketDetails.discounts.app.amount > 0 && {
                app_discount_amount: ticketDetails.discounts.app.amount,
                app_discount_percent: ticketDetails.discounts.app.percentage,
              }),
              item_discounts: filterItemDiscounts({ itemDiscounts: itemDiscounts.itemDiscounts, lookup: { specificType: 'experience', transactionKeys: [transactionKey], _logFilterInfo: true } }).map(formatItemDiscount),
              source_app: sourceApp,
            }
          })
        })
      }).flat(2)
  },
)
