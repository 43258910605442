import moment from 'moment'
import { getItemUniqueKey, getPackageId } from 'checkout/lib/utils/accommodation/cart'
import { computeCabinPricing } from 'checkout/lib/utils/cruises/pricing'
import { CHECKOUT_ITEM_TYPE_CRUISE } from 'constants/checkout'
import { OFFER_TYPE_CRUISE } from 'constants/offer'
import { pluralizeToString } from 'lib/string/pluralize'
import { getCruiseDurationByItinerary } from 'lib/cruises/cruiseUtils'
import { buildCruiseSummaryBreakdown } from 'checkout/lib/utils/cruises/summary'
import { getBreakdownTitle } from 'checkout/utils'
import { CheckoutPageId } from 'checkout/constants/pages'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { sum } from 'lib/array/arrayUtils'
import { getCruiseDepositAmount } from './booking'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'

export function isCruiseOfferView(
  view: App.Checkout.AccommodationOfferView,
): view is App.Checkout.CruiseAccommodationOfferView {
  return view.offerType === OFFER_TYPE_CRUISE
}

function dummyCruiseItemView(
  item: App.Checkout.CruiseItem,
  kind: 'cruise',
  offer: App.CruiseOffer | undefined,
): App.Checkout.CruiseAccommodationItemView {
  return {
    kind,
    packageId: getPackageId(item),
    uniqueKey: getItemUniqueKey(item),
    item,
    mainLabel: '',
    occupancy: { adults: 0, children: 0, infants: 0 },
    totals: {
      price: 0,
      memberPrice: 0,
      value: 0,
      surcharge: 0,
      taxesAndFees: 0,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
    depositAmount: 0,
    removeLabel: 'remove',
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
  }
}

export function buildCruiseItemView(
  item: App.Checkout.CruiseItem,
  offer: App.CruiseOffer,
  cabinSelectionData: App.Cruises.BookingCabinSelectionData,
  cabinPricingData: App.Cruises.BookingCabinPricingData,
  rateListData: App.Cruises.CruiseBookingRateListData,
  rateDetailsData: App.Cruises.BookingRateDetailsData,
  isPurchasePage: boolean,
  currentBookingStepLowestPrice: number,
  consolidatedPaymentSchedule?: App.Cruises.CruiseConsolidatedPaymentSchedule,
): App.WithDataStatus<App.Checkout.CruiseAccommodationItemView> {
  if (!offer) {
    return {
      hasRequiredData: false,
      data: dummyCruiseItemView(item, CHECKOUT_ITEM_TYPE_CRUISE, offer),
    }
  }

  const { cabinSelection } = cabinSelectionData

  const rateDetails = rateDetailsData.detailsByRate[item.componentId || ''] as App.CruiseCabinRateDetailsResponse
  const cabinRate = rateListData.rates.find(rate => rate.componentId === item.componentId)

  const mainLabel = cabinRate?.pricedCategoryCode || ''
  const cabinCategory = offer.ship.cabinCategories?.find(category => category.code === item.cabinCode)

  const departures = offer.departures ? Object.values(offer.departures).flat() : []
  const departure = departures.find(departure => departure.id === item.departureId)

  const { taxesAndFees, price } = computeCabinPricing(cabinSelectionData, cabinPricingData)
  const depositAmount = getCruiseDepositAmount(item.bookingId, consolidatedPaymentSchedule)

  const hasRequiredData = isPurchasePage ? !!cabinSelection : !!offer

  return {
    hasRequiredData,
    data: {
      luxLoyaltyProductType: getLuxLoyaltyProductType(offer),
      item: { ...item, offerName: offer.name },
      kind: CHECKOUT_ITEM_TYPE_CRUISE,
      packageId: item.departureId,
      uniqueKey: item.departureId,
      departure,
      occupancy: item.occupancy,
      totals: {
        surcharge: 0,
        memberPrice: 0,
        price,
        taxesAndFees,
        value: 0,
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
      currentBookingStepLowestPrice,
      luxPlusInclusionsByTier: item.luxPlusInclusionsByTier,
      depositAmount,
      mainLabel,
      ...(cabinRate && { cabinRate }),
      ...(cabinCategory && { cabinCategory }),
      ...(rateDetails && { rateDetails }),
      ...(cabinSelection && { cabinSelection }),
      ...(cabinPricingData && { cabinPricingData }),

      // Accommodation specific attributes
      removeLabel: 'remove',
    },
  }
}

export function getCruiseBreakdownView(
  offerView: App.Checkout.CruiseAccommodationOfferView,
  hidePrice: boolean,
): App.Checkout.PriceBreakdownView {
  const { startDate, endDate, saleUnit, itemViews, mainLabel, currentCheckoutPage } = offerView

  const items = itemViews.map((itemView, index) => {
    return getCruiseBreakdownItemView(itemView, index, saleUnit, hidePrice, currentCheckoutPage)
  })

  const cruiseDuration = getCruiseDurationByItinerary(offerView.offer?.itinerary ?? [])
  const formattedStartDate = startDate ? `${moment(startDate).format(DMY_CASUAL_FORMAT)}` : 'start'
  const formattedEndDate = endDate ? `${moment(endDate).format(DMY_CASUAL_FORMAT)}` : 'end'
  const price = sum(items, item => (item.price ?? 0))
  const memberPrice = sum(items, item => (item.memberPrice ?? 0))
  const cabins = items.length
  const passengers = itemViews.reduce(
    (acc, { occupancy }) => acc + occupancy.adults + (occupancy.children || 0) + (occupancy.infants || 0),
    0,
  )

  return {
    title: getBreakdownTitle(offerView),
    description: mainLabel,
    additionalInfoText: [
      `${offerView.offer?.cruiseLine.name} - ${offerView.offer?.ship.name}`,
      `${formattedStartDate} - ${formattedEndDate} - ${pluralizeToString('night', cruiseDuration - 1)}`,
      passengers ? `${pluralizeToString('cabin', cabins)} • ${pluralizeToString('passenger', passengers)}` : '',
    ],
    items,
    price,
    memberPrice,
    onboardCredits: items.find(item => item.onboardCredits)?.onboardCredits,
    productType: offerView.offerType,
    itemsAlwaysVisible: false,
  }
}

function getCruiseBreakdownItemView(
  itemView: App.Checkout.CruiseAccommodationItemView,
  index: number,
  saleUnit: string,
  hidePrice: boolean,
  currentCheckoutPage: string,
): App.Checkout.CruiseItemBreakdownView {
  const {
    additionalElements,
    additionalInfoText,
  } = buildCruiseSummaryBreakdown(itemView)
  const cancellationPolicy = buildItemViewCancellationPolicy(itemView)
  const onboardCredits = buildItemViewOnboardCredits(itemView)

  const showPrice = currentCheckoutPage === CheckoutPageId.Purchase && !hidePrice
  const price = showPrice ? (itemView?.totals.price ?? 0) - itemView.totals.taxesAndFees : undefined
  const title = [`${saleUnit} ${index + 1}`, itemView.item?.cabinType].filter(Boolean).join(' - ')

  return {
    title,
    additionalInfoText,
    cancellationPolicy,
    additionalElements,
    price,
    taxesAndFees: itemView.totals.taxesAndFees,
    offerId: itemView.item.offerId,
    itemId: itemView.item.itemId,
    luxPlusInclusionsByTier: itemView.luxPlusInclusionsByTier,
    itemType: 'cruise',
    ...(onboardCredits && { onboardCredits }),
  }
}

function buildItemViewOnboardCredits(
  itemView: App.Checkout.CruiseAccommodationItemView,
): App.CruiseOnboardCredits | undefined {
  const { cabinSelection } = itemView
  const onboardCredits = cabinSelection?.pricing?.onboardCredit
  const onboardCreditAmount = onboardCredits?.amount
  const onboardCreditCurrencyCode = onboardCredits?.currency

  if (onboardCreditAmount && onboardCreditCurrencyCode) {
    return {
      amount: onboardCreditAmount,
      currencyCode: onboardCreditCurrencyCode,
    }
  }
}

function buildItemViewCancellationPolicy(
  itemView: App.Checkout.CruiseAccommodationItemView,
): App.Cruises.CruiseCancellationPolicyView | undefined {
  const pricing = itemView.cabinSelection?.pricing
  return {
    isNonRefundable: pricing?.isNonRefundable,
    cancellationSchedule: pricing?.cancellationSchedule,
    cancellationPolicies: pricing?.cancellationPolicy,
  }
}
