import { createSelector } from 'reselect'
import config from 'constants/config'
import { RefundProtectQuoteRequest, RefundProtectQuotePatchRequest } from 'api/insurance'
import getTotalsWithoutInsurance from 'checkout/selectors/payment/getTotalsWithoutInsurance'
import { checkoutAccommodationGroupingKey, checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { areOccupantsEqual, mergeOccupants, sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { getBookingProtectionEnabledCheckoutItems, getBookingProtectionItems } from '../view/bookingProtection'
import { groupBy, partitionBy, sum, last, take, nonNullable } from 'lib/array/arrayUtils'
import { excludeNullOrUndefined } from 'checkout/utils'
import { getBookingProtectionQuoteKey } from 'lib/checkout/bookingProtection/utils'
import { isBedbankItem, isFlightItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { adjustForDecimal } from 'lib/maths/mathUtils'
import { getFlightItemsView } from '../view/flights'
import { BOOKING_PROTECTION_RISK_TYPE_MAP } from 'constants/bookingProtection'
import { isSpoofed } from 'selectors/featuresSelectors'
import { getExperienceItemsView } from '../view/experience'
import { selectShouldUseBookingProtectionMemberPrice } from '../view/luxPlusSubscription'
import { isStandaloneExperience } from 'selectors/checkoutSelectors'
import { isMobileAppDevice } from 'lib/web/deviceUtils'
import getDestinationCountriesFromCart from '../view/getDestinationCountriesFromCart'

const getBookingProtectionOccupants = createSelector(
  (state: App.State) => getBookingProtectionEnabledCheckoutItems(state),
  (items): Array<App.Occupants> | undefined => {
    if (items.length > 0) {
      const [accommodationItems, flightItems] = partitionBy(items, (item) => (isInstantBookingLEHotelItem(item) || isBedbankItem(item)))
      const itemMap = groupBy(accommodationItems, checkoutAccommodationGroupingKey)

      const bookingProtectionOccupantsByAccommodationGroup = Array.from(itemMap.values()).map((itemList) => {
        const occupants = itemList
          .map(item => 'occupancy' in item ? item.occupancy : null)
          .filter(excludeNullOrUndefined)
        return mergeOccupants(occupants)
      })

      const bookingProtectionOccupantsByFlight = flightItems.map(item => 'occupants' in item ? item.occupants : null).filter(excludeNullOrUndefined)

      return [
        ...bookingProtectionOccupantsByAccommodationGroup,
        ...bookingProtectionOccupantsByFlight,
      ].flat()
    }
  },
)

export const areBookingProtectionOccupantsConsistent = createSelector(
  (state: App.State) => getBookingProtectionOccupants(state),
  (occupants): boolean => {
    return occupants ? areOccupantsEqual(...occupants) : true // if no occupants, that's consistent
  },
)

export const getBookingProtectionOccupantsFromCartItems = createSelector(
  (state: App.State) => getBookingProtectionOccupants(state),
  (state: App.State) => areBookingProtectionOccupantsConsistent(state),
  (occupants, isConsistent) => {
    if (isConsistent && occupants) {
      return occupants[0]
    }
  },
)

interface FlightMetadata {
  airline: string,
  departureAirportCode: string,
  departureCountry: string,
  arrivalAirportCode: string,
  eventTravelClass: string,
  flightType: string,
  hasReturnFlight: boolean,
}

export const getBookingProtectionStartDateFromCart = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => getExperienceItemsView(state),
  (accommodationViews, flightViews, experienceViews) => {
    if (!accommodationViews.hasRequiredData && !flightViews.hasRequiredData && !experienceViews.hasRequiredData) { return null }

    const accommodationDates = accommodationViews.data.map(item => item.startDate)

    const flightDates = flightViews.data.map(item => item.flights[0]?.journeyFlight.flights[0].departingDate)

    const experienceDates = experienceViews.data.map(item => item.bookingDate)

    const dates = nonNullable([...accommodationDates, ...flightDates, ...experienceDates])

    if (dates.length) {
      return moment.min(dates.map(date => moment(date, ISO_DATE_FORMAT)))
    }
  },
)

export const getFlightBookingMetadata = createSelector(
  (state: App.State) => getFlightItemsView(state),
  (flightViews): FlightMetadata | null => {
    if (!flightViews.hasRequiredData || !flightViews.data?.length) {
      return null
    }

    try {
      const departing = flightViews.data[0].flights[0]
      const departingFlight = departing?.journeyFlight.flights[0]
      const returningFlight = last(flightViews.data[0].flights[1]?.journeyFlight?.flights ?? [])

      const hasReturnFlight = !!returningFlight
      const totalFlightDuration = hasReturnFlight ? moment(returningFlight.arrivalDate).diff(moment(departingFlight?.departingDate), 'days') : null

      if (departingFlight) {
        return {
          airline: departingFlight.carrierName,
          departureAirportCode: departingFlight.departingAirport,
          departureCountry: departingFlight.departingCountry,
          arrivalAirportCode: departingFlight.arrivalAirport,
          eventTravelClass: departing?.fareFamily?.cabin ?? departingFlight.fareClass,
          hasReturnFlight,
          flightType: hasReturnFlight ? `${totalFlightDuration} Days` : 'OneWay',
        }
      }
    } catch {
      return null
    }

    return null
  },
)

const getBookingProtectionCoverUpdateAmountFromCart = createSelector(
  (state: App.State) => getTotalsWithoutInsurance(state),
  (totals): number | undefined => {
    if (totals.hasRequiredData) {
      const {
        price = 0,
        surcharge = 0,
        otherFees = {},
      } = totals.data
      return price + surcharge + sum(Object.values(otherFees))
    }
  },
)

export const mapCheckoutItemTypesToRiskType = createSelector(
  (state: App.State) => getBookingProtectionEnabledCheckoutItems(state),
  (items): BOOKING_PROTECTION_RISK_TYPE_MAP | undefined => {
    if (items.length === 0) return undefined
    if (items.length > 1) {
      if (items.every(item => isInstantBookingLEHotelItem(item) || isBedbankItem(item))) {
        return BOOKING_PROTECTION_RISK_TYPE_MAP.HOTEL
      }
      return BOOKING_PROTECTION_RISK_TYPE_MAP.BUNDLED
    }

    const item = items[0]
    if (isFlightItem(item)) {
      return BOOKING_PROTECTION_RISK_TYPE_MAP.FLIGHT
    }
    if (isInstantBookingLEHotelItem(item) || isBedbankItem(item)) {
      return BOOKING_PROTECTION_RISK_TYPE_MAP.HOTEL
    }
    return undefined
  },
)

export const getBookingProtectionCoverAmountFromCart = createSelector(
  (state: App.State) => getTotalsWithoutInsurance(state),
  (totals): number | undefined => {
    if (totals.hasRequiredData) {
      const {
        paidPrice = 0,
        price = 0,
        surcharge = 0,
        otherFees = {},
      } = totals.data
      return paidPrice + price + surcharge + sum(Object.values(otherFees))
    }
  },
)

export const getBookingProtectionExperienceTickets = createSelector(
  (state: App.State) => getBookingProtectionEnabledCheckoutItems(state),
  (items): number => {
    const totalCountOfTickets = sum(
      items,
      (item) => {
        if ('tickets' in item) {
          return sum(item.tickets, (ticket) => ticket.count)
        }
        return 0
      },
    )

    return totalCountOfTickets
  },
)

export const getBookingProtectionQuoteRequest = createSelector(
  (state: App.State) => getBookingProtectionOccupantsFromCartItems(state),
  (state: App.State) => getBookingProtectionCoverAmountFromCart(state),
  (state: App.State) => getBookingProtectionStartDateFromCart(state),
  (state: App.State) => mapCheckoutItemTypesToRiskType(state),
  (state: App.State) => getDestinationCountriesFromCart(state),
  (state: App.State) => getFlightBookingMetadata(state),
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => isSpoofed(state),
  (state: App.State) => isStandaloneExperience(state),
  (state: App.State) => getBookingProtectionExperienceTickets(state),
  (state: App.State) => getExperienceItemsView(state).data,
  (state: App.State) => isMobileAppDevice(state.system.rawUserAgentString),
  (
    occupants,
    coverAmount,
    startDate,
    riskType,
    getDestinationCountries,
    flightMetadata,
    currencyCode,
    isSpoofing,
    isStandaloneExperience,
    numberOfExperienceTickets,
    experienceView,
    isMobileApp,
  ): RefundProtectQuoteRequest | null => {
    let numberOfTickets
    let venue

    if ((!occupants && !numberOfExperienceTickets) || !coverAmount || !startDate) return null
    if (isStandaloneExperience) {
      numberOfTickets = numberOfExperienceTickets
      venue = experienceView[0]?.redemptionLocation?.name || ''
    } else {
      numberOfTickets = occupants?.adults ?? 0 + (occupants?.children ?? 0) + (occupants?.infants ?? 0)
      venue = take(getDestinationCountries, 10).join(',')
    }

    return {
      brand: 'luxuryescapes',
      currencyCode,
      eventTravelDateTime: startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ'),
      totalValue: coverAmount,
      numberOfTickets,
      // limiting the list countries to 10 to avoid sending large payloads to an external API
      venue,
      source: isSpoofing ? 'Mobile' : 'Web',
      riskType,
      ...(flightMetadata && {
        airline: flightMetadata.airline,
        departureAirportCode: flightMetadata.departureAirportCode,
        departureCountry: flightMetadata.departureCountry,
        arrivalAirportCode: flightMetadata.arrivalAirportCode,
        eventTravelClass: flightMetadata.eventTravelClass,
        flightType: flightMetadata.flightType,
      }),
      mobile_only_price: isMobileApp,
    }
  },
)

export const getBookingProtectionQuoteRequestKey = createSelector(
  (state: App.State) => getBookingProtectionQuoteRequest(state),
  (request): string => getBookingProtectionQuoteKey(request),
)

export const getBookingProtectionQuoteUpdateRequest = createSelector(
  (state: App.State) => getBookingProtectionCoverUpdateAmountFromCart(state),
  (state: App.State) => getBookingProtectionStartDateFromCart(state),
  (state: App.State) => mapCheckoutItemTypesToRiskType(state),
  (state: App.State) => getDestinationCountriesFromCart(state),
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (state: App.State) => isSpoofed(state),
  (coverAmount, startDate, riskType, getDestinationCountries, currencyCode, bookingProtectionItems, isSpoofing): RefundProtectQuotePatchRequest | null => {
    const item = bookingProtectionItems?.find(item => item.status === 'completed')
    if (!coverAmount || !startDate || !item) return null

    return {
      brand: 'luxuryescapes',
      currencyCode,
      eventTravelDateTime: startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ'),
      totalValue: coverAmount + item.coverAmount,
      quoteId: item.quoteId,
      numberOfTickets: item.numberOfTickets,
      venue: take(getDestinationCountries, 10).join(','),
      source: isSpoofing ? 'phone' : 'web',
      riskType,
    }
  },
)

export const getBookingProtectionQuoteUpdateRequestKey = createSelector(
  (state: App.State) => getBookingProtectionQuoteUpdateRequest(state),
  (request) => getBookingProtectionQuoteKey(request),
)

export const getUpdatedQuoteFromBookingProtectionQuotes = createSelector(
  (state: App.State) => state.bookingProtection.quotes,
  (state: App.State) => getBookingProtectionQuoteUpdateRequestKey(state),
  (quotes, quoteKey): App.BookingProtectionQuote | undefined => {
    if (!quoteKey || !quotes) return undefined
    return quotes[quoteKey]
  },
)

export const getQuoteFromBookingProtectionQuotes = createSelector(
  (state: App.State) => state.bookingProtection.quotes,
  (state: App.State) => getBookingProtectionQuoteRequestKey(state),
  (quotes, quoteKey): App.BookingProtectionQuote | undefined => {
    if (!quoteKey || !quotes) return undefined
    return quotes[quoteKey]
  },
)

export const getBookingProtectionQuoteMobileAppOnlyPrice = createSelector(
  (state: App.State) => getQuoteFromBookingProtectionQuotes(state),
  (quote): number => {
    if (!quote) return 0
    return quote.mobileAppOnlyPrice
  },
)

export const getBookingProtectionQuotePrice = createSelector(
  (state: App.State) => getQuoteFromBookingProtectionQuotes(state),
  (quote): number => {
    if (!quote) return 0
    return quote.price
  },
)

export const getBookingProtectionQuoteLuxPlusPrice = createSelector(
  (state: App.State) => getQuoteFromBookingProtectionQuotes(state),
  (quote): number => {
    if (!quote) return 0
    return quote.luxPlusPrice || 0
  },
)

export const getExistingBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (items) => {
    return items?.find(item => item.status === 'completed')
  },
)

export const getUpdatedBookingProtectionQuotePriceDifference = createSelector(
  (state: App.State) => getUpdatedQuoteFromBookingProtectionQuotes(state),
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (quote, checkoutWithDiscountedBookingProtection, existingBookingProtectItems): number => {
    if (!quote) return 0
    const oldQuotePrice = existingBookingProtectItems?.find(bookingProtectItem => bookingProtectItem.status === 'completed')?.total || 0
    const newQuotePrice = checkoutWithDiscountedBookingProtection ? (quote.luxPlusPrice ?? 0) : quote.price
    const quotePriceDiff = Math.round((newQuotePrice - oldQuotePrice + Number.EPSILON) * 100) / 100
    // For now, we don't want to refund when negative difference
    return quotePriceDiff < 0 ? 0 : quotePriceDiff
  },
)

export const getUpdatedBookingProtectionQuotePrice = createSelector(
  (state: App.State) => getUpdatedQuoteFromBookingProtectionQuotes(state),
  (quote): number => {
    if (!quote) return 0
    return quote.price
  },
)

export const getUpdatedBookingProtectionQuoteLuxPlusPrice = createSelector(
  (state: App.State) => getUpdatedQuoteFromBookingProtectionQuotes(state),
  (quote): number => {
    if (!quote) return 0
    return quote.luxPlusPrice || 0
  },
)

export const getBookingProtectionQuotePricePerTraveller = createSelector(
  (state: App.State) => getBookingProtectionQuotePrice(state),
  (state: App.State) => getBookingProtectionOccupantsFromCartItems(state),
  (price, occupants): number => {
    if (!price || !occupants) return 0
    const numTravellers = sumUpOccupancies([occupants])
    return adjustForDecimal(price / numTravellers)
  },
)

export const getBookingProtectionQuoteLuxPlusPricePerTraveller = createSelector(
  (state: App.State) => getBookingProtectionQuoteLuxPlusPrice(state),
  (state: App.State) => getBookingProtectionOccupantsFromCartItems(state),
  (luxPlusPrice, occupants): number => {
    if (!luxPlusPrice || !occupants) return 0
    const numTravellers = sumUpOccupancies([occupants])
    return adjustForDecimal(luxPlusPrice / numTravellers)
  },
)

export const getBookingProtectionQuoteMobileOnlyPricePerTraveller = createSelector(
  (state: App.State) => getBookingProtectionQuoteMobileAppOnlyPrice(state),
  (state: App.State) => getBookingProtectionOccupantsFromCartItems(state),
  (mobileOnlyPrice, occupants): number => {
    if (!mobileOnlyPrice || !occupants) return 0
    const numTravellers = sumUpOccupancies([occupants])
    return adjustForDecimal(mobileOnlyPrice / numTravellers)
  },
)

export const getUpdatedBookingProtectionDetails = createSelector(
  (state: App.State) => getUpdatedBookingProtectionQuotePrice(state),
  (state: App.State) => getUpdatedBookingProtectionQuoteLuxPlusPrice(state),
  (state: App.State) => getUpdatedBookingProtectionQuotePriceDifference(state),
  (state: App.State) => getBookingProtectionCoverAmountFromCart(state),
  (state: App.State) => getUpdatedQuoteFromBookingProtectionQuotes(state),
  (state: App.State) => selectShouldUseBookingProtectionMemberPrice(state),
  (state: App.State) => getBookingProtectionItems(state),
  (
    quotePrice,
    quoteLuxPlusPrice,
    quotePriceDifference,
    coverAmount,
    quote,
    checkoutWithDiscountedBookingProtection,
    bookingProtectionItems,
  ): App.Checkout.UpdatedBookingProtectionDetails | null => {
    if (!bookingProtectionItems.length || !quote || !quotePrice || !coverAmount) return null

    const { quoteId, products } = quote

    return {
      quoteId,
      total: checkoutWithDiscountedBookingProtection ? quoteLuxPlusPrice : quotePrice,
      totalLuxPlus: quoteLuxPlusPrice,
      publicTotal: quotePrice,
      orderValue: coverAmount,
      brand: config.BRAND,
      quotePriceDiff: quotePriceDifference,
      quote: {
        products,
      },
    }
  },
)

interface TravelDates {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
}

export const getTravelDatesFromCart = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getFlightItemsView(state),
  (state: App.State) => getExperienceItemsView(state),
  (accommodationViews, flightViews, experienceViews): TravelDates | undefined | null => {
    if ((!accommodationViews.hasRequiredData || !flightViews.hasRequiredData) && !experienceViews.hasRequiredData) { return null }

    const accommodationDates = accommodationViews.data.map(item => ({
      startDate: item.startDate,
      endDate: item.endDate,
    }))

    const flights = flightViews.data.map(flightView => [
      ...(flightView.flights[0]?.journeyFlight.flights || []),
      ...(flightView.flights[1]?.journeyFlight.flights || []),
    ]).flat()

    const flightDates = flights.map(flight => ({
      startDate: flight.departingDate,
      endDate: flight.arrivalDate,
    }))

    const experienceDates = experienceViews.data.map(experienceView => ({
      startDate: experienceView.bookingDate,
      endDate: experienceView.bookingDate,
    }))

    const dates = [...accommodationDates, ...flightDates, ...experienceDates].filter(date => date.endDate && date.startDate)

    if (dates.length) {
      return {
        startDate: moment.min(dates.map(date => moment(date.startDate, ISO_DATE_FORMAT))),
        endDate: moment.max(dates.map(date => moment(date.endDate, ISO_DATE_FORMAT))),
      }
    }
  },
)
