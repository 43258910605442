export enum OptimizelyExperiments {
  cruiseNewBookingFlow = 'cruise_new_cruisebank_booking_flow_may_2024',
  isSupportAssistantEnabled = 'opex_support_assistant_customer_pool',
  paymentsHotelsDepositsAu = 'payments_hotels_deposits_au_v2',
  pricePerNight = 'cro_price_per_night_non_au_nz',
  lereSearchModelV4 = 'lere_search_model_v4',
  bedbankChangeDatesEnabled = 'opex_bedbank_change_dates',
  searchClusterMapPins = 'search_cluster_map_pins',
  supportAssistantWidgetEnabled = 'opex_lesa_widget',
  expediaStyleLoginPrompt = 'expedia_style_login_prompt',
  emailChangeEnabled = 'opex_email_change',
  signUpModalTitleEnabled = 'cro_sign_up_modal_without_save_up_to_70',
  croAppPromoInSgHk = 'cro_app_promo_in_sg_hk',
  refundProtectForInternationalEnabled = 'cro_refund_protect_vs_insurance_international',
  croBedbankOptionSortOrder = 'cro_bedbank_option_sort_order',
  offerPageSecondChanceModal = 'offer_page_second_chance_modal',
  searchPageSecondChanceModal = 'search_page_second_chance_modal',
  specificPaidLandingPage = 'specific_paid_landing_page',
  croMultiItemCart = 'cro_multi_item_cart',
  securePhoneNumberChangeEnabled = 'csx_secure_phone_number_change',
  croInsuranceCopyWithCoverageDetails = 'cro_insurance_copy_with_coverage_details',
  croTrustpilotReviewFeedInCheckout = 'cro_trustpilot_review_feed_in_checkout',
  croLPFreePreviewAfterSignUpLogIn = 'cro_offer_lp_free_preview_after_sign_up_log_in',
  croWalledGardenButtonCta = 'cro_walled_garden_button_cta',
  paymentsNonrefundableMerchantFees = 'payments_nonrefundable_merchant_fees',
  croLPPNewMobileBooking = 'cro_lpp_new_mobile_booking',
  croUrgencySavingUpsPricingHomepageSearch = 'cro_urgency_saving_usps_homepage_search',
  lerePersonalisedHeroCarousel = 'lere_personalised_hero_carousel',
  paymentsDebitCardMerchantFees = 'payments_debit_card_merchant_fees',
  croAlwaysOnNewMobileBooking = 'cro_lpc_new_mobile_booking',
  searchDestinationLandingPageLargeSearchBar = 'search_destination_landing_page_large_search_bar',
  croFlexibleFlightFares = 'cro_flexible_flight_fares',
  croWebPushNotificationTrigger = 'cro_web_push_notification_trigger',
}

export enum OptimizelyFeatureFlags {
  luxLoyaltyFeatureToggle = 'lux_loyalty_feature_toggle',
  luxLoyaltyLoadTest = 'lux_loyalty_load_test',
  luxPlusFeatureToggle = 'luxplus_feature_toggle',
  luxPlusToursToggle = 'luxplus_tours_toggle',
  luxPlusDiscountedInsuranceBenefit = 'lux_plus_discounted_insurance_benefit',
  luxPlusDiscountedBookingProtectionBenefit = 'lux_plus_discounted_booking_protection_benefit',
  luxPlusLppPricingEnabled = 'luxplus_lpp_pricing_enabled',
  luxPlusCruiseInclusionsEnabled = 'lux_plus_cruise_inclusions',
  isGoCardlessEnabled = 'payments_go_cardless',
  allowPhoneNumberLogin = 'allow_phone_number_login',
  promoAppBanner = 'app_banner_targeted_delivery',
  CSAgentsAutoQuote = 'cro_cs_agents_auto_quote',
  tourOptionalExtras = 'tours_optional_extras',
  tourReviewsEnabled = 'cro_tour_reviews',
  toursRequestAgentCallbackEnabled = 'csx_tours_request_agent_callback',
  hotelsAndVillasRequestAgentCallbackEnabled = 'csx_hotels_and_villas_request_agent_callback',
  cancellationOutsidePolicyEnabled = 'opex_cancellation_outside_refund_policy',
  tourOptionalExtraPostPurchase = 'tour_optional_extra_post_purchase',
  flashExtendDatesEnabled = 'opex_flash_extend_dates',
  upsellTilesFeatureToggle = 'cro_upsell_on_homepage_and_my_escapes',
  croNewFlightsSaleCarouselEnabled = 'cro_flights_sale_carousel',
  csxOutsidePolicyChangeDatesRequestEnabled = 'csx_outside_policy_change_dates_request',
  croTravelPreferencesModalEnabled = 'cro_travel_preferences_modal',
  standaloneAirportTransfersEnabled = 'csx_standalone_airport_transfer_flow',
  opexTermsAndConditionsModal = 'terms_and_conditions_modal',
  flashLpcDifferentRatesChangeDatesEnabled = 'opex_different_rates_change_dates_flash_lpc',
  lebtPinnedFlightDetails = 'lebt_pinned_flight_details',
  croWebPushNotificationPrompt = 'cro_web_push_notification_prompt',
  opexFraudOrdersOtp = 'opex_fraud_orders_otp',
  lebtMerchantFeesEnabled = 'lebt_merchant_fees',
  payIdTargetedRelease = 'pay_id_targeted_release',
  opexDifferentRatesChangeDatesBedbankEnabled = 'opex_different_rates_change_dates_bedbank',
  useSppPricingSearch= 'use_spp_pricing_search',
  paypalTargetedRelease = 'paypal_targeted_release',
}
