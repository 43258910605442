import isDomesticTrip from 'checkout/selectors/view/isDomesticTrip'
import { EmptyArray } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'

const getCanRedeemLoungePassLoyaltyBenefit = createSelector(
  (state: App.State) => state.luxLoyalty.account.data?.benefits,
  (state: App.State) => !isDomesticTrip(state),
  (state: App.State) => state.luxLoyalty.config?.loungePassExperienceIds,
  (userBenefits, isInternationalTrip, loungePassIds = EmptyArray): boolean => {
    const loungePassBenefit = userBenefits?.find(benefit => benefit.code === 'lounge_pass')

    // Can redeem lounge passes when they have some remaning, it's an international order
    // and our config says we have lounge pass experiences available
    return loungePassIds.length > 0 &&
    !!loungePassBenefit &&
    (loungePassBenefit.unlimited || !!loungePassBenefit.remaining) &&
    isInternationalTrip
  },
)

export default getCanRedeemLoungePassLoyaltyBenefit
