import { Models as FlightModels } from '@luxuryescapes/lib-flights-types'
import { templates } from '@luxuryescapes/lib-uri-templates'
import request, { authOptions } from 'api/requestUtils'
import {
  Airline,
  FlightCachedPrice,
  FlightCalendarPrices,
  FlightsCreditDetails,
} from 'components/Flights/types'
import { LE_BUSINESS_TRAVELLER } from 'constants/brands'
import config from 'constants/config'
import { FlightsFareTypes } from 'constants/flight'
import { nonNullable, sortBy } from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import { countOccupantsForFlights } from 'lib/offer/occupancyUtils'
import qs from 'qs'
import { flightDealMap } from './lib/flightDealMap'
import { mapFlightFareFamily, ServerUpsellFareFamily } from './lib/flightFareFamilyMap'
import { journeyV2Map } from './lib/journeyV2Map'

export interface FlightSearchItinerary {
  departureAirport: string;
  arrivalAirport: string;
  departureDate: string;
}
interface SearchParams {
  itinerary: Array<FlightSearchItinerary>;
  currency: string;
  occupancies: Array<App.Occupants>;
  fareClass: string;
  maxArrivalTime?: string;
  minReturningDepartureTime?: string;
  region: string;
  carriers?: Array<string>;
  fareType?: string;
  source?: string;
  viewType?: string;
  carrier?: string;
  flightsCredit?: boolean;
  forceBundleId?: string;
  filterAirlines?: Array<string>;
  dealId?: string;
}

interface FlightSearchResult {
  viewType?: string;
  searchId?: string;
  onewayFares?: Array<FlightModels.Search.OneWayFare>;
  journeys?: Array<any>;
  notices?: Array<App.FlightNotice>;
  sort?: string;
  fareFamilies?: FlightModels.Search.IFareFamilies;
  metadata?: {
    cheapest?: {
      adultPrice?: number;
      provider?: string;
    }
  }
}

const fareTypeToTripTypeMapper: Record<FlightsFareTypes, string> = {
  [FlightsFareTypes.RETURN]: 'Return',
  [FlightsFareTypes.ONE_WAY]: 'OneWay',
  [FlightsFareTypes.MULTI_CITY]: 'MultiCity',
}

export function searchFlights(
  {
    itinerary,
    currency,
    occupancies,
    fareClass,
    maxArrivalTime,
    minReturningDepartureTime,
    region,
    fareType,
    source,
    viewType,
    carrier,
    flightsCredit,
    forceBundleId,
    filterAirlines,
    dealId,
  }: SearchParams,
  options: {
    offerId?: string
    publicOfferFlightPrice?: number
    calendarFlightPrice?: number
    sendCredentials?: boolean
  } = EmptyObject,
) {
  const passengerCount = countOccupantsForFlights(occupancies)

  const queryParams: any = {
    tripType: fareTypeToTripTypeMapper[fareType as FlightsFareTypes] || fareTypeToTripTypeMapper[FlightsFareTypes.RETURN],
    currencyCode: currency,
    itinerary,
    fareClass,
    passengers: {
      numAdults: passengerCount.adults,
      numChildren: passengerCount.children,
      numInfants: passengerCount.infants,
    },
    region,
    source,
    viewType,
    carrier,
    flightsCredit,
    sessionId: '',
    searchId: '',
    filters: {
      ...(filterAirlines ? { airlines: filterAirlines } : {}),
    },
    sortBy: '',
    searchDateTime: '',
    searchSource: '',
    localeId: '',
    apiAuthKey: '',
    forceBundleId,
    dealId,
    ...options,
  }

  const providerOverrides = config.FLIGHT_PROVIDER_OVERRIDE
  if (providerOverrides) {
    queryParams.provider = providerOverrides.split(',')
  }

  if (maxArrivalTime) {
    queryParams.maxArrivalTime = maxArrivalTime
  }

  if (minReturningDepartureTime) {
    queryParams.minReturningDepartureTime = minReturningDepartureTime
  }

  const credentials = config.BRAND === LE_BUSINESS_TRAVELLER || options.sendCredentials ? 'include' : undefined

  return request
    .post<App.ApiResponse<FlightSearchResult>, any>('/api/flights/flightsearch', queryParams, { credentials })
    .then(res => {
      const { onewayFares, searchId, viewType, sort, fareFamilies, metadata } = res.result

      return {
        onewayFares: (onewayFares ?? []).map((oneWayFare) => {
          return oneWayFare.fares.filter(fare => fare !== null).map((fare) => journeyV2Map(fare, fareFamilies))
        }),
        searchId,
        viewType,
        sort: sort?.toLowerCase(),
        fareFamilies,
        metadata,
        timestamp: Date.now(),
      }
    })
}

export interface FareRuleParams {
  provider: string,
  fare_rule_type?: string,
  pnrId?: string,
  region?: string,
  journey_id?: string,
  isBundledWithAccommodation?: boolean,
}

export function getFareRules(url: string, fareRuleParams?: FareRuleParams) {
  if (fareRuleParams) {
    return request.post<App.ApiResponse<any>, unknown>(url, fareRuleParams).then(resp => resp.result)
  }
  return request.get<App.ApiResponse<any>>(url).then(resp => resp.result)
}

export interface FlightSelectParams {
  journeyId: string;
  departingJourneyKey: string;
  departingFareFamilyId?: string;
  returningJourneyKey?: string;
  returningFareFamilyId?: string;
  departingAirport: string;
  arrivalAirport: string;
  providerSearchId: string;
  region?: string;
  isStandalone?: boolean;
  provider: string;
  source?: string;
  callCount?: number;
  isFlightsCredit?: boolean;
  viewType?: string;
  selectedFareIds?: Array<string>;
  isFlightBaggageAvailable?: boolean;
  forceBundleId?: string;
}

export interface PriceDetails {
  passengerTypeQuantity: { code: string };
  totalFare: string;
  totalFareOriginalCurrency: number
}

export interface Price {
  type: 'total_fare' | 'atol_fee' | 'service_fee' | 'flights_credit_fee' | 'booking_fee';
  amount: number;
}

interface SelectFlightsResult {
  priceSummary?: Array<Price>;
  selectedFares: Array<{ flightGroups: any, price: Record<string, PriceDetails> }>;
  error: { message: string };
}

export function selectflights({
  journeyId,
  departingJourneyKey,
  departingFareFamilyId,
  returningJourneyKey,
  returningFareFamilyId,
  departingAirport,
  arrivalAirport,
  providerSearchId,
  region,
  isStandalone,
  provider,
  source,
  isFlightsCredit,
  viewType,
  selectedFareIds,
  forceBundleId,
}: FlightSelectParams) {
  const selectFlightDetails: any = {
    journeyId,
    departing_journey_key: departingJourneyKey,
    returning_journey_key: returningJourneyKey,
    departing_airport: departingAirport,
    arrival_airport: arrivalAirport,
    provider_search_id: providerSearchId,
    region,
    isStandalone,
    provider,
    source,
    isFlightsCredit,
    viewType,
    selectedFareIds,
    forceBundleId,
  }

  if (selectedFareIds && (departingFareFamilyId || returningFareFamilyId)) {
    selectFlightDetails.selectedUpsellOptions = {
      [selectedFareIds[0]]: departingFareFamilyId,
      [selectedFareIds[1]]: returningFareFamilyId,
    }
  }

  // disable booking fee for any flights in AU region, apply merchant fee for merchant fee evaluation
  return request
    .post<App.ApiResponse<SelectFlightsResult>, any>('/api/flights/select', selectFlightDetails).then(response => {
      if (response.result.error?.message) {
        throw new Error(response.result.error.message)
      }
      return response
    })
}

interface ServerAirportLocation {
  id?: number;
  cityAirportName?: string;
  cityCode?: string;
  cityDesc?: string;
  airportCode: string;
  airportName: string;
  country?: string;
  isMultiAirport?: boolean;
  isChildDisplay?: boolean;
  isCityAirport?: boolean;
  lt?: number;
  lg?: number;
}

function airportMap(airport: any): App.AirportLocation {
  return {
    id: airport.id,
    cityAirportName: airport.cityAirportName,
    cityCode: airport.cityCode,
    cityDesc: airport.cityDesc,
    airportCode: airport.airportCode,
    airportName: airport.airportName,
    country: airport.country,
    isMultiAirport: airport.isMultiAirport,
    isChildDisplay: airport.isChildDisplay,
    lt: airport.lt,
    lg: airport.lg,
  }
}

export function getAirportsSearch(searchText: string): Promise<Array<App.AirportLocation>> {
  const query = qs.stringify({
    searchCode: searchText,
  })
  return request
    .get<Array<ServerAirportLocation>>(`/api/flights/locationSearch?${query}`)
    .then(res => res.map(airport => airportMap(airport)))
}

interface AirportResult {
  airports?: Record<string, App.Airport>;
  origins?: Array<string>;
  destinations?: Array<string>;
  main_port?: string;
  closest_port?: string;
}

export function getAirports(regionCode: string): Promise<{
  airports: Array<App.Airport>;
  originAirports: Array<App.Airport>;
  mainAirport?: App.Airport;
}> {
  const query = qs.stringify({ region: regionCode })

  return request.get<App.ApiResponse<AirportResult>>(`/api/flights/airports?${query}`).then(res => {
    const originAirports = nonNullable((res.result.origins ?? []).map(code => res.result.airports?.[code]))
    return {
      airports: Object.values(res.result.airports ?? {}),
      originAirports: sortBy(originAirports, airport => airport.name, 'asc'),
      mainAirport: res.result.airports?.[res.result.main_port ?? ''],
    }
  })
}

export async function getFlightPrice({
  startDate,
  endDate,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  origin,
  destination,
  currency,
  region,
  nights,
}: {
  startDate: string,
  endDate: string,
  numberOfAdults: number,
  numberOfChildren: number,
  numberOfInfants: number,
  origin?: string,
  destination: string,
  currency: string,
  region: string,
  nights?: number,
}): Promise<number> {
  const uri = templates.flight.flight_single_cheapest.expand({
    start_date: startDate,
    end_date: endDate,
    origin,
    currency,
    number_of_nights: nights,
    number_of_adults: numberOfAdults,
    number_of_children: numberOfChildren,
    number_of_infants: numberOfInfants,
    region,
    destination,
  })

  const response = await request.get<App.ApiResponse<any>>(uri)

  if (response?.result?.journey) {
    return response.result.journey.cost + response.result.journey.fees
  }
  return 0
}

export function getPopularAirportsByRegion(region: string) {
  return request
    .get<App.ApiResponse<App.PopularAirports>>(`/api/flights/popularDestinations?region=${region}`)
    .then(res => {
      const { origins, destinations } = res.result

      return {
        origins: origins.map(airport => airportMap(airport)),
        destinations: destinations.map(airport => airportMap(airport)),
      }
    })
}

export function getCreditRebookingDetails(email: string, pnrId: string) {
  return request
    .post<App.ApiResponse<any>, Record<string, string>>('/api/flights/postBookingPnrDetails', { email, pnrId }, { credentials: 'include' })
    .then(res => {
      const { status, message, result } = res

      const creditDetails: FlightsCreditDetails = {
        passengerCounts: result.passengerCounts,
        flightCreditFee: result.flightCreditFee,
        currency: result.currency,
        carrier: result.carrier?.validating,
        carrierName: result.carrier?.carrierName,
        pnrId,
        region: result.region,
        email: result.email,
        phone: result.phone,
      }

      return {
        status,
        message,
        creditDetails,
      }
    })
}

export function getFlightDealsByRegion(region: string, dealCategory?: string): Promise<Array<App.FlightDeal>> {
  return request
    .post<App.ApiResponse<Array<App.FlightDeal>>, Record<string, string | undefined>>('/api/flights/flight-deals/getFilteredDeals', { region, categoryGroup: dealCategory })
    .then(res => res.result.map(flightDeal => flightDealMap(flightDeal)))
}

export function getFlightDeal(id: string, token?: string): Promise<App.FlightDeal> {
  return request.get<App.ApiResponse<App.FlightDeal>>(`/api/flights/flight-deals/${id}`, {
    credentials: 'include',
    ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
  }).then(response => flightDealMap(response.result))
}

export function getAirlines(searchText: string): Promise<Array<Airline>> {
  return request
    .get<Array<Airline>>(`/api/flights/airlines?searchCode=${searchText}`)
    .then(res => res.map(airline => airline))
}

export async function getJourneyV2(
  searchId: string,
  fareId: string,
  accessToken: string,
  fareFamilyId?: string,
): Promise<App.JourneyV2> {
  const query = qs.stringify({
    selectedUpsellOptionId: fareFamilyId,
  })
  return request.get<App.ApiResponse<FlightModels.Search.Fare>>(
    `/api/flights/flightSearch/${searchId}/${fareId}?${query}`,
    authOptions(accessToken),
  ).then(response => {
    // server doesn't respond with correct status but hides the status in a 200 response, we need to detect this
    if (response.errors?.message) {
      throw new Error(response.errors.message)
    }
    return journeyV2Map(response.result)
  })
}

interface CachePricingRequest {
  startDate: string;
  endDate: string;
  origin: string;
  destination: string;
  currency: string;
  region: string;
  nights?: number;
}

interface CachePricingResult {
  journeys_by_date: FlightCachedPrice;
  earliest_booking_date: string;
  final_booking_date: string;
}

export function getFlightCachePrices(params: CachePricingRequest): Promise<FlightCalendarPrices> {
  const { startDate, endDate, origin, destination, currency, region, nights } = params
  const queryParams = {
    start_date: startDate,
    end_date: endDate,
    number_of_adults: 2,
    number_of_children: 0,
    number_of_infants: 0,
    number_of_nights: nights || 1,
    destination,
    origin,
    currency,
    region,
  }

  return request
    .get<App.ApiResponse<CachePricingResult>>(`/api/flights/calendar-search?${qs.stringify(queryParams)}`)
    .then(res => ({ cachedPrices: res.result.journeys_by_date, earliestBookingDate: res.result.earliest_booking_date, finalBookingDate: res.result.final_booking_date }))
}

export function getFlightBookingFees(region: string): Promise<Array<string>> {
  return request
    .post<App.ApiResponse<Array<string>>, Record<string, string>>('/api/flights/bookingFees', { region })
    .then(resp => resp.result)
}

interface GetFlightUpsellFaresParams {
  searchId: string;
  sessionId: string;
  origin: string;
  destination: string;
  occupancies: App.Occupants;
  region: string;
  departingUpsellOptionId?: string;
  isStandalone?: boolean;
  selectedFareIds: Array<string>;
}

interface UpsellFaresResult {
  upsellFares: Array<ServerUpsellFareFamily>;
  upsellPerLeg?: Array<{ legId: string, upsellFares: Array<ServerUpsellFareFamily> }>
}

export function getFlightFareFamilies({
  sessionId,
  searchId,
  origin,
  destination,
  occupancies,
  region,
  departingUpsellOptionId,
  isStandalone,
  selectedFareIds,
}: GetFlightUpsellFaresParams) {
  const queryParams = {
    origin,
    destination,
    numAdults: occupancies.adults,
    numChildren: occupancies.children,
    numInfants: occupancies.infants,
    region,
    sessionId,
    searchId,
    departingUpsellOptionId,
    isStandalone,
    selectedFareIds: selectedFareIds?.join(','),
  }

  return request
    .get<App.ApiResponse<UpsellFaresResult>>(`/api/flights/fare-families/upsell?${qs.stringify(queryParams)}`)
    .then(res => {
      const upsellFares = res.result.upsellFares.map(mapFlightFareFamily)
      const upsellFaresPerLeg = res.result.upsellPerLeg?.map(leg => leg.upsellFares.map(mapFlightFareFamily))

      return { upsellFares, upsellFaresPerLeg }
    })
}
